<script>

import 'codemirror/addon/dialog/dialog.css'
import 'codemirror/addon/dialog/dialog.js'
import 'codemirror/addon/display/fullscreen.css'
import 'codemirror/addon/hint/show-hint'
import 'codemirror/addon/hint/show-hint.css'

import 'codemirror/addon/display/fullscreen.js'

import 'codemirror/addon/edit/closebrackets.js'
import 'codemirror/addon/edit/closetag.js'
import 'codemirror/addon/edit/matchbrackets.js'
import 'codemirror/addon/edit/matchtags.js'
import 'codemirror/addon/fold/brace-fold.js'
import 'codemirror/addon/fold/comment-fold.js'

import 'codemirror/addon/fold/foldcode.js'
import 'codemirror/addon/fold/foldgutter.css'
import 'codemirror/addon/fold/foldgutter.js'
import 'codemirror/addon/fold/indent-fold.js'
import 'codemirror/addon/fold/markdown-fold.js'
import 'codemirror/addon/fold/xml-fold.js'
// import 'codemirror/addon/hint/anyword-hint.js'
// import 'codemirror/addon/hint/css-hint.js'
// import 'codemirror/addon/hint/html-hint.js'
import 'codemirror/addon/hint/show-hint.js'
import 'codemirror/addon/mode/overlay.js'
import 'codemirror/addon/search/match-highlighter.js'
import 'codemirror/addon/search/matchesonscrollbar.js'
import 'codemirror/addon/search/search.js'
import 'codemirror/addon/search/searchcursor.js'
import 'codemirror/addon/selection/active-line.js'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/css/css.js'
import 'codemirror/mode/htmlmixed/htmlmixed.js'
import 'codemirror/mode/xml/xml.js'
import 'codemirror/theme/rubyblue.css'
import Utils from "@/commons/Utils.js";
import {codemirror} from 'vue-codemirror'
import './formatting.js'
import './minuta.js'

import './shortcode.js'
import './wordpresspost.js'

export default {
    name: "IdeMinuta",
    components: {
      codemirror
    },
    props: {
      value: {
        type: String
      },
      readOnly: {default: false},
      options: Object
    },

    watch: {
      'value': function(newVal, oldVal) {
        if(newVal != oldVal && this.minuta != newVal){
          this.$set(this, 'minuta', newVal);
        }
      }
    },

    computed:{
        editor(){
            return this.$refs.editor.codemirror;
        }
    },

    data() {
      let _this = this;
      // console.debug('IdeMinuta', this.options);
      return {
        minuta: this.value,
        cmOption: {

          line: true,
          autofocus: true,
          // readOnly: 'nocursor',
          readOnly: this.readOnly,

          fullScreen: false,
          tabMode: 'indent',
          indentWithTabs: true,
          smartIndent: false,
          enterMode: 'keep',
          styleActiveLine: true,
          electricChars: false,
          showCursorWhenSelecting: true,
          viewportMargin: 10,
          matchTags : {both : true},
          highlightSelectionMatches: true,

          autoCloseBrackets : true,
          autoCloseTags : true,
          matchBrackets : false,
          lineWrapping : true,
          lineNumbers : true,

          foldGutter: true,
          gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],

          mode: 'minuta',

          theme: 'rubyblue',

          extraKeys: {

            "F11": function() {
              _this.cmOption.fullScreen = !_this.cmOption.fullScreen;
            },

            "Esc": function() {
              _this.cmOption.fullScreen = false;
            },

            "Ctrl-S": function() {
              if (this.readOnly) return;
              _this.$emit("save");
            },

            "Ctrl-I": function() {
              let range = { from: _this.editor.getCursor(true), to: _this.editor.getCursor(false) };
              _this.editor.autoFormatRange(range.from, range.to);
            },

            "Ctrl-J": "toMatchingTag",
            "Ctrl-Space": function(cm) { _this.testAutocomplete(cm) }

          }
        },
        ...(this.options || {})
      }
    },

    methods: {
      change(value) {
        if (this.readOnly) return;
        this.minuta = value;
        this.$emit('input', value);
        this.$emit('change');
      },
      testAutocomplete(cm){
        if (!cm.state.completionActive) {
          const cursor = cm.getCursor();
          const token = cm.getTokenAt(cursor);
          if (token.string.startsWith("{{")) {
            cm.showHint({hint: this.autocompleteEndVars});
          }else if (token.string.startsWith("$(")) {
            cm.showHint({hint: this.autocompleteVars});
          }else if (token.string.startsWith("<")) {
            cm.showHint({hint: this.autocompleteTags});
          }
        }
      },

      autocompleteTags(cm) {
        const cursor = cm.getCursor();
        const token = cm.getTokenAt(cursor);

        // Lista de sugestões possíveis
        const list = Utils.orderBy([
          'negrito',
          'sublinhado',
          'italico',
          'capitalizar',
          'maiusculo',
          'minusculo',
          'condicao',
          'repetidor',
          'fragmento',
          'formatar-data',
          'formatar-numero',
          'texto-formatado',
          'plural',
          'genero',
          'extenso',
          'remover',
          'fracao',
          'filtrar-lista',
          'agrupar-lista',
          'envolvidos',
          'vinculos',
          'documentos',
          'imagem',
          'tabela-sigef',
          'sigef'
        ]);

        const filtered = list.filter(function(item) {
          return item.startsWith(token.string.replace('<', '').replace('>', ''));
        });

        return {
          list: filtered.length ? filtered : list,
          from: {line: cursor.line, ch: token.start + 1},
          to: {line: cursor.line, ch: cursor.ch}
        };
      },

      autocompleteVars(cm) {
        const cursor = cm.getCursor();
        const token = cm.getTokenAt(cursor);

        // Lista de sugestões possíveis - mover para fora desse componente
        const list = Utils.orderBy([
          "protocolo.id",
          "protocolo.codigo",
          "protocolo.dataCadastro",
          "protocolo.dataRegistro",
          "protocolo.tipo",
          "protocolo.tipoRegistro",
          "protocolo.registrado",
          "protocolo.protocoloExterno",
          "protocolo.identificacaoEstadual",
          "protocolo.dadosImportados",
          "protocolo.seloPrincipal",
          "protocolo.selo",
          "imovel.id",
          "imovel.cadastro",
          "imovel.atualizacao",
          "imovel.codigo",
          "imovel.cnm",
          "imovel.codigoPre",
          "imovel.livroLetra",
          "imovel.ultimoAto",
          "imovel.status",
          "imovel.encerramento",
          "imovel.abertura",
          "imovel.legado",
          "imovel.cnmSincronizado",
          "imovel.textoLegado",
          "imovel.atoRecente",
          "imovel.restricoes",
          "imovel.usuarioImpressao",
          "imovel.impressao",
          "imovel.digitalizacao",
          "imovel.ordemUnidade",
          "imovel.livro",
          "imovel.livroNumero",
          "imovel.livroFolha",
          "imovel.indisponivel",
          "imovel.proprietarios",
          "imovel.origens",
          "imovel.referencias",
          "imovel.indisponibilidades",
          "imovel.certificacaoIncra",
          "imovel.incra",
          "imovel.itr",
          "imovel.cadastroImobiliario",
          "imovel.cadastroFiscal",
          "imovel.car",
          "imovel.categoria",
          "imovel.tipoImovel",
          "imovel.unidadeAutonoma",
          "imovel.descricaoTipoImovelOutros",
          "imovel.medidaArea",
          "imovel.areaPrivativa",
          "imovel.areaPrivativaAcessoria",
          "imovel.areaPrivativaTotal",
          "imovel.areaComum",
          "imovel.area",
          "imovel.fracaoSolo",
          "imovel.fracaoSoloPercentual",
          "imovel.coeficienteProporcionalidade",
          "imovel.cep",
          "imovel.estado",
          "imovel.cidade",
          "imovel.bairro",
          "imovel.tipoLogradouro",
          "imovel.logradouro",
          "imovel.numero",
          "imovel.unidade",
          "imovel.lote",
          "imovel.quadra",
          "imovel.pavimento",
          "imovel.bloco",
          "imovel.andar",
          "imovel.localizacao",
          "imovel.complemento",
          "imovel.empreendimento",
          "imovel.denominacao",
          "imovel.grupo",
          "imovel.especificacao",
          "imovel.observacao",
          "imovel.certidao",
          "imovel.resumo",
          "imovel.areas",
          "imovel.areasEdificadas",
          "imovel.unidadesAcessorias",
          "imovel.confrontacoes",
          "imovel.fronteira",
          "imovel.marinha",
          "imovel.imovelUniao",
          "imovel.numeroRipSpu",
          "imovel.regime",
          "imovel.medidaAreaUniao",
          "imovel.areaUniao",
          "imovel.usuarioEncerramento",
          "imovel.tipoImovelDescricao",
          "imovel.areaTotal",
          "imovel.areaEdificadaTotal",
          "checklist",
          "ato.registrado",
          "ato.id",
          "ato.codigo",
          "ato.tipo",
          "ato.selo",
          "ato.selos",
          "ato.isento",
          "ato.valor",
          "ato.guia",
          "ato.codigoFiscal",
          "ato.titulo",
          "ato.nomenclatura",
          "ato.numeroCat",
          "ato.ficha.id",
          "ato.ficha.cadastro",
          "ato.ficha.atualizacao",
          "ato.ficha.codigo",
          "ato.ficha.cnm",
          "ato.ficha.codigoPre",
          "ato.ficha.livroLetra",
          "ato.ficha.ultimoAto",
          "ato.ficha.status",
          "ato.ficha.encerramento",
          "ato.ficha.abertura",
          "ato.ficha.legado",
          "ato.ficha.cnmSincronizado",
          "ato.ficha.textoLegado",
          "ato.ficha.atoRecente",
          "ato.ficha.restricoes",
          "ato.ficha.usuarioImpressao",
          "ato.ficha.impressao",
          "ato.ficha.digitalizacao",
          "ato.ficha.ordemUnidade",
          "ato.ficha.livro",
          "ato.ficha.livroNumero",
          "ato.ficha.livroFolha",
          "ato.ficha.indisponivel",
          "ato.ficha.proprietarios",
          "ato.ficha.origens",
          "ato.ficha.referencias",
          "ato.ficha.indisponibilidades",
          "ato.ficha.certificacaoIncra",
          "ato.ficha.incra",
          "ato.ficha.itr",
          "ato.ficha.cadastroImobiliario",
          "ato.ficha.cadastroFiscal",
          "ato.ficha.car",
          "ato.ficha.categoria",
          "ato.ficha.tipoImovel",
          "ato.ficha.unidadeAutonoma",
          "ato.ficha.descricaoTipoImovelOutros",
          "ato.ficha.medidaArea",
          "ato.ficha.areaPrivativa",
          "ato.ficha.areaPrivativaAcessoria",
          "ato.ficha.areaPrivativaTotal",
          "ato.ficha.areaComum",
          "ato.ficha.area",
          "ato.ficha.fracaoSolo",
          "ato.ficha.fracaoSoloPercentual",
          "ato.ficha.coeficienteProporcionalidade",
          "ato.ficha.cep",
          "ato.ficha.estado",
          "ato.ficha.cidade",
          "ato.ficha.bairro",
          "ato.ficha.tipoLogradouro",
          "ato.ficha.logradouro",
          "ato.ficha.numero",
          "ato.ficha.unidade",
          "ato.ficha.lote",
          "ato.ficha.quadra",
          "ato.ficha.pavimento",
          "ato.ficha.bloco",
          "ato.ficha.andar",
          "ato.ficha.localizacao",
          "ato.ficha.complemento",
          "ato.ficha.empreendimento",
          "ato.ficha.denominacao",
          "ato.ficha.grupo",
          "ato.ficha.especificacao",
          "ato.ficha.observacao",
          "ato.ficha.certidao",
          "ato.ficha.resumo",
          "ato.ficha.areas",
          "ato.ficha.areasEdificadas",
          "ato.ficha.unidadesAcessorias",
          "ato.ficha.confrontacoes",
          "ato.ficha.fronteira",
          "ato.ficha.marinha",
          "ato.ficha.imovelUniao",
          "ato.ficha.numeroRipSpu",
          "ato.ficha.regime",
          "ato.ficha.medidaAreaUniao",
          "ato.ficha.areaUniao",
          "ato.ficha.usuarioEncerramento",
          "ato.tipoRegistro",
          "ato.dto.configuracaoRestricao.restricoesSelecionadas",
          "ato.doi.dataDaAlienacao",
          "ato.doi.formaDeAlienacaoAquisicao",
          "ato.doi.situacaoDaConstrucao",
          "ato.dto.informarAreaEnvolvidos",
          "ato.dto.multiplasCobrancas",
          "ato.dto.restricoes",
          "ato.dto.restricoesCanceladas",
          "ato.dto.restricoesCanceladasSemRegistrar",
          "ato.dto.salvandoItemProtocolo",
          "ato.dto.valoresBaseCalculo.alienacao",
          "ato.moeda.alienacao",
          "ato.moeda.avaliacao",
          "ato.moeda.divida",
          "ato.alienacao",
          "ato.valorAlienacao",
          "ato.valorAlienacaoAtualizado",
          "ato.ressarcimento.motivoIsencao",
          "ato.ressarcimento.motivoIsencaoId",
          "ato.ressarcimento.tipoProcesso",
          "ato.ressarcimento.tipoProcessoId",
          "ato.ressarcimento.numeroProcesso",
          "ato.frj",
          "ato.recolhimentos",
          "ato.emolumentos",
          "ato.fundos.FRJ",
          "ato.total",
          "ato.iss",
          "ato.custoSelo",
          "ato.taxaJudiciaria",
          "ato.fundosEstaduais",
          "ato.totalSemIss",
          "ato.transmissao",
          "ato.transmissaoImovel",
          "ato.garantia",
          "ato.garantiaImovel",
          "ato.restricao",
          "ato.restricaoImovel",
          "envolvidos.lista",
          "envolvidos.ADQUIRENTE",
          "envolvidos.ANUENTE",
          "envolvidos.ARRENDATARIO",
          "envolvidos.AUTOR",
          "envolvidos.AVALISTA_FIADOR",
          "envolvidos.CEDENTE",
          "envolvidos.CESSIONARIO",
          "envolvidos.COMPROMISSADO",
          "envolvidos.CONTRATANTE",
          "envolvidos.CREDOR",
          "envolvidos.DECLARANTE",
          "envolvidos.DESTINATARIO",
          "envolvidos.DEVEDOR",
          "envolvidos.EMITENTE",
          "envolvidos.EXECUTADO",
          "envolvidos.EXEQUENTE",
          "envolvidos.FIEL_DEPOSITARIO",
          "envolvidos.GARANTIDOR",
          "envolvidos.INTERESSADO",
          "envolvidos.INTERVENIENTE",
          "envolvidos.LOCADOR",
          "envolvidos.LOCATARIO",
          "envolvidos.MANDANTE",
          "envolvidos.MANDATARIO",
          "envolvidos.MEEIRO",
          "envolvidos.NOTIFICADO",
          "envolvidos.NOTIFICANTE",
          "envolvidos.NU_PROPRIETARIO",
          "envolvidos.PERMUTANTE",
          "envolvidos.PESSOA_JURIDICA_PRINCIPAL",
          "envolvidos.PROCURADOR",
          "envolvidos.PROMITENTE_COMPRADOR",
          "envolvidos.PROMITENTE_VENDEDOR",
          "envolvidos.PROPRIETARIO",
          "envolvidos.REMETENTE",
          "envolvidos.REPRESENTANTE",
          "envolvidos.REQUERENTE",
          "envolvidos.REQUERIDO",
          "envolvidos.REU",
          "envolvidos.SINDICO",
          "envolvidos.TESTEMUNHA",
          "envolvidos.TRANSMITENTE",
          "envolvidos.USUFRUTUARIO"
        ]);

        // Filtra a lista de sugestões com base no texto atualmente após o símbolo '$('
        const filtered = list.filter(function(item) {
          return item.startsWith(token.string.replace('$(', '').replace(')', ''));
        });

        return {
          list: filtered.length ? filtered : list,
          from: {line: cursor.line, ch: token.start + 2},
          to: {line: cursor.line, ch: cursor.ch}
        };
      },

      autocompleteEndVars(cm) {
        const cursor = cm.getCursor();
        const token = cm.getTokenAt(cursor);

        // Lista de sugestões possíveis - mover para fora desse componente
        const list = Utils.orderBy([
          'USUARIO',
          'USUARIO_CARGO',
          'DATA_REGISTRO',
          'DATA_REGISTRO_EXTENSO',
          'DATA_PROTOCOLO',
          'DATA_PROTOCOLO_EXTENSO',
          'PROTOCOLO',
          'ATO',
          'MATRICULA',
          'CNM',
          'TIPOATO',
          'VALOR_SELO',
          'VALOR_EMOLUMENTOS',
          'VALOR_ISS',
          'VALOR_TOTAL'
        ]);

        // Filtra a lista de sugestões com base no texto atualmente após o símbolo '$('
        const filtered = list.filter(function(item) {
          return item.startsWith(token.string.replace('{{', '').replace('}}', ''));
        });

        return {
          list: filtered.length ? filtered : list,
          from: {line: cursor.line, ch: token.start + 2},
          to: {line: cursor.line, ch: cursor.ch}
        };
      },

      applyFormat(startTag, endTag) {
        let cm = this.editor;
        let doc = cm.getDoc();
        let cursor = doc.getCursor();

        if (doc.somethingSelected()) {
          let selection = doc.getSelection();
          doc.replaceSelection(startTag + selection + endTag);
        } else {
          doc.replaceRange(startTag + endTag, cursor);
        }
      },
      makeBold() {
        this.applyFormat("<strong>", "</strong>");
      },
      makeItalic() {
        this.applyFormat("<em>", "</em>");
      },
      makeUnderlined() {
        this.applyFormat("<u>", "</u>");
      },
      insertBreakline() {
        this.applyFormat("", "<br />");
      }
    }
  }

</script>

<template>
  <div class="codemirror" id="ide-minuta">

    <div class="code-toolbar" v-if="!readOnly">
      <button class="btn btn-outline-secondary btn-xs mx-1 my-2" title="Negrito" @click="makeBold"><i class="fa fa-bold"></i></button>
      <button class="btn btn-outline-secondary btn-xs mx-1 my-2" title="Itálico" @click="makeItalic"><i class="fa fa-italic"></i></button>
      <button class="btn btn-outline-secondary btn-xs mx-1 my-2" title="Sublinhado" @click="makeUnderlined"><i class="fa fa-underline"></i></button>
      <button class="btn btn-outline-secondary btn-xs mx-1 my-2" title="Quebra de Linha" @click="insertBreakline"><i class="fa fa-level-down"></i></button>
    </div>

    <codemirror @input="change" :options="cmOption" v-model="minuta" ref="editor"></codemirror>
  </div>
</template>

<style lang="scss">

.CodeMirror-hints{
  z-index: 999999999999 !important;
}

  .CodeMirror-hints li{
    color: black !important;
  }

  #ide-minuta {
    width: 100%;
    $wpBlueBright_Color: rgb(0, 183, 255);
    $wpBlueLight_Color: rgb(116, 212, 250);
    $wpBlue_Color: rgb(0, 133, 186);
    $wpBlueDark_Color: rgb(0, 106, 149);
    $wpBlueDeep_Color: rgb(0, 93, 130);

    $wpGrayDark_Color: rgb(35, 40, 45);

    .CodeMirror {
      height: calc(100vh - 200px);
      margin-left: 0;
      margin-right: 0;
    }

    .CodeMirror-gutters {
      background: lighten($wpGrayDark_Color, 3%);
      color: rgb(83, 127, 126);
      color: darken($wpBlueDeep_Color, 20%);
      border: none;
    }

    .CodeMirror-guttermarker, .CodeMirror-guttermarker-subtle, .CodeMirror-linenumber {
      color: rgb(83, 109, 127);
    }

    .CodeMirror-cursor {
      border-left: 1px solid #f8f8f0;
    }

    div.CodeMirror-selected {
      background: rgba(255, 255, 255, 0.15);
    }

    &.CodeMirror-focused div.CodeMirror-selected {
      background: rgba(255, 255, 255, 0.10);
    }

    .CodeMirror-line::selection, .CodeMirror-line > span::selection, .CodeMirror-line > span > span::selection {
      background: rgba(255, 255, 255, 0.10);
    }

    .CodeMirror-line::-moz-selection, .CodeMirror-line > span::-moz-selection, .CodeMirror-line > span > span::-moz-selection {
      background: rgba(255, 255, 255, 0.10);
    }

    .CodeMirror-activeline-background {
      background: rgba(0, 0, 0, 0);
    }

    .cm-keyword {
      color: rgba(199, 146, 234, 1);
    }

    .cm-operator {
      color: rgb(187, 209, 209);
    }

    .cm-variable-2 {
      color: #80CBC4;
    }

    .cm-variable-3, .cm-type {
      color: #82B1FF;
    }

    .cm-builtin {
      color: #DECB6B;
    }

    .cm-atom {
      color: $wpBlueBright_Color;
    }

    .cm-number {
      color: $wpBlueBright_Color;
    }

    .cm-def {
      color: #DECB6B;
    }

    .cm-string {
      color: rgb(250, 149, 109);
    }

    .cm-string-2 {
      color: #80CBC4;
    }

    .cm-comment {
      color: #546E7A;
    }

    .cm-variable {
      color: #82B1FF;
    }

    .cm-tag {
      color: #80CBC4;
    }

    .cm-meta {
      color: #80CBC4;
    }

    .cm-attribute {
      color: $wpBlueLight_Color;
    }

    .cm-property {
      color: #80CBAE;
    }

    .cm-qualifier {
      color: #DECB6B;
    }

    .cm-variable-3, .cm-type {
      color: #DECB6B;
    }

    .cm-tag {
      color: $wpBlueBright_Color
    }

    .cm-variavel {
      color: #28a745
    }

    .cm-error {
      color: rgba(255, 255, 255, 1.0);
      background-color: #EC5F67;
    }

    .cm-matchhighlight,
    .CodeMirror-matchingbracket,
    .CodeMirror-matchingtag
    {
      background-color: rgba(0, 255, 175, 0.2);
    }


  }


</style>
