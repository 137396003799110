import FrontBusiness from "@/business/FrontBusiness";
import Http from "@/commons/Http";

const path = 'api/indicador-real-externo';

export default {

  async pagination(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/pagination?sort=${sort || 'ire.codigo'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async paginationV2(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/v2/pagination?sort=${sort || 'ire.codigo'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async save(dto, id = ''){
    return Http.post(`${path}/${id}`, dto);
  },

  async getById(id){
    return Http.get(`${path}/${id}`);
  },

  async delete(id){
    return Http.delete(`${path}/${id}`);
  },

  async buscarProtocolos(id){
    return Http.get(`${path}/${id}/protocolos`);
  },

  async buscarProtocolosAtivos(id){
    return Http.get(`${path}/${id}/protocolos-ativos`);
  },

  async listarRegistros(id, sort, direction){
    return Http.get(`${path}/${id}/registros?sort=${sort || 'ordem'},${direction || 'asc'}`);
  },

  async ordenarRegistros(id, unidades) {
    return Http.post(`${path}/${id}/registros/ordenar`, unidades);
  },

  nomearFicha(ficha = {}, codigo = true){
    let livro = FrontBusiness.nomearLivro(ficha.tipo) + ' ';
    return `${livro}${codigo ? FrontBusiness.formatarCodigo(ficha.codigo) : ''}`.trim();
  },

  async getFichasPorCodigo(params = {}) {
    const {livro, dto} = params;
    return await Http.post(`${path}/codigos/${livro}`, dto);
  },

  exportarRegistrosPosteriores(id, direction) {
    return `${path}/${id}/exportar-registros-posteriores?sort=codigo,${direction || 'desc'}`;
  },

  getErrosForm(form) {
    let validation = {};
    let invalid = false;
    if (!form.serventia) {
      validation.serventia = 'O campo serventia é obrigatório';
      invalid = true;
    }
    if (!form.tipo) {
      validation.tipo = 'O campo tipo é obrigatório';
      invalid = true;
    }
    if (!form.codigo) {
      validation.codigo = 'O campo código é obrigatório';
      invalid = true;
    }
    return invalid ? validation : undefined;

  },
}
