<template>
  <consulta ref="modalNaoAtivos" :columns="columns" :process="process" id="consultar-protocolo-nao-ativos" titulo="Não Ativos">
    <template #row="{ row }">

      <tr>

        <td width="50px" class="text-center">
          <router-link :to="{ name: row.dominio === 'OFICIO' ? 'oficio' : 'protocolo', params:{id : row.id} }" class="icon" target="_blank" @click.native="$event.stopImmediatePropagation()">
            <i class="fas fa-external-link-alt"></i>
          </router-link>
        </td>

        <td class="">
          {{nomear(row)}}
        </td>
        <td>{{ row.natureza }}</td>
        <td>{{ row.tipoServico }}</td>
        <td>{{ row.etapa }}</td>
        <td width="150px">{{ row.cadastro | data }}</td>
        <td width="150px">{{ row.vencimento | data }}</td>
        <td width="150px">{{ row.registro | data }}</td>
        <td width="150px">{{ row.encerramento | data }}</td>
        <td width="120px" class="text-capitalize">{{ row.status.toLowerCase() }}</td>

      </tr>

    </template>

  </consulta>
</template>

<script>
import FrontBusiness from "@/business/FrontBusiness.js";
import ProtocoloBusiness from "@/business/protocolo/ProtocoloBusiness.js";
import Consulta from "@/components/Consultas/Consulta.vue";

export default {
    name: "ConsultarProtocoloNaoAtivo",
    components: {Consulta},

    props: {
      atribuicao: String,
      indicadorId: String,
      indicadorRealId: String,
      indicadorPessoalId: String,
      registroAuxiliarId: String,
      tipo: String,
    },

    data() {
      return {
        filtros: {
          atribuicao: this.atribuicao,
          indicadorId: this.indicadorId,
          indicadorRealId: this.indicadorRealId,
          indicadorPessoalId: this.indicadorPessoalId,
          registroAuxiliarId: this.registroAuxiliarId,
          tipo: this.tipo,
        }
      }
    },

    computed: {

      columns() {
        let cols = [
          {label: '', field: 'id', headerClass: 'column__botao', sortable: false, name: 'id'},
          {label: 'Código', field: 'p.codigo', name: 'codigo'},
          {label: 'Natureza', field: 'p.natureza', name: 'natureza', minWidth: '200px'},
          {label: 'Serviço', field: 'p.tiposervico', name: 'tipoServico', minWidth: '200px'},
          {label: 'Etapa', field: 'p.etapa', name: 'etapa', minWidth: '200px'},
          {label: 'Cadastro', field: 'p.cadastro', name: 'cadastro', minWidth: '150px'},
          {label: 'Vencimento', field: 'p.vencimento', name: 'vencimento', minWidth: '150px'},
          {label: 'Registro', field: 'p.registro', name: 'registro', minWidth: '150px'},
          {label: 'Encerramento', field: 'p.encerramento', name: 'encerramento', minWidth: '150px'},
          {label: 'Situação', field: 'p.status', headerClass: 'column__tipo_doc', name: 'status'}
        ];
        return cols;
      }
    },

    mounted() {
      this.$refs?.modalNaoAtivos?.load();
    },

    methods: {

      nomear(protocolo) {
        if(protocolo.dominio === 'OFICIO') {
          return 'Ofício ' + protocolo.codigo;
        }
        return FrontBusiness.nomearProtocolo(protocolo.dominio, protocolo.tipoProtocolo, protocolo.codigo);
      },

      close() {
        FrontBusiness.closeModal();
      },

      async process(sort_by, sort_dir, page_length, page_number) {
        let sort = sort_by || "p.cadastro";
        return ProtocoloBusiness.protocolosNaoAtivos(sort, sort_dir, page_number, page_length, {filtros: this.filtros})
          .then(result => ({rows: result.pagina, total: result.quantidade}));
      }

    }
  }
</script>

<style lang=scss>
  #consultar-protocolo-nao-ativos {
    th.column__botao {
      width: 50px;
    }
  }
</style>
