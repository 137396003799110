import AuthBusiness from "@/business/AuthBusiness.js";
import ConfiguracaoBusiness from "@/business/ConfiguracaoBusiness.js";
import GrupoPermissaoBusiness from "@/business/crud/GrupoPermissaoBusiness";
import FrontBusiness from "@/business/FrontBusiness";
import DominioBusiness from "@/business/protocolo/DominioBusiness.js";
import VueBusiness from "@/business/VueBusiness.js";
import EventBus from "@/commons/EventBus.js";
import Utils from "@/commons/Utils";
import {installApi} from "@/plugins/Axios";
import {ApmVuePlugin} from "@elastic/apm-rum-vue";

import {createPinia, PiniaVuePlugin} from 'pinia';
import Vue from "vue";
import App from "./App.vue";
import "./imports.js";
import "./errorHandler.js";
import router from "./router";
import ListaBusiness from "@/business/crud/ListaBusiness";

window.server = {
  publicPath: import.meta.env.VITE_APP_BASE_URL || '/',
  VUE_APP_VERSION: __APP_VERSION__ || '',
  YGDRASIL_URL: 'https://yggdrasil.sistemaasgard.com.br',
  BACK_VERSION: null,
  SLUG: null
};

String.prototype.replaceAll = String.prototype.replaceAll || function(needle, replacement) {
  return this.split(needle).join(replacement);
};

window.handleCredentialResponse = async function(response) {
  await AuthBusiness.authenticateGoogleToken(response.credential);
  setTimeout(FrontBusiness.reload, 1000);
}

String.prototype.format = String.prototype.format || function() {
  let args = arguments;
  return this.replace(/{(\d+)}/g, function(match, number) {
    return typeof args[number] != 'undefined' ? args[number] : match;
  });
};

String.prototype.capitalize = String.prototype.capitalize || function() {
  return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
};

router.beforeEach(async (to, from, next) => {

  await installApi();
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  let redirect = undefined;

  if (from.name) {
    document.body.classList.remove(from.name);
  }
  document.body.classList.add(to.name);

  if(requiresAuth && !VueBusiness.app?.$data.usuarioLogado){
    const user = await AuthBusiness.getLoggedUser();
    if(!user){
      window.server.user = null;
      redirect = {path: '/login', query: { redirect: to.fullPath }};
    }else{
      await Promise.all([getConfiguracoes(), loadLoggedUser(), loadConfig()]);
    }
  }

  next(redirect);

});

let apmInstalled = false;

const loadLoggedUser = async () => {
  const user = await AuthBusiness.getLoggedUser().catch(err => (null));
  if(user){
    window.server.user = {id : user.id, cpf: user.cpf, cpfCertificado: user.cpfCertificado};
    VueBusiness.app.$data.usuarioLogado = user;
    VueBusiness.app.$data.theme = user.theme || 'dark';
  }
};

const loadConfig = async () => {
  VueBusiness.app.$data.enums = await ConfiguracaoBusiness.getEnums().catch(err => ({}));
  VueBusiness.app.$data.permissoes = await GrupoPermissaoBusiness.getPermission().catch(err => ([]));
};

const getConfiguracoes = async () => {
  /***********************/
  const serverInfo = await AuthBusiness.testServer();

  window.server.SLUG = serverInfo?.slug;
  window.server.BACK_VERSION = serverInfo?.version;
  window.server.hom = serverInfo?.profile === "DEVELOPMENT";
  console.info(`Client: ${window.server?.SLUG}`)
  console.info(`Back Version: ${window.server?.BACK_VERSION}`)
  console.info(`Front Version: ${window.server?.VUE_APP_VERSION}`)
  console.debug(serverInfo);

  let config = await ConfiguracaoBusiness.getResumo().catch(err => ({}));
  VueBusiness.app.$data.config = config;
  window.server.config = config;

  let cidades = config?.listaCidade?.id ? await ListaBusiness.getById(config?.listaCidade?.id).catch(e => ({lista: []})) : {lista: []};
  VueBusiness.app.$data.cidades = (cidades || {lista: []})?.lista.map(e => ({
    id: e,
    nome: e
  }));

  if(!apmInstalled){
    apmInstalled = true;
    //apm
    // Vue.use(ApmVuePlugin, {
    //   router,
    //   config: {
    //     serviceName: 'vue-app-' + serverInfo?.slug,
    //     serverUrl: 'https://apm.sistemaasgard.com.br',
    //     serviceVersion: window.server?.VUE_APP_VERSION,
    //     environment: window.server.hom ? 'development' : 'production',
    //     logLevel: 'warn',
    //     // agent configuration
    //   }
    // });
  }

}

Vue.config.productionTip = !!window.server.dev;
Vue.config.devtools = !!window.server.dev;
Vue.config.performance = !!window.server.dev;

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

VueBusiness.app = new Vue({
  router, pinia,
  render: h => h(App),
  computed:{

    publicPath(){
      return window?.server?.publicPath || '/';
    },

    isHomolog(){
      return window.server.hom;
    },

    isProduction(){
      return !window.server.hom;
    },

    isRTD(){
      return this.atribuicaoAtiva('RTD');
    },

    isRI(){
      return this.atribuicaoAtiva('RI');
    },

    isRCPJ(){
      return this.atribuicaoAtiva('RCPJ');
    },

    isEstadoGO(){
      return this.config.estado == 'GO'
    },
    isEstadoSC(){
      return this.config.estado == 'SC'
    },
    isEstadoMG(){
      return this.config.estado == 'MG'
    },
    isEstadoPE(){
      return this.config.estado == 'PE'
    },
    isEstadoES(){
      return this.config.estado == 'ES'
    },
    isEstadoCE(){
      return this.config.estado == 'CE'
    },
    isEstadoSP(){
      return this.config.estado == 'SP'
    },
    isEstadoPR(){
      return this.config.estado == 'PR'
    },
    isEstadoRO(){
      return this.config.estado == 'RO'
    },
    isEstadoMS(){
      return this.config.estado == 'MS'
    },
    isCartorio1RIGO(){
      return ['1rigoiania-go', 'api-1rigoiania-go', 'asgard-homologacao.1rigo.com'].includes(window.server.SLUG);
    },

    isCartorioCuritibanos(){
      return window.server.SLUG?.includes('ricuritibanos-sc');
    },

    isCidadeRecife(){
      return this.isEstadoPE && this.config.cidade == 'Recife';
    },

    usuarioUtilizaCertificadoTerceiros(){
      return this.usuarioLogado?.cpfCertificado;
    },

    isVhlUser(){
      return this.usuarioLogado?.login?.includes('vhl');
    },

    isGestorOrAdminUser(){
      return !this.config.geral.usarGrupoPermissaoCompleto && (this.usuarioLogado.gestor || this.usuarioLogado.administrador);
    },

    utilizarAssinadorOnr(){
      return this.config?.arisp?.usarAssinador;
    },

    utilizarAssinadorOnrDemaisDocumentos(){
      return this.config?.arisp?.usarAssinadorDemaisDocumentos;
    },

    ativarSolicitacaoValor(){
      return this.config?.financeiro?.ativarSolicitacaoValor;
    },

    gerenciarCalendario(){
      return this.getPermission({id: 'PERMITIR_GERENCIAR_CALENDARIO'});
    },

    atendente(){
      return this.getPermission({id: 'ATENDENTE'});
    },

    isCartorioJuatuaba(){
      return window.server.SLUG?.includes('rijuatuba-mg');
    },

  },
  data(){
    return {
      systemActive: true,
      loading:true,
      usuarioLogado: null,
      theme: 'dark',
      profile : null,
      config : {
        estado : 'GO'
      },
      editor: {
        changes: false
      },
      updateAvailable: false,
      atribuicao: [],

      permissoes: [],
      cidades: [],
      enums: {},
    }
  },

  async beforeMount() {

    document.addEventListener('visibilitychange', e => {
      console.debug('System active', !document.hidden);
      this.$set(this, 'systemActive', !document.hidden);

      if(!document.hidden){
        EventBus.$emit('SSE-HEARTBEAT');
      }

    }, false);

    this.$set(this, 'loading', true);
    console.debug('Start Asgard load')
    await installApi();
    console.debug('End Asgard load')
    this.$set(this, 'loading', false);
  },

  methods: {

    publicAsset(file){
      return (window?.server?.publicPath || '/') + file;
    },

    atribuicaoAtiva(modulo){
      switch (modulo){
        case 'RI': return this.config.atribuicaoRi;
        case 'RTD': return this.config.atribuicaoRtd;
        case 'RCPJ': return this.config.atribuicaoRcpj;
      }
      return false;
    },

    async loadConfig(){
      let permissoes = await GrupoPermissaoBusiness.getPermission().catch(err => ([]));
      this.$set(this, 'permissoes', permissoes);
    },

    getAttributeValue(object, attributeNamesGroup) {
      if (Utils.isEmptyNullOrUndefined(attributeNamesGroup) || !attributeNamesGroup.length) {
        return false;
      }
      let currentObject;

      for (const attributeNames of attributeNamesGroup) {
        for (const attributeName of attributeNames) {
          currentObject = object;
          const attributeParts = attributeName.split('.');
          for (const attribute of attributeParts) {
            currentObject = currentObject[attribute];
          }
          if (currentObject !== true) {
            break;
          }
        }
        if (currentObject === true) {
          break;
        }
      }
      return currentObject;
    },

    setAtribuicao(id, atribuicao = false) {
      this.atribuicao[id] = atribuicao;
    },

    getPermission(params = {}) {
      let isProtocolo = false;
      let {id, dominio, atribuicao, protocolo, finalizado, olharAtribuicao = true} = params;
      if (protocolo) {
        dominio = protocolo.dominio || 'PROTOCOLO_RI';
      }
      if (dominio && !atribuicao) {
        if (['OCORRENCIA', 'OFICIO', 'NOTIFICACAO'].includes(dominio)) {
          atribuicao = dominio;
        } else if (['PERSONALIZADO'].includes(dominio)) {
          atribuicao = 'TAREFA';
          dominio = 'TAREFA';
        } else {
          isProtocolo = true;
          atribuicao = DominioBusiness.getDominio(dominio)?.atribuicao;
        }
      }
      let apenasPermissao = ['PERMITIR_DESFAZER_REGISTRO_PROTOCOLO', 'PERMITIR_AVANCAR_ETAPA_PROTOCOLO', 'PERMITIR_VOLTAR_ETAPA_PROTOCOLO','PERMITIR_EDICAO_COMPLETA_PROTOCOLO_COM_ATRIBUICAO','PERMITIR_EDICAO_COMPLETA_PROTOCOLO_SEM_ATRIBUICAO','PERMITIR_ALTERAR_CERTIDAO_SELADA_SEM_ATRIBUICAO','PERMITIR_SELAR_SEM_ATRIBUICAO']
      if (finalizado !== false && protocolo?.status === 'FINALIZADO' && !apenasPermissao.includes(id)) {
        if (dominio) {
          const PERMITIR_ALTERAR_FINALIZADO = `PERMITIR_ALTERAR_${isProtocolo ? "PROTOCOLO" : dominio}_FINALIZADO`;
          let atribuido = true;

          if(olharAtribuicao) {
            atribuido = this.isGestorOrAdminUser ||
              this.atribuicao[protocolo.id] ||
              (protocolo.usuario?.id != undefined && this.usuarioLogado.id === protocolo.usuario?.id);
          }

          let PERMITIR_ALTERAR_PROTOCOLO_FINALIZADO = atribuido && this.getPermission({id: PERMITIR_ALTERAR_FINALIZADO, atribuicao: isProtocolo ? atribuicao : null, finalizado: false});
          if (id.startsWith('PERMITIR_ALTERAR_PROTOCOLO_REGISTRADO')) {
            return PERMITIR_ALTERAR_PROTOCOLO_FINALIZADO
          }
          if (!PERMITIR_ALTERAR_PROTOCOLO_FINALIZADO) {
            return false;
          }
        }
      }
      id += `${atribuicao ? '_'+atribuicao : ''}`
      let permissao = (this.permissoes || [])?.find(p => p.id === id);


      if (!permissao) return false;
      let nova = (this.usuarioLogado?.permissao || []).includes(permissao?.id);
      let verificarAntigo = !this.config.geral.usarGrupoPermissaoCompleto;
      if (verificarAntigo && !nova && permissao.local.length) {
        nova = this.getAttributeValue(permissao?.configuracao ? this.config : this.usuarioLogado, permissao.local);
      }
      return nova

    },

    getPermissionOrAssignment: GrupoPermissaoBusiness.getPermissionOrAssignment,

    getAdmin() {
      let permissoes = [  'PERMITIR_GERENCIAR_DEPARTAMENTO_USUARIO'
        , 'PERMITIR_GERENCIAR_GRUPO_PERMISSAO'
        , 'PERMITIR_GERENCIAR_GRUPO_ATRIBUICAO'
        , 'PERMITIR_GERENCIAR_CONFIGURACAO_GERAL'
        , 'PERMITIR_GERENCIAR_LISTAS'
        , 'PERMITIR_GERENCIAR_CHECKLIST'
        , 'PERMITIR_GERENCIAR_FRAGMENTO'
        , 'PERMITIR_GERENCIAR_LAYOUT_IMPRESSAO'
        , 'PERMITIR_GERENCIAR_MODELO_TEXTUAL'
        , 'PERMITIR_GERENCIAR_TIPO_DOCUMENTO'
        , 'PERMITIR_GERENCIAR_REPASSES'
        , 'PERMITIR_GERENCIAR_FERIADO'
        , 'PERMITIR_GERENCIAR_ANEXO'
        , 'PERMITIR_GERENCIAR_TAG'
        , 'PERMITIR_GERENCIAR_TIPO_INCONFORMIDADE'
        , 'PERMITIR_GERENCIAR_TIPO_PAUSA'
        , 'PERMITIR_GERENCIAR_OFICIO'
        , 'PERMITIR_GERENCIAR_NOTIFICACAO'
        , 'PERMITIR_GERENCIAR_OCORRENCIA'
        , 'PERMITIR_GERENCIAR_FILA_ATENDIMENTO'
        , 'PERMITIR_GERENCIAR_CAIXA'
        , 'PERMITIR_GERENCIAR_CATEGORIA_FINANCEIRA'
        , 'PERMITIR_GERENCIAR_SELO'
        , 'PERMITIR_VISUALIZAR_FUNDOS_REPASSES'
        , 'PERMITIR_VISUALIZAR_TABELA_CUSTAS'
        , 'PERMITIR_GERENCIAR_TIPO_BEM_MOVEL'
        , 'PERMITIR_GERENCIAR_PERSONALIZADO'
        , 'VISUALIZAR_RELATORIO_ACESSO'
      ];
      if (this.config.atribuicaoRi) {
        permissoes = [...permissoes, ...['GERENCIAR_CONFIGURACOES_PROTOCOLO_RI', 'GERENCIAR_CONFIGURACOES_CERTIDAO_RI']]
      }
      if (this.config.atribuicaoRtd) {
        permissoes = [...permissoes, ...['GERENCIAR_CONFIGURACOES_PROTOCOLO_RTD', 'GERENCIAR_CONFIGURACOES_CERTIDAO_RTD']]
      }
      if (this.config.atribuicaoRcpj) {
        permissoes = [...permissoes, ...['GERENCIAR_CONFIGURACOES_PROTOCOLO_RCPJ', 'GERENCIAR_CONFIGURACOES_CERTIDAO_RCPJ']]
      }
      for (const key of permissoes) {
        let value = this.getPermission({id: key})
        if (value) {
          return value;
        }
      }
      return false;
    },
    permitirAcessarGestaoFinanceira(){
      let permissoes = [
        'PERMITIR_CADASTRAR_DESPESAS',
        'PERMITIR_CADASTRAR_RECEITAS',
        'PERMITIR_GERENCIAR_DESPESAS',
        'PERMITIR_GERENCIAR_RECEITAS',
        'PERMITIR_CADASTRAR_TRANSFERENCIAS',
        'PERMITIR_GERENCIAR_CHEQUES',
        'PERMITIR_VIZUALIZAR_EXTRATO_GERAL',
        'PERMITIR_GERENCIAR_CAIXAS_INATIVOS',
        'PERMITIR_GERENCIAR_CAIXAS_ATIVOS',
        'PERMITIR_GERENCIAR_NOTAS_FISCAIS',
        'PERMITIR_VIZUALIZAR_CONSULTA_VALORES_A_RECEBER_DEVOLVER'
      ];
      for (const key of permissoes) {
        let value = this.$root.getPermission({id: key});
        if (value) {
          return value;
        }
      }
      return false;

    }
  },

  renderError (h, err) {
    return h('pre', { style: { color: 'red' }}, err.stack)
  }
}).$mount("#app");

