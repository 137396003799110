import extend from "@/commons/src/extend.js";
import cleanDeep from "@/commons/src/cleanDeep.js";
import moment from "moment";
import DOMPurify from "dompurify";

import _throttle from 'lodash/throttle';
import _debounce from 'lodash/debounce';
import _orderBy from 'lodash/orderBy';
import _uniqBy from 'lodash/uniqBy';
import _keyBy from 'lodash/keyBy.js';
import _groupBy from 'lodash/groupBy';
import _shuffle from 'lodash/shuffle';
import _uniq from 'lodash/uniq';
import _merge from 'lodash/merge';
import _defaultsDeep from 'lodash/defaultsDeep';
import _flatten from 'lodash/flatten';
import _get from 'lodash/get';
import _reduce from 'lodash/reduce';
import _intersection from 'lodash/intersection';
import _capitalize from 'lodash/capitalize';
import _cloneDeep from 'lodash/cloneDeep';
import _bigdecimal from "bigdecimal";
import _isEqual from "lodash/isEqual";
import _isObjectLike from "lodash/isObjectLike";
import _entries from "lodash/entries";
import _keys from "lodash/keys";
import _has from "lodash/has";
import _isUndefined from "lodash/isUndefined";
import _isArray from "lodash/isArray";
import _isNil from "lodash/isNil";

export default {

  throttle : _throttle,
  debounce : _debounce,
  orderBy : _orderBy,
  keyBy : _keyBy,
  uniqBy : _uniqBy,
  groupBy : _groupBy,
  shuffle : _shuffle,
  uniq : _uniq,
  merge : _merge,
  defaultsDeep : _defaultsDeep,
  flatten : _flatten,
  get : _get,
  isArray: _isArray,
  intersection : _intersection,
  capitalize : _capitalize,
  bigDecimal : _bigdecimal,

  cleanDeep: cleanDeep,
  safetyClone : _cloneDeep,
  cloneExtends : extend,
  clone : (data)  => {
    try{
      return JSON.parse(JSON.stringify(data));
    }catch (e) {
      return _cloneDeep(data);
    }
  },

/**
   * Deep diff between two object-likes
   * @param  {Object} fromObject the original object
   * @param  {Object} toObject   the updated object
   * @return {Object}            a new object which represents the diff
   */
  deepDiff(fromObject, toObject) {
    const changes = {};

    const buildPath = (path, obj, key) => _isUndefined(path) ? key : `${path}.${key}`;

    const walk = (fromObject, toObject, path) => {
      for (const key of _keys(fromObject)) {
        const currentPath = buildPath(path, fromObject, key);
        if (!_has(toObject, key)) {
          changes[currentPath] = {from: _get(fromObject, key)};
        }
      }

      for (const [key, to] of _entries(toObject)) {
        const currentPath = buildPath(path, toObject, key);
        if (!_has(fromObject, key)) {
          changes[currentPath] = {to};
        } else {
          const from = _get(fromObject, key);
          if (!_isEqual(from, to)) {
            if (_isObjectLike(to) && _isObjectLike(from)) {
              walk(from, to, currentPath);
            } else {
              changes[currentPath] = {from, to};
            }
          }
        }
      }
    };

    walk(fromObject, toObject);

    return changes;
  },

  isEqual(obj1, obj2){
    const obj1Parsed = Object.freeze(cleanDeep(JSON.parse(JSON.stringify(obj1))));
    const obj2Parsed = Object.freeze(cleanDeep(JSON.parse(JSON.stringify(obj2))));
    return _isEqual(obj1Parsed, obj2Parsed);
  },

  uuid(){
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  },

  removeHTML(html){
    let doc = new DOMParser().parseFromString(html || '', 'text/html');
    return doc.body.textContent || "";
  },

  toObject(arr, key, value){
    return _reduce(arr , function(out, obj) {
      out[obj[key]] = value ? obj[value] : obj;
      return out;
    }, {});
  },

  toNumber(number, decimals = 2){
    return parseFloat(parseFloat(number).toFixed(decimals));
  },

  truncateNumber(value, digits) {
    let re = new RegExp("(\\d+\\.\\d{" + digits + "})(\\d)"), m = value.toString().match(re);
    return m ? parseFloat(m[1]) : value;
  },

  sortAlphanumeric(arr, key, dir = 'asc'){

    try {
      let reA = /[^a-zA-Z]/g;
      let reN = /[^0-9]/g;

      const sort = function (_a, _b) {

        const aV = _get(_a, key);
        const bV = _get(_b, key);

        const a = typeof(aV) === "string" ? aV : typeof(aV) === "number" ? aV.toString() : "";
        const b = typeof(bV) === "string" ? bV : typeof(bV) === "number" ? bV.toString() : "";

        const aA = a.replace(reA, "");
        const bA = b.replace(reA, "");

        let result;

        if (aA === bA) {
          const aN = parseInt(a.replace(reN, ""), 10);
          const bN = parseInt(b.replace(reN, ""), 10);
          result = aN === bN ? 0 : aN > bN ? 1 : -1;
        } else {
          result = aA > bA ? 1 : -1;
        }

        return result * (dir == 'desc' ? -1 : 1);

      };

      return arr.sort(sort);
    } catch (e) {
      e;
    }
  },

  formatMoney(value = 0, digits = 2) {
    if (this.isEmptyNullOrUndefined(value)) value = 0;
    return parseFloat(value).toLocaleString("pt-BR", {
      minimumFractionDigits: digits?.min || digits,
      maximumFractionDigits: digits?.max || digits,
      style: 'currency',
      currency: 'BRL'
    }).replace("R$ ", "");
  },

  testUUID(uuid){
    let s = ("" + uuid).match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    return !(s === null);
  },

  getLabel(valor, vetor = [], id = 'id', nome = 'nome'){
    return (vetor.find(a => a[id] == valor) || {})[nome] || '';
  },

  getObjectKeys(obj, prefix = '') {
    return Object.keys(obj).reduce((res, k) => {
      const pre = prefix.length ? prefix + '.' : '';
      if (!obj[k] || moment.isMoment(obj[k]) || obj[k] instanceof Date || Array.isArray(obj[k])) {
        res.push(pre + k);
        return res;
      }
      if (typeof obj[k] === 'object') {
        res = res.concat(this.getObjectKeys(obj[k], pre + k));
      } else {
        res.push(pre + k);
      }
      return res;
    }, []);
  },

  getObjectId(obj){
    if(obj?.id){
      return  {id:obj.id};
    }
    return null;
  },

  stringify(obj, replacer, spaces, cycleReplacer) {

    function serializer(replacer, cycleReplacer) {
      let stack = [], keys = [];

      if (cycleReplacer == null) {
        cycleReplacer = function(key, value) {
          if (stack[0] === value){
            return "[Circular ~]";
          }
          return "[Circular ~." + keys.slice(0, stack.indexOf(value)).join(".") + "]";
        }
      }

      return function(key, value) {
        if (stack.length > 0) {
          let thisPos = stack.indexOf(this);
          ~thisPos ? stack.splice(thisPos + 1) : stack.push(this);
          ~thisPos ? keys.splice(thisPos, Infinity, key) : keys.push(key);
          if (~stack.indexOf(value)) {
            value = cycleReplacer.call(this, key, value);
          }
        }
        else{
          stack.push(value);
        }

        return replacer == null ? value : replacer.call(this, key, value);
      }
    }

    return JSON.stringify(obj, serializer(replacer, cycleReplacer), spaces)
  },

  stringCodigosToArray(stringCodigos) {

    let arrayCodigos = [];

    let string = (stringCodigos+'').replace(/[. ]+/g, "").toUpperCase();

    string.split(',').forEach(f => {

      let cleared = f.replace(/[^\d-]/g, '');

      if(cleared == f){

        if (f.includes('-')) {
          let [inicio, fim] = f.split('-').map(i => parseInt(i, 10));
          while (inicio <= fim) {
            arrayCodigos.push((inicio++));
          }
        } else if (f != '') {
          arrayCodigos.push(parseInt(f, 10));
        }

      }

    });

    return Array.from(new Set(arrayCodigos));

  },

  stringCodigosLetraToArray(stringCodigos) {

    let arrayCodigos = [];

    let string = (stringCodigos+'').replace(/[. ]+/g, "").toUpperCase();

    string.split(',').forEach(f => {
      if (f.includes('-')) {
        let cleared = f.replace(/[^\d-]/g, '');
        if(cleared == f) {
          let [inicio, fim] = f.split('-').map(i => parseInt(i, 10));
          while (inicio <= fim) {
            arrayCodigos.push((inicio++));
          }
        } else {
          arrayCodigos.push(f);
        }
      } else if (f != '') {
        arrayCodigos.push(f);
      }
    });

    return Array.from(new Set(arrayCodigos));

  },

  async getBase64ImageFromUrl(imageUrl) {
    let res = await fetch(imageUrl);
    let blob = await res.blob();

    return new Promise((resolve, reject) => {
      let reader  = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.onerror = () => reject(this);
      reader.readAsDataURL(blob);
    })
  },

  async getBase64FromFile(blob) {
    return new Promise((resolve, reject) => {
      let reader  = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.onerror = () => reject(this);
      reader.readAsDataURL(blob);
    })
  },

  utf8_to_b64( str ){
    return window.btoa(unescape(encodeURIComponent( str )));
  },

  b64_to_utf8( str ) {
    return decodeURIComponent(escape(window.atob( str )));
  },

  isEmptyNullOrUndefined(string, allowZero = false){
    return string === null || string === undefined || (allowZero ? false : string === 0) || string === "" || (allowZero ? false : string === "0") || (string.trim ? string.trim().length === 0 : false);
  },

  removerAcentos(str){
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
  },

  humanFileSize(bytes, si=false, dp=1) {
    const thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    const r = 10**dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
  },

  orderFields(obj, campos) {
    (obj || []).sort((a, b) => {
      let sortBy = campos;
      let i = 0, result = 0;
      while (i < sortBy.length && result === 0) {
        let itemA = a[sortBy[i]] || '', itemB = b[sortBy[i]] || '';
        let c1 = isNaN(parseInt(itemA)) || isNaN(parseInt(itemB)) ? itemA.toString() < itemB.toString() : (parseInt(itemA) === parseInt(itemB) ? itemA.toString() < itemB.toString() : parseInt(itemA) < parseInt(itemB));
        let c2 = isNaN(parseInt(itemA)) || isNaN(parseInt(itemB)) ? itemA.toString() > itemB.toString() : (parseInt(itemA) === parseInt(itemB) ? itemA.toString() > itemB.toString() : parseInt(itemA) > parseInt(itemB));
        result = (c1 ? -1 : (c2 ? 1 : 0));
        i++;
      }
      return result;
    });
  },

  onlyNumbers(e) {
    let charCode = e?.charCode ? e?.charCode : e?.keyCode;
    if (charCode !== 8 && charCode !== 9) {
      if (charCode < 48 || charCode > 57) {
        e.returnValue = false;
      }
    }
  },

  cleanHtml(html) {
    return DOMPurify.sanitize(html);
  },

  isMobile() {
    return window.matchMedia("only screen and (max-width: 800px)").matches;
    /*
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)
        || ('ontouchstart' in window || navigator.maxTouchPoints > 0 && window.matchMedia("only screen and (max-width: 800px)").matches);
     */
  },

  formatters: {

    currency(value, decimais = 2, empty = '0,00') {
      return !value ? empty : Number(value).toLocaleString("pt-BR", {
        minimumFractionDigits: decimais?.min || decimais,
        maximumFractionDigits: decimais?.max || decimais,
        style: 'decimal',
        currency: 'BRL'
      });
    },

    money(value, max = 5) {

      if(!value){
        return '-';
      }

      let v = Number(value).toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: max,
        style: 'decimal',
        currency: 'BRL'
      });

      return `${v}`;

    },

    fromNow(value) {
      return moment(value, "YYYY-MM-DD HH:mm:ss").fromNow();
    },

    date(value, empty = '-') {
      if (value != null && typeof (value) === 'object' && value?.year) value = new Date(value?.year, value?.monthValue - 1, value?.dayOfMonth, value?.hour, value?.minute, value?.second);
      return !value ? empty : moment(value, "YYYY-MM-DD").format("DD/MM/YYYY");
    },

    datetime(value, empty = '-') {
      if (value != null && typeof (value) === 'object' && value?.year) value = new Date(value?.year, value?.monthValue - 1, value?.dayOfMonth, value?.hour, value?.minute, value?.second);
      return !value ? empty : moment(value).format("DD/MM/YYYY HH:mm:ss");
    },

    filesize(size) {
      if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
      } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
      } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + ' MB'
      } else if (size > 1024) {
        return (size / 1024).toFixed(2) + ' KB'
      }
      return size.toString() + ' B'
    },

    integer(value, empty = '-') {
      return !value ? empty : Number(value).toLocaleString("pt-BR", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: 'decimal',
        currency: 'BRL'
      });
    },

    getLabel(valor, vetor = [], id = 'id', nome = 'nome'){
      return (vetor.find(a => a[id] == valor) || {})[nome] || '';
    },

    boolean(value, empty) {
      if(value === true){
        return 'Sim';
      }
      if(value === false){
        return 'Não';
      }
      return empty;
    },


  }


}
