<template>
  <div id="aba-dados-principais">
    <card v-if="loading">
      <small-loading :loading="true"></small-loading>
    </card>

    <template v-else>
      <template v-for="(alerta, index) in alertas" >
        <AlertBanner v-if="alerta.mostrar" :key="index" :message="alerta.msg" :type="alerta.tipo" />
      </template>
      <card titulo="Dados Gerais">
        <card-content>
          <p v-if="ficha.serventia">
            <span class="font-weight-500">Serventia: </span><span>{{ficha.serventia}}</span>
          </p>
          <p v-if="ficha.tipo">
            <span class="font-weight-500">Tipo: </span><span>{{nomearFicha(ficha, false)}}</span>
          </p>
          <p v-if="ficha.codigo">
            <span class="font-weight-500">Código: </span><span>{{ficha.codigo}}</span>
          </p>
          <p v-if="ficha.livroNumero">
            <span class="font-weight-500">Livro (Número): </span><span>{{ficha.livroNumero}}</span>
          </p>
          <p v-if="ficha.livroLetra">
            <span class="font-weight-500">Livro (Letra): </span><span>{{ficha.livroLetra}}</span>
          </p>
          <p v-if="ficha.livroFolha">
            <span class="font-weight-500">Folha: </span><span>{{ficha.livroFolha}}</span>
          </p>

        </card-content>
      </card>

      <card titulo="Protocolos Relacionados">
        <card-table class="mb-0" v-if="protocolos.length">
          <thead>
            <tr>
              <th width="30px"></th>
              <th>Protocolo</th>
              <th>Serviço</th>
              <th>Etapa</th>
              <th>Cadastro</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(protocolo) in protocolos" :key="protocolo.id">
              <td width="30px" class="text-center">
                <router-link :to="{ name: abrirProtocolo(protocolo), params:{id : protocolo.id} }" class="icon" target="_blank" @click.native="$event.stopImmediatePropagation()">
                  <i class="fas fa-external-link-alt"></i>
                </router-link>
              </td>
              <td>{{nomearProtocolo(protocolo)}}</td>
              <td>{{protocolo.tipoServico}}</td>
              <td>{{protocolo.etapa}}</td>
              <td>{{protocolo.cadastro | datahora}}</td>
            </tr>
          </tbody>

        </card-table>
        <div class="alert alert-outline-secondary" role="alert" v-if="!protocolos.length">
          Nenhum registro encontrado
        </div>
      </card>
    </template>

  </div>
</template>

<script>
import FrontBusiness from "@/business/FrontBusiness.js";
import IndicadorRealExternoBusiness from "@/business/indicadores/IndicadorRealExternoBusiness.js";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import CardTable from "@/components/Layout/components/CardTable.vue";
import SmallLoading from "@/components/SmallLoading";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness";
import AlertBanner from "@/components/alert/AlertBanner.vue";
import AlertMessage from "@/components/alert/AlertConstMessage";

export default {

  name: "Dados",
  components: {AlertBanner, CardContent, CardTable, Card, SmallLoading},
  props: {
    id: String,
  },

  data() {
    return {
      ficha: {},
      protocolos: [],
      loading : true,
      protocolosAtivos: false,
    }
  },

  async mounted(){
    await this.load();
  },

  computed: {
    alertas() {
      return [
        {mostrar: this.protocolosAtivos, msg: AlertMessage.indicadorPossuiProtocolos(), tipo : 'alert-warning'}
      ];
    },
  },

  methods: {
    nomearFicha: IndicadorRealExternoBusiness.nomearFicha,

    nomearProtocolo(protocolo){
      if(protocolo.dominio === 'OFICIO') {
        return 'Ofício ' + protocolo.codigo;
      } else if (protocolo.dominio === 'NOTIFICACAO'){
        return 'Notificação ' + protocolo.codigo;
      }
      return FrontBusiness.nomearProtocolo(protocolo.dominio, protocolo.tipoProtocolo, protocolo.codigo);
    },

    abrirProtocolo(protocolo){
      if(protocolo.dominio === 'OFICIO'){
        return 'oficio'
      } else if (protocolo.dominio === 'NOTIFICACAO'){
        return 'notificacao'
      } else {
        return 'protocolo'
      }
    },

    async load(){
      this.loading = true;
      this.ficha = await IndicadorRealExternoBusiness.getById(this.id);
      this.protocolos = await IndicadorRealExternoBusiness.buscarProtocolosAtivos(this.id);
      this.loading = false;
      this.protocolosAtivos = this.protocolos.length > 0;
    },
  }
}
</script>
