<template>
  <modal id="form-registro-td" :titulo="titulo" :loading="!form">

    <form @submit.prevent="" v-if="form" autocomplete="off">

      <card>

        <card-content class="row gutters-xs">

          <v-input :error="validation.tipoBem" v-model="form.tipoBem" :options="options.tipos"
                   type="v-select" class="col-4" label="Tipo" />
          <div class="w-100" />

          <v-input class="col-6" v-model="form.nome" :error="validation.nome" type="text" label="Nome" />
          <v-input class="col-3" v-model="form.identificacao" type="text" label="Identificação" />

          <v-input class="col-3" label="Data de Referência" v-model="form.dataReferencia"
                   type="date" />

          <v-input class="col-6" v-model="form.localizacao" type="text" label="Localização" />

          <v-input class="col-2" label="Valor" v-model="form.valor"
                   type="currency" :input-props="{precision : 2}" />

          <v-input class="col-4" v-model="form.notaFiscal" type="text" label="Nota fiscal" />
          <v-input class="col-12" v-model="form.detalhes" type="text" label="Detalhes" />

        </card-content>

      </card>
    </form>

    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-auto">Cancelar</button>
      <v-button :run="salvar" class="btn-outline-primary">Salvar</v-button>
    </template>

  </modal>
</template>
<script>

  import FrontBusiness from "@/business/FrontBusiness";
  import RegistroTDBusiness from '@/business/indicadores/RegistroTDBusiness.js';
  import utils from "@/commons/Utils";
  import Card from "@/components/Layout/components/Card.vue";
  import CardContent from "@/components/Layout/components/CardContent.vue";
  import Modal from "@/components/Modal";
  import TipoBemMovelBusiness from "@/business/crud/TipoBemBusiness";
  import BemMovelBusiness from "@/business/crud/BemMovelBusiness";

  export default {
    name: "FormBemMovel",
    components: {
      Card,
      CardContent,
      Modal
    },

    modal: {
      escapable: false
    },

    props: {
      id: String,
      livro: String,
      clone: {type: Object, default: () => ({})}
    },

    async created() {
      this.options.tipos = await TipoBemMovelBusiness.getSimpleList();
    },

    async mounted() {

      let dto = {};

      if (this.id) {
        dto = await BemMovelBusiness.getById(this.id);
        this.titulo = `Editar Bem Móvel`;
      } else {
        this.titulo = 'Adicionar Bem Móvel';
        dto = {...this.clone};
      }

      let form = {
        ...dto
      };

      form.abertura = dto.abertura ? this.$moment(dto.abertura).format('YYYY-MM-DDTHH:mm:ss') : null;
      form.encerramento = dto.encerramento ? this.$moment(dto.encerramento).format('YYYY-MM-DDTHH:mm:ss') : null;

      this.$set(this, 'form', form);
    },

    data() {
      return {
        form: null,
        validation: {},
        titulo: '',
        decimais: 2,
        options: {
          tipos: [],
          statusEdicao: RegistroTDBusiness.getStatusImovel(false),
          livros: [
            {id: 'MATRICULA', nome: 'Matrícula'},
            {id: 'PRE_MATRICULA', nome: 'Pré-Matrícula'}
          ]
        }
      }
    },

    computed: {
      nomeLivro() {
          return FrontBusiness.nomearFicha({livro: this.form?.livro || this.livro, status: ''}, false);
      },
    },

    methods: {

      close() {
        FrontBusiness.closeModal();
      },

      showError(e) {
        FrontBusiness.fullErrorHandler(this, e, '#form-registro-titulo');
      },

      nomearLivro: FrontBusiness.nomearFicha,

      async salvar() {
        this.form.livro = this.form.livro || 'BEM_MOVEL';
        let dto = utils.clone(this.form);
        let validated = true;
        let id = dto.tipoBem?.id || dto.tipoBem
        dto.tipoBem = id ? {id} : null;

        let obrigatorios = ['nome', 'tipoBem'];

        obrigatorios.forEach(e => {
          if (!dto[e]) {
            this.$set(this.validation, e, 'Campo obrigatório');
            validated = false;
          }
        });

        if(validated) {
          let retorno = await BemMovelBusiness.save(dto, this.id).catch(this.showError);
          if (retorno) {
            FrontBusiness.closeModal(retorno);
          }
        }
      },

    }
  }
</script>

<style lang=scss>
  #form-registro-td {
    .card {
      hr {
        margin-top: .25rem;
      }

      .busca-cep {
        margin-top: 21px;
      }

      .card-body {
        padding-bottom: 0;
      }
    }
  }
</style>
