import DominioBusiness from "@/business/protocolo/DominioBusiness.js";
import ProtocoloBusiness from "@/business/protocolo/ProtocoloBusiness.js";
import EventBus from "@/commons/EventBus.js";
import Utils from "@/commons/Utils.js";
import ModalConfirmacao from "@/components/ModalConfirmacao.vue";

import Fraction from 'fraction.js';

let modals = [];

const getSpecialDateImagem = () => {
  if((new Date()).getMonth() == 11){
    return 'natal';
  }
  return '';
};

// bug de referencia circular com o indicadorbusiness
const getLivro = function(livro){
    return [
      {id: 'MATRICULA', nome: 'Matrícula', grupo : 'INDICADOR_REAL', sigla : 'M'},
      {id: 'REGISTRO_AUXILIAR', nome: 'Registro Auxiliar', grupo : 'REGISTRO_AUXILIAR', sigla : 'RA'},
      {id: 'TRANSCRICAO', nome: 'Transcrição', grupo : 'INDICADOR_REAL', sigla : 'T'},
      {id: 'REGISTRO_TD', nome: 'Registro TD', grupo : 'REGISTRO_TD', sigla : 'RTD'},
      {id: 'REGISTRO_PJ', nome: 'Registro PJ', grupo : 'REGISTRO_PJ', sigla : 'RCPJ'},
      {id: 'BEM_MOVEL', nome: 'Bem Móvel', grupo : 'BEM_MOVEL', sigla : 'BM'},
    ].find(e => e.id == livro);
}

const formatarCodigo = function(value = 0, obj = false){

  if(obj && obj?.status == 'PENDENTE'){
    return `{{PRE-${obj?.livro}-${obj.codigo}}}`;
  }

  if(value == undefined || value == null){
    return '';
  }

  return ''+parseFloat(value).toLocaleString("pt-BR", {
    minimumFractionDigits: 0,
    style: 'decimal',
    currency: 'BRL'
  }).replace("R$ ", "");
};

const formatarCodigoPreMatricula = function(value, obj){

  if(obj && obj?.status == 'PENDENTE'){
    // console.debug('pre-matricula', `{{MATRICULA-${obj.id}}}`, value);
    return `{{PRE-${obj?.livro}-${obj.codigo}}}`; //--${value}
  }

  return value;
};

const getLabel = function(valor, vetor = [], id = 'id', nome = 'nome'){
  return (vetor?.find(a => a[id] == valor) || {})?.[nome] || '';
};

const twoDecimals = (valor) => {
  return parseFloat(parseFloat(valor || 0).toFixed(2));
};

const fiveDecimals = (valor) => {
  return parseFloat(parseFloat(valor || 0).toFixed(5));
};

export default {

  maxUploadSize: 524288000,//1073741824

  logWithTrace(msg){
    console.groupCollapsed(msg);
    console.debug(...arguments);
    console.trace(); // hidden in collapsed group
    console.groupEnd();
  },

  /** *****************************************************************************/

  async showConfirmacao(title = '', msg = '', input = null, inputMsg = null, observacao = null, inputValue = 0) {
    return new Promise(async (resolve) => {
      let r = await this.openConfirmacao(title, msg, inputMsg, observacao);

      if(r == 'confirm'){
        return resolve({value: 1, isConfirmed : true});
      }

      if(r == 'cancel'){
        return resolve({dismiss: 'cancel'});
      }

      resolve(false);

    });

    // let alert = EventBus.$swal({
    //   title: title,
    //   html: msg,
    //   showCancelButton: true,
    //   input: input,
    //   inputPlaceholder: inputMsg,
    //   inputValue: inputValue,
    //   inputValidator: (result) => {
    //     return !result && 'Você precisa concordar com esta ação para prosseguir.'
    //   },
    //   customClass: {
    //     popup: 'confirm-popup',
    //     title: 'confirm-title',
    //     content: 'confirm-content',
    //     actions: 'confirm-actions',
    //     confirmButton: 'btn btn-sm mx-1 confirm-btn-primary btn-primary',
    //     cancelButton: 'btn btn-sm mx-1 confirm-btn-danger btn-danger',
    //
    //   },
    //   confirmButtonText: 'Sim',
    //   cancelButtonText: 'Não',
    //   buttonsStyling: false
    // });
    //
    // return alert
  },

  /** *****************************************************************************/

  openConfirmacao(msg = '', pergunta = '', confirmacao = null, observacao = null, escapable = true) {
    return this.openModal(ModalConfirmacao, {pergunta, msg, confirmacao, observacao}, 400, escapable);
  },

  openConsulta(component, params = {}) {
    return this.openModal(component, params, 1600);
  },

  openModal(component, params = {}, width = 800, escapable = false) {

    /** OPÇÕES PERSONALIZADAS **/
    width = component?.modal?.width || width;
    const clickToClose = false; // component?.modal?.escapable != undefined ? component.modal.escapable : escapable;

    const clone = Utils.safetyClone(component);
    /** CRIA NOME ÚNICO **/
    const name = 'modal-' + Utils.uuid();
    modals.push(name);

    /** CRIA PROMESSA DO MODAL PARA OS RETORNOS **/
    return new Promise((resolve) => {
      EventBus.$modal.show(clone, params,
        {
          maxWidth: width,
          width: "95%",
          name,
          clickToClose,
          scrollable: false,
          draggable: '.modal-header .mover-modal',
          height: 'auto',
          adaptive: true
        },
        {
          'before-close': event => {
            modals = modals.filter(e => e != event.name);
            return resolve(event.params);
          }
        });
    });
  },

  closeModal(result) {
    // fecha sempre o modal mais recente
    EventBus.$modal.hide(modals.pop(), result);
  },

  closeAllModals() {
    modals.forEach(id => EventBus.$modal.hide(id));
  },

  isOpen() {
    return modals.length ? true : false
  },

  /** *****************************************************************************/

  fullErrorHandler(vm, e, wrapper = '#wrapper') {
    console.error(e);
    vm.$nextTick(() => {
      vm.validation = {};
      let v = this.errorHandler(e);
      if (v) {
        vm.validation = v;
        vm.$nextTick(() => {
          this.scrollTo(wrapper, `${wrapper} .is-invalid`, -70);
        });
      } else {
        this.showError('', e.message);
      }
    });
  },

  errorHandler(err) {
    if (typeof err === 'object' && err.validation) {
      let validation = {};
      Object.keys(err.validation).forEach(key => {
        validation[key] = err.validation[key].split(':')[0];
      });
      return validation;
    }
    return false;
  },

  showError(title, msg, group = 'app', duration = -1) {
    if(Array.isArray(msg)){
      msg.forEach(m => this._showError(title, m, group, duration));
    } else {
      this._showError(title, msg, group, duration);
    }
  },

  _showError(title, msg, group, duration= -1){
    if(title || msg){
      EventBus.$notify({title: title, ignoreDuplicates : true, text: msg, type: 'error', group, duration: duration, closeOnClick : false});
    }
  },

  showSuccess(title, msg, group = 'app', duration = 30000) {
    if(title || msg){
      EventBus.$notify({title: title, ignoreDuplicates : true, text: msg, type: 'success', group, duration: duration});
    }
  },

  showWarn(title, msg, group = 'app', duration = -1) {
    if(title || msg){
      EventBus.$notify({title: title, ignoreDuplicates : true, text: msg, type: 'warn', group, duration: duration, closeOnClick : false});
    }
  },

  /** *****************************************************************************/

  nomearLivro(livro){
    return getLivro(livro)?.nome || livro;
  },

  formatarCodigo : formatarCodigo,
  formatarCodigoPreMatricula : formatarCodigoPreMatricula,
  getLabel : getLabel,

  nomearFicha(ficha = {}, codigo = true, resumirFicha = false, livroLetra = true){
    let nome = ficha.status == 'PENDENTE' || ficha.prematricula? 'Pré-' : '';
    let livro = getLivro(ficha.livro)?.nome + ' ';
    if(resumirFicha){
      livro = getLivro(ficha.livro)?.sigla+'-';
      if(ficha.livroTranscricao){
        livro = ficha.livroTranscricao+'-';
      }
    }
    nome += livro;

    if(ficha.livroTranscricao && !resumirFicha){
      nome += ' '+ficha.livroTranscricao+'-';
    }

    let letra = `${livroLetra && ficha.livroLetra ? ficha.livroLetra : ''}`;
    if(letra != '' && ficha.livro != 'TRANSCRICAO' && /^[a-zA-Z0-9]+$/.test(letra)){
      letra = '-' + letra;
    }
    if(letra != '' && ficha.livro == 'TRANSCRICAO'){
      letra = '-' + `${ficha.livroNumero ? ficha.livroNumero : ''}` + `${ficha.livroLetra ? ficha.livroLetra : ''}`;
    }
    return `${nome}${codigo ? formatarCodigo(ficha.codigo) : ''}${letra}`.trim();
  },

  nomearProtocolo(dominio, tipoProtocolo, codigo, tipoCadastro, letra = null){

    let dominioStr = getLabel(dominio, DominioBusiness.getDominios());
    let tipoProtocoloStr = getLabel(tipoProtocolo, ProtocoloBusiness.getTiposProtocolo());
    let atribuicao = 'teste';

    if(['PROTOCOLO_RI','CERTIDAO_RI'].includes(dominio)){
      atribuicao = 'RI';
    }else if(['PROTOCOLO_RTD','CERTIDAO_RTD'].includes(dominio)){
      atribuicao = 'RTD';
    }else if(['PROTOCOLO_RCPJ','CERTIDAO_RCPJ'].includes(dominio))
      atribuicao = 'RCPJ';

    let str = '';

    if(tipoCadastro){
      switch (tipoCadastro) {
        case 'ONR':
        case 'ARISP':
          if(dominio == 'PROTOCOLO_RI'){
            str += '[E-Protocolo] ';
          }
          if(dominio == 'CERTIDAO_RI'){
            str += '[E-Certidão] ';
          }
          break;
        case 'E_CERTIDAO':
        case 'SITE':
          str += '[E-Certidão] ';
          break;
      }
    }

    if(tipoProtocolo && tipoProtocolo != 'NORMAL'){
      str += tipoProtocoloStr;
      if(tipoProtocolo === 'ORCAMENTO'/* && codigo*/){
        str += ` de ${dominioStr}`;
      }else if(tipoProtocolo === 'EXAME_CALCULO'){
        str += ` ${atribuicao}`;
      }

    }else{
      str += dominioStr;
    }

    if(codigo){
      str += ' ' + formatarCodigo(codigo);
      if(letra){
        str += '-'+letra;
      }
    }

    return str.replace('Orçamento de Pedido de', 'Orçamento de');

    //return `${tipoProtocolo && tipoProtocolo != 'NORMAL' ? `${tipoProtocoloStr} ${tipoProtocolo === 'ORCAMENTO' && codigo ? `de ${dominioStr}` : ''}` : dominioStr} ${codigo ? ' ' + formatarCodigo(codigo) : ''}`;

  },

  /** *****************************************************************************/

  async abrirJanelaPromise(promise, name = 'Download PDF', type = "application/pdf") {
    const blob = await promise.then(r => {
      return new Blob([r], {type: type});
    }).catch(e => Promise.reject(e));
    this.abrirJanela(blob, name);
  },

  abrirJanela(blob, name = 'Download PDF', url = null) {
    const fileURL = blob ? URL.createObjectURL(blob) : null;
    const w = window.open(url || fileURL, '', "toolbar=no,scrollbars=yes,resizable=no,width=1000,height=600");

    setTimeout(() => {
      try {
        w.focus();
      } catch (e) {
        alert("Desative todos os bloqueadores de anúncio do seu navegador");
      }
    }, 200);

    return w;
  },

  getFileExtension (fileValue) {
    return fileValue.slice((fileValue.lastIndexOf(".") - 1 >>> 0) + 2)
  },

  downloadUrl(url, fileName) {

    if(!url){
      return;
    }

    // Create an invisible A element
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);

    // Set the HREF to a Blob representation of the data to be downloaded
    a.href = url;
    a.target = '_blank';

    if(fileName){
      a.setAttribute("download", fileName);
    }

    // Trigger the download by simulating click
    a.click();

    // Cleanup
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  },

  downloadBase64(base64, fileName) {

    // Create an invisible A element
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);

    // Set the HREF to a Blob representation of the data to be downloaded
    a.href = base64;

    // Use download attribute to set set desired file name
    a.setAttribute("download", fileName);

    // Trigger the download by simulating click
    a.click();

    // Cleanup
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  },

  downloadFile(blob, fileName) {

    if(!blob){
      return;
    }

    if(typeof blob === 'string'){
      this.downloadUrl(blob, fileName)
      return;
    }

    // Create an invisible A element
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);

    // Set the HREF to a Blob representation of the data to be downloaded
    a.href = window.URL.createObjectURL(blob);

    // Use download attribute to set set desired file name
    a.setAttribute("download", fileName);

    // Trigger the download by simulating click
    a.click();

    // Cleanup
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  },

  async downloadPromise(promise, fileName, type = "application/pdf") {

    const blob = await promise.then(r => {
        return new Blob([r], {type: type});
      }).catch(e => Promise.reject(e));

    this.downloadFile(blob, fileName);
  },

  downloadTxt(filename, text, encript = false) {
    const element = document.createElement('a');

    if(encript){
      text = btoa(text || ' ').reverse();
    }

    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  },

  downloadCsv(filename, dados, colunas) {
    let csv = '';
    let separador;
    if (colunas.length) {
      for (let i = 0; i < colunas.length; i++) {
        separador = (separador ? '; ' : '');
        csv += separador + colunas[i].label;
      }
      csv += '\n';
    }
    separador = undefined;
    dados.forEach(function (row) {
      if (colunas.length) {
        for (let i = 0; i < colunas.length; i++) {
          separador = (separador ? '; ' : '');
          csv += separador + (colunas[i].field ? row[colunas[i].field] : row);
        }
      } else {
        csv += row;
      }
        csv += '\n';
      });
      let hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
      hiddenElement.target = '_blank';
      hiddenElement.download = filename;
      hiddenElement.click();
  },

  scrollTo(el, to, margin = 0) {

    let debug = true;

    const easeInOutQuad = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return c / 2 * t * t + b;
      t--;
      return -c / 2 * (t * (t - 2) - 1) + b;
    };

    const scrollTo = (element, to, duration) => {
      var start = element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20;

      var animateScroll = function () {
        currentTime += increment;
        var val = easeInOutQuad(currentTime, start, change, duration);
        element.scrollTop = val;
        if (currentTime < duration) {
          setTimeout(animateScroll, increment);
        }
      };
      animateScroll();
    };

    const getElemDistance = function (elem) {
      let location = -30 + margin;
      if (elem && elem.offsetParent) {
        do {
          location += elem.offsetTop;
          elem = elem.offsetParent;
        } while (elem);
      }
      return location >= 0 ? location : 0;
    };

    let distance = typeof to != 'number' ? getElemDistance(document.querySelector(to)) : to;
    let element = typeof el == 'string' ? document.querySelector(el) : el;

    scrollTo(element, distance, 800);

  },

  toFraction(number){
    return (new Fraction((new Fraction(number/100)).simplify())).toFraction();
  },

  formatNumber(number, decimals, max = undefined){
    return parseFloat(number).toLocaleString("pt-BR", {
      minimumFractionDigits: decimals,
      maximumFractionDigits: max || decimals,
      style: 'currency',
      currency: 'BRL'
    }).replace("R$ ", "");
  },

  formatArray(arr, sep = ", ", last = " e "){
    if (arr.length === 1) {
      return arr[0];
    } else if (arr.length === 2) {
      return arr.join(last);
    } else if (arr.length > 2) {
      return arr.slice(0, -1).join(sep) + last + arr.slice(-1);
    }
    return '';
  },

  agruparArray(lista, chaves, proxy = (v) => v){

    const createGroup = (o, key, ...keys) => key ? createGroup(o[key] = o[key] || {}, ...keys) : o;

    let result = {};

    lista.forEach( (grupo) => {
      const valores = chaves.map(i => proxy(grupo[i]) );
      const key = createGroup(result, ...valores);
      key.valores = (key.valores || []).concat(grupo);
    });

    const createArray = (obj, key, ...keys) => {
      return !key || obj.valores ? obj.valores : Object.keys(obj).map( k => {
        let o = { valores : createArray(obj[k], ...keys) || [] };
        o.quantidade = (o.valores.length + '');
        o[key] = k;
        return o;
      });

    };

    const novaLista = createArray(result, ...chaves);

    return novaLista;

  },

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  },

  redirect(path = '', blank= false){

    let pp = window?.server?.publicPath || '/';

    if (blank) {
      window.open(`${pp}#/${path}`, "_blank")
    } else {
      window.location.href = `${pp}#/${path}`;
      window.location.reload();
    }
  },

  reload(){
    window.location.reload();
  },

  print(html, titulo) {

    // Create a random name for the print frame.
    const strFrameName = ("printer-" + (new Date()).getTime());

    // Create an iFrame with the new name.
    const jFrame = document.createElement("iframe");
    jFrame.setAttribute("name", strFrameName);

    jFrame.style.width = "1px";
    jFrame.style.height = "1px";
    jFrame.style.position = "absolute";
    jFrame.style.left = "-9999px";

    document.body.appendChild(jFrame);

    const objFrame = window.frames[strFrameName];
    const objDoc = objFrame.document;
    objDoc.open();

    const body = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
      <html>
        <body>
          <head>
            <title>
            ${titulo}
            </title>
          </head>
          ${html}
        </body>
      </html>`;

    objDoc.write(body);
    objDoc.close();

    // Print the document.
    objFrame.focus();
    objFrame.print();

    setTimeout(() => jFrame.remove(), 30 * 1000);
  },

  /** *****************************************************************************/
  twoDecimals : twoDecimals,
  fiveDecimals: fiveDecimals,

  somarTaxas(custas, out = {emolumentos : 0, fundos : {}, total : 0, iss : 0, custoSelo : 0, taxaJudiciaria : 0, fundosEstaduais : 0}, quantidade = 1, grupo = ''){

    if(!custas){
      return out;
    }

    Object.values(custas).forEach(custa => {

      let qtd = custa.quantidade || 1;
      out.emolumentos = fiveDecimals((fiveDecimals(out.emolumentos) + fiveDecimals(fiveDecimals(custa.emolumentos * qtd) * quantidade)));

      if(custa.iss){
        out.iss = fiveDecimals((fiveDecimals(out.iss) + fiveDecimals(fiveDecimals(custa.iss * qtd) * quantidade)));
      }

      if(custa.custoSelo){
        out.custoSelo = fiveDecimals((fiveDecimals(out.custoSelo) + fiveDecimals(fiveDecimals(custa.custoSelo * qtd) * quantidade)));
      }

      if(custa.taxaJudiciaria){
        out.taxaJudiciaria = fiveDecimals((fiveDecimals(out.taxaJudiciaria) + fiveDecimals(fiveDecimals(custa.taxaJudiciaria * qtd) * quantidade)));
      }

      if(custa.fundosEstaduais){
        out.fundosEstaduais = fiveDecimals((fiveDecimals(out.fundosEstaduais) + fiveDecimals(fiveDecimals(custa.fundosEstaduais * qtd) * quantidade)));
      }

      Object.keys(custa?.fundos || {}).forEach((taxa) => {
        let valor = fiveDecimals(fiveDecimals(custa.fundos[taxa] * qtd) * quantidade);
        taxa = taxa == 'ART. 15,X, 19.191' ? 'ART-15' : taxa;
        out.fundos[taxa] = fiveDecimals( (fiveDecimals(out.fundos[taxa]) + valor));
      });

      out.total = out.total + twoDecimals(twoDecimals(custa.total * qtd) * quantidade);

      // console.debug('Resumo', grupo, quantidade, Utils.clone(out.emolumentos), custa, custas);
    });

    out.total = twoDecimals(out.total);
    out.valor = out.total;
    out.emolumentos = twoDecimals(out.emolumentos);
    out.iss = twoDecimals(out.iss);
    out.custoSelo = twoDecimals(out.custoSelo);
    out.fundosEstaduais = twoDecimals(out.fundosEstaduais);
    out.taxaJudiciaria = twoDecimals(out.taxaJudiciaria);
    Object.keys(out.fundos).forEach((taxa) => {
      out.fundos[taxa] = twoDecimals(out.fundos[taxa]);
    });

    return out;

  },

  getDominios() {
    return [
      ...DominioBusiness.dominiosAtivos(),
      {id: 'OFICIO', nome: 'Ofício', genero: 'o'},
      {id: 'NOTIFICACAO', nome: 'Notificação', genero: 'a'},
      {id: 'OCORRENCIA', nome: 'Ocorrência', genero: 'a'},
      {id: 'PERSONALIZADO', nome: 'Tarefa', genero: 'a'}
    ];
  },

  tiposDigitalizacao() {
    return [
      { id: '', nome: 'Todos' },
      { id: 'ATUALIZADA', nome: 'Atualizada' },
      { id: 'DESATUALIZADA', nome: 'Desatualizada' }
    ];
  },

  setFocus(elementQuery, idElement){
    const el = elementQuery.querySelector(`#${idElement}`);
    el.scrollIntoView({behavior: 'smooth', block: "center"})
    el.focus();
  },

  chooseTheme(theme = 'dark'){
    let remove = theme == 'dark' ? 'light' : 'dark';
    document.body.classList.remove('theme-'+remove);
    document.body.classList.add('theme-'+theme);
  },

  delayedView(el, fnc = () => {}, opt = {}, force = false){

    const observer = new IntersectionObserver((entries ) => {
      const el = entries?.[0];
      if (el?.isIntersecting) {
        if(force || el?.intersectionRatio > 0){
          if(fnc()){
            this.observer?.unobserve(this.$el);
            this.observer?.disconnect();
          }
        }
      }
    },{
      threshold: 0.5,
      trackVisibility: true,
      delay: 500,
      ...opt
    });

    observer.observe(el);

    return observer;

  },

  getUrlLogo(){
    switch (getSpecialDateImagem()){
      case 'natal':  return `img/logo-natal.png`;
      default: return `iconx/pwa-192x192.png`;
    }
  },

  getUrlDrakkar(){
    switch (getSpecialDateImagem()){
      case 'natal':  return `img/release-icon-natal.png`;
      default: return `img/release-icon.svg`;
    }
  },

  tiposBuscaFiltro(tipo) {
    let tipos = [
      {id: 'contains', nome: 'Contém', tipos: ['text', 'tsquery', 'cpfCnpj', 'cpf', 'cnpj', 'area']},
      {id: 'equal', nome: 'Igual', tipos: ['text', 'date', 'cpfCnpj', 'cpf', 'cnpj', 'numeric', 'area']},
      {id: 'starts', nome: 'Começa com', tipos: ['text', 'cpfCnpj', 'cpf', 'cnpj']},
      {id: 'ends', nome: 'Termina com', tipos: ['text', 'cpfCnpj', 'cpf', 'cnpj']},
      {id: 'between', nome: 'Entre o Intervalo', tipos: ['date', 'numeric']},
      {id: 'after', nome: 'Maior ou Igual', tipos: ['date', 'numeric']},
      {id: 'before', nome: 'Menor ou Igual', tipos: ['date', 'numeric']},
      {id: 'bigger', nome: 'Maior que', tipos: ['area']},
      {id: 'smaller', nome: 'Menor que', tipos: ['area']},
      {id: 'empty', nome: 'Vazio', tipos: ['tsquery']}
    ];
    return tipos.filter(t => t.tipos.includes(tipo))
  }

}
