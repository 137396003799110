<template>

  <modal id="detalhes-notificacao" class="modal-detalhes-notificacao" :titulo="titulo" :loading="loading">

    <template v-if="notificacao">
      <div id="aba-dados-principais" v-if="notificacao">

        <card>
          <div class="row gutters-xs oficio">
            <v-input type="v-select" :options="listas.tiposServico" label="Tipo de Notificação" class="col"
                     v-model="notificacao.tipoServico.id" />
          </div>
        </card>

      </div>
    </template>

    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-auto">Cancelar</button>
      <v-button :run="salvar" class="btn-outline-primary">Salvar</v-button>
    </template>

  </modal>

</template>

<script>

  import TipoServicoBusiness from "@/business/crud/TipoServicoBusiness.js";
  import FrontBusiness from "@/business/FrontBusiness.js";
  import NotificacaoBusiness from "@/business/notificacao/NotificacaoBusiness.js";
  import Utils from "@/commons/Utils.js";
  import Modal from "@/components/Modal.vue";
  import Card from "@/components/Layout/components/Card.vue";

  export default {

    name: "DetalhesNotificacao",
    components: {
      Modal, Card
    },

    props: {
      anterior: Object,
      id: String,
      protocolo: String,
      targetBlank: Boolean
    },

    modal:{
      width: 800,
      escapable: false
    },

    async mounted(){
      this.loading = true;
      if(this.id){
        this.notificacao = await NotificacaoBusiness.getById(this.id);
        this.tipoServico = this.notificacao.tipoServico;
      } else {
        this.notificacao = {
          envolvidos: [],
          indices: {},
          tipoServico: {},
          texto: '',
          protocolos: []
        };
      }
      let idsTipoServico = [...new Set([this.notificacao?.tipoServico?.id])].filter(el => el != null);
      await TipoServicoBusiness.getByDominioSimplesIds('NOTIFICACAO', idsTipoServico).then(l => this.listas.tiposServico = l);
      this.notificacao.tipoServico = this.notificacao.tipoServico || {};
      this.loading = false;
    },

    computed:{

      titulo(){
        return this.id ? 'Editar Notificação' : 'Cadastrar Notificação';
      },

    },

    data() {
      return {
        loading : false,
        notificacao : null,
        tiposServico : [],
        listas: {
          tiposServico : []
        }
      }
    },

    methods:{

      close : FrontBusiness.closeModal,

      showError(e) {
        FrontBusiness.fullErrorHandler(this, e, '#formulario-notificacao');
      },

      async salvar() {

        if(!this.notificacao.tipoServico?.id){
          return null;
        }

        let dto = Utils.clone(this.notificacao);
        if(this.protocolo) dto.protocolos.push(this.protocolo);
        dto.protocolos = dto.protocolos?.filter(e => e).map(e => e.id);

        let retorno = await NotificacaoBusiness.save(dto, this.notificacao.id).catch(this.showError);
        if(retorno){
          await NotificacaoBusiness.regerarChecklist(retorno).catch(console.error);
          FrontBusiness.redirect(`notificacao/${retorno}/geral`, this.targetBlank);
          this.close();
        }

      },

    }

  }
</script>
<style>
  #detalhes-notificacao .modal-body{
    overflow: unset;
  }
</style>
