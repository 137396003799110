import FrontBusiness from "@/business/FrontBusiness";
import textoFormatado from "@/business/livros/copiladores/textoFormatado.js";
import Http from '@/commons/Http'
import moment from "moment";
import CachedApi from "@/commons/CachedApi";

const path = 'api/relatorio';

const erro = (e) => {
  FrontBusiness.showError('', e == 'TypeError: Failed to fetch' ? 'Serviço indisponível' : e);
  return Promise.reject(e);
};

const limparHtml = (html) => {
  html = html.replace(/(\r\n|\n|\r)/gm, "");
  html = html.replace(/(^\s*)|(\s*$)/gi, "");
  html = html.replace(/[ ]{2,}/gi, " ");
  html = html.replace(/\n /, "\n");
  html = html.replaceAll('&', '&amp;');
  return html;
};

export default {
  async getRelatorios() {
    return CachedApi.getCached('relatorios', () => Http.get(`${path}/agrupados`));
  },
  async getRelatoriosAll() {
    return CachedApi.getCached('relatorios-all', () => Http.get(`${path}`));
  },

  validarPeriodo(inicioStr, fimStr){

    let validado = true;

    try{
      if(!inicioStr || !fimStr){
        validado = false;
      }

      let inicio = moment(inicioStr);
      let fim = moment(fimStr);

      if(!inicio?.isValid() || !fim?.isValid()){
        validado = false;
      }

      if(!inicio?.isBefore(fim) && inicioStr != fimStr){
        validado = false;
      }
    }catch (e){
      validado = false;
    }

    if(!validado){
      FrontBusiness.showError('', 'Período inválido');
    }

    return validado;
  },

  async downloadExcel(url, name = 'relatorio', method = 'GET', data = null){

    const config = {
      responseType: 'blob',
      timeout : 0,
      headers: {'Content-Type': 'application/json'}
    };

    let promise;
    if(method == 'POST'){
      promise = Http.post(url, data, config);
    }else{
      promise = Http.get(url, config);
    }

    let pdf = await promise.then(response => new Blob([response], {type: "application/xls"}))
      .catch(e => {
        FrontBusiness.showError('', 'Erro na geração do Relatório', e?.message);
        return Promise.reject(e);
      });

    FrontBusiness.downloadFile(pdf, name);

  },

  async downloadPdf(url, name = 'impressao', method = 'GET', data = null){

    const config = {
      responseType: 'blob',
      timeout : 0,
      headers: {'Content-Type': 'application/json'}
    };

    let promise;
    if(method == 'POST'){
      promise = Http.post(url, data, config);
    }else{
      promise = Http.get(url, config);
    }

    let pdf = await promise.then(response => new Blob([response], {type: "application/pdf"}))
    .catch(e => {
      FrontBusiness.showError('', 'Erro na geração do PDF', e?.message);
      return Promise.reject(e);
    });

    FrontBusiness.downloadFile(pdf, name);

  },

  async visualizarPdf(url, name = 'impressao', method = 'GET', data = null, download= false){

    const config = {
      responseType: 'blob',
      timeout : 0,
      headers: {'Content-Type': 'application/json'}
    };

    let promise;
    if(method == 'POST'){
      promise = Http.post(url, data, config);
    }else{
      promise = Http.get(url, config);
    }

    let pdf = await promise.then(response => new Blob([response], {type: "application/pdf"}))
      .catch(e => {
        e.text().then(text => {
          const res = JSON.parse(text);
          FrontBusiness.showError('', (res) ? res.message : 'Erro na geração do PDF' , e?.message);
        });
        return Promise.reject(e);
      });

    if(download){
      FrontBusiness.downloadFile(pdf,name);
    }else{
      FrontBusiness.abrirJanela(pdf);
    }

  },

  async downloadTxt(url, name = 'impressao', method = 'GET', data = null, download= false){

    const config = {
      responseType: 'blob',
      timeout : 0,
      headers: {'Content-Type': 'application/json'}
    };

    let promise;
    if(method == 'POST'){
      promise = Http.post(url, data, config);
    }else{
      promise = Http.get(url, config);
    }

    let arquivo = await promise.then(response => new Blob([response], {type: "text/plain"}))
      .catch(e => {
        e.text().then(text => {
          const res = JSON.parse(text);
          FrontBusiness.showError('', (res) ? res.message : 'Erro na geração do TXT' , e?.message);
        });
        return Promise.reject(e);
      });
    FrontBusiness.downloadFile(arquivo,name);
  },

  async downloadArquivo(url, name = 'impressao',tipo){

    const config = {
      responseType: 'blob',
      timeout : 0,
      headers: {'Content-Type': 'application/json'}
    };

    let promise = Http.get(url, config);


    let arquivo = await promise.then(response => new Blob([response], {type: tipo}))
      .catch(e => {
        e.text().then(text => {
          const res = JSON.parse(text);
          FrontBusiness.showError('', (res) ? res.message : 'Erro na geração do arquivo! ' , e?.message);
        });
        return Promise.reject(e);
      });
    FrontBusiness.downloadFile(arquivo,name);
  },


  async forceDownload(html, name = 'impressao', java = false, fileName = undefined){
    let pdf = await this.gerarPdf(html, fileName || name);
    FrontBusiness.downloadFile(pdf, name);
  },

  async download(html, name = 'impressao'){
    let pdf = await this.gerarPdf(html, name);
    FrontBusiness.abrirJanela(pdf);
  },

  async gerarPdf(html, name = 'impressao'){

    let contentType = 'text/plain';
    let url = '';

    if(Array.isArray(html)){
      contentType = 'application/json';
      url = '/merged';
      html = html.map(h => limparHtml(textoFormatado(h)));
    }else{
      html = limparHtml(textoFormatado(html));
    }

    return Http.post(`${path}/pdf${url}?filename=${name}.pdf`, html, {
        responseType: 'blob',
        timeout : 0,
        headers: {'Content-Type': contentType}
      }).then(response => {
        return new Blob([response], {type: "application/pdf"});
      })
      .catch(e => {
        // const blob = new Blob([e], {type: "application/json"});
        // console.debug(blob);
        FrontBusiness.showError('', 'Erro na geração do PDF', e?.message);
        return Promise.reject(e);
      });

  },

  async livroEstrangeiro(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/livro-estrangeiro/pagination?sort=${sort || 'a.registro'},${direction || 'asc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async livroIncra(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/incra/pagination?sort=${sort || 'a.registro'},${direction || 'asc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async livroProtocolo(inicio, fim){
    return Http.get(`${path}/livro-protocolo/${inicio}/${fim}`, {timeout : 0});
  },

  async livroDepositoPrevio(inicio, fim){
    return Http.get(`${path}/livro-deposito-previo/${inicio}/${fim}`, {timeout : 0});
  },

  async enviarSinter(inicio, fim){
    return Http.get(`api/sinter/enviar/${inicio}/${fim}`, {timeout : 0});
  },

  async exportarSinter(inicio, fim) {
    return Http.get(`api/sinter/exportar/${inicio}/${fim}`, {timeout : 0});
  },

  async ultimaImpressao(tipo){
    return Http.get(`${path}/memory/${tipo}`).catch(e => ({}));
  },

  async ultimaImpressaoFicha(livro, id){
    let tipoLivro = livro === 'REGISTRO_AUXILIAR' ? 'registro-auxiliar': livro === 'LIVRO_ESTRANGEIRO' ? 'livro-estrangeiro':'indicador-real'
    return Http.get(`${path}/memory/ficha/${tipoLivro}/${id}`).catch(e => ({}));
  },

  async consultaDoiV2(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/v2/pagination?sort=${sort || 'p.codigo'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },
}
