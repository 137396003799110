<template>
  <card titulo="Histórico">

    <auditoria-pagination :referencia="pessoa.indicadorPessoalId" entidade="INDICADOR_PESSOAL_VERSAO" :formatters="formatters" />

  </card>
</template>

<script>
  import Card from "@/components/Layout/components/Card.vue";
  import AuditoriaBusiness from "@/business/AuditoriaBusiness";
  import AuditoriaPagination from "@/v2/components/Auditoria/AuditoriaPagination.vue";
  import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness";
  import EnderecoBusiness from "@/business/EnderecoBusiness";

  export default {

    name: "Histórico",
    components: {Card, AuditoriaPagination},
    props: {
      pessoa: Object
    },

    data() {
      return {
        historico : [],
        loading: true,
        disable: false,
        page: 1,
        pageSize: 10
      }
    },

    methods:{

      async formatters(auditoria) {
        let formatters = [];

        if(['ANEXO_INDICADOR_PESSOAL', 'ANEXO_DIGITALIZACAO_INDICADOR_PESSOAL'].includes(auditoria.entidade)){
          formatters = [
            {label: 'Ordem', key: 'ordem', type: 'string'},
            {label: 'Nome', key: 'nomeAnexo', type: 'string'},
            {label: 'Caminho', key: 'caminho', type: 'string'},
            {label: 'Tags', key: 'tags', type: 'listahtml'},
            {label: 'Caminho Assinado', key: 'caminhoAssinado', type: 'string'},
          ];
        } else {
          formatters = [
            {label: 'Versão', key: 'versao'},
            {label: 'Nome', key: 'nome'},
            {label: 'Tipo de pessoa', key: 'tipoPessoa', type: 'enum', lista: IndicadorPessoalBusiness.getTiposPessoa()},
            {label: 'CPF/CNPJ desconhecido?', key: 'documentoDesconhecido', type: 'boolean'},
            // TODO: Ajustar para $root.enums no futuro
            {label: 'Motivo do CPF/CNPJ desconhecido', key: 'motivoDocumentoDesconhecido', type: 'enum', lista: [{id: 'DECISAO_JUDICIAL', nome: 'Sem CPF/CNPJ - Decisão Judicial'}, {id: 'NAO_CONSTA', nome: 'Não consta no documento'}]},
            {label: 'CPF/CNPJ', key: 'documento'},
          ]

          if(auditoria.tipoPessoaAntes === 'FISICA' || auditoria.tipoPessoaDepois === 'FISICA') {
            formatters.push(
              {label: 'RG', key: 'rg'},
              {label: 'CNH', key: 'cnh'},
              {label: 'Identidade profissional', key: 'cip'},
              {label: 'Passaporte', key: 'passaporte'},
              {label: 'Outros Documentos', key: 'outrosDocumentos'},
              {label: 'Pessoa falecida?', key: 'falecido', type: 'boolean'},
              {label: 'Menor de idade?', key: 'menor', type: 'boolean'},
              {label: 'Incapaz?', key: 'incapaz', type: 'boolean'},
              {label: 'Pessoa exposta politicamente?', key: 'expostaPoliticamente', type: 'boolean'},
              {label: 'Servidor Público?', key: 'servidorPublico', type: 'boolean'},
            )
          } else {
            formatters.push(
                {label: 'Nome Fantasia (Denominação)', key: 'denominacao'},
              )
          }

          formatters.push(
            {label: 'Pessoa Obrigada?', key: 'pessoaObrigada', type: 'boolean'},
            {label: 'Permitir uso como fornecedor?', key: 'fornecedor', type: 'boolean'},
            {label: 'Optante de Substituição Tributária?', key: 'substituicaoTributaria', type: 'boolean'},
            {label: 'Sexo/Gênero no Texto', key: 'sexo', type: 'enum', lista: IndicadorPessoalBusiness.getSexos(), group: 'Dados Básicos'},
          )

          if(auditoria.tipoPessoaAntes === 'FISICA' || auditoria.tipoPessoaDepois === 'FISICA'){
            formatters.push(
              {label: 'Data de nascimento', key: 'nascimento', type: 'date', group: 'Dados Básicos'},
              {label: 'Nacionalidade', key: 'nacionalidade', type: 'enum', lista: IndicadorPessoalBusiness.getNacionalidades(), group: 'Dados Básicos'},
              {label: 'Profissão', key: 'profissao', group: 'Dados Básicos'},
              {label: 'Nome do pai', key: 'nomePai', group: 'Dados Básicos'},
              {label: 'Nome da mãe', key: 'nomeMae', group: 'Dados Básicos'},
              {label: 'Estado civil', key: 'estadoCivil', type: 'enum', lista: IndicadorPessoalBusiness.getEstadosCivil(), group: 'Dados Básicos'},
              {label: 'União estável?', key: 'possuiUniaoEstavel', type: 'boolean', group: 'Dados Básicos', alt: 'Não Informado'},
              {label: 'Cônjuge', key: 'conjugeId', formatter: async (value) => AuditoriaBusiness.getEntityById(value, IndicadorPessoalBusiness), group: 'Dados do casamento/união estável'},
              {label: 'Após lei 6515?', key: 'casamentoAposLei6515', type: 'boolean', group: 'Dados do casamento/união estável'},
              {label: 'Data do casamento/união', key: 'dataCasamento', type: 'date', group: 'Dados do casamento/união estável'},
              {label: 'Regime', key: 'regimeCasamento', type: 'enum', lista: IndicadorPessoalBusiness.getRegimes(), group: 'Dados do casamento/união estável'},
              {label: 'Detalhes de regime', key: 'detalhesRegime', group: 'Dados do casamento/união estável'},
              {label: 'Adicionar dados de registro do pacto antenupcial?', key: 'adicionarRegistroPactoAntenupcial', type: 'boolean', group: 'Dados do casamento/união estável'},
              {label: 'Número do registro', key: 'numeroRegistroPactoAntenupcial', group: 'Dados do casamento/união estável'},
              {label: 'Registro de Imóveis', key: 'registroImoveisPactoAntenupcial', group: 'Dados do casamento/união estável'}
            )
          }

          formatters.push(
            {label: 'Banco', key: 'banco', group: 'Dados Bancários'},
            {label: 'Agência', key: 'agencia', group: 'Dados Bancários'},
            {label: 'Conta', key: 'conta', group: 'Dados Bancários'},
          )

          if(auditoria.tipoPessoaAntes !== 'FISICA' || auditoria.tipoPessoaDepois !== 'FISICA'){
            formatters.push(
              {label: 'Inscrição estadual', key: 'inscricaoEstadual', group: 'Dados básicos'},
              {label: 'Inscrição municipal', key: 'inscricaoMunicipal', group: 'Dados básicos'},
              {label: 'Participação estrangeira?', key: 'possuiParticipacaoEstrangeira', type: 'boolean', group: 'Dados básicos'},
              {label: 'Natureza Jurídica', key: 'naturezaJuridica', type: 'enum', lista: IndicadorPessoalBusiness.getNaturezaJuridica(), group: 'Dados básicos'},
              {label: 'Descrição', key: 'naturezaJuridicaOutras', group: 'Dados básicos'},
            )
          }

          formatters.push(
            {label: 'E-mail', key: 'email', group: 'Contato'},
            {label: 'Telefone', key: 'telefone', group: 'Contato'},
            {label: 'Celular', key: 'celular', group: 'Contato'},
            {label: 'Comercial', key: 'comercial', group: 'Contato'},
            {label: 'CEP', key: 'cep', group: 'Endereço'},
            {label: 'Logradouro (rua)', key: 'logradouro', group: 'Endereço'},
            {label: 'Número', key: 'numero', group: 'Endereço'},
            {label: 'Quadra', key: 'quadra', group: 'Endereço'},
            {label: 'Lote', key: 'lote', group: 'Endereço'},
            {label: 'Bairro (setor)', key: 'bairro', group: 'Endereço'},
            {label: 'Cidade', key: 'cidade', group: 'Endereço'},
            {label: 'País', key: 'pais', type: 'enum', lista: IndicadorPessoalBusiness.getNacionalidades(), nomeLista: 'pais', group: 'Endereço'},
            {label: 'Estado', key: 'estado', type: 'enum', lista: EnderecoBusiness.getEstados(), group: 'Endereço'},
            {label: 'Complemento', key: 'complemento', group: 'Endereço'}
            );

          if(auditoria.tipoPessoaAntes === 'FISICA' || auditoria.tipoPessoaDepois === 'FISICA'){
            formatters.push(
              {label: 'CEP', key: 'cepComercial', group: 'Endereço comercial'},
              {label: 'Logradouro (rua)', key: 'logradouroComercial', group: 'Endereço comercial'},
              {label: 'Número', key: 'numeroComercial', group: 'Endereço comercial'},
              {label: 'Quadra', key: 'quadraComercial', group: 'Endereço comercial'},
              {label: 'Lote', key: 'loteComercial', group: 'Endereço comercial'},
              {label: 'Bairro (setor)', key: 'bairroComercial', group: 'Endereço comercial'},
              {label: 'Cidade', key: 'cidadeComercial', group: 'Endereço comercial'},
              {label: 'Estado', key: 'estadoComercial', type: 'enum', lista: EnderecoBusiness.getEstados(), group: 'Endereço comercial'},
              {label: 'País', key: 'paisComercial', type: 'enum', lista: IndicadorPessoalBusiness.getNacionalidades(), nomeLista: 'pais', group: 'Endereço comercial'},
              {label: 'Complemento', key: 'complementoComercial', group: 'Endereço comercial'}
              );
          }
          formatters.push({label: 'Observações', key: 'observacao'});
          formatters.push({label: 'Sequencial', key: 'sequencial'});
        }

        return formatters;
      }

    }
  }
</script>
