<template>
  <modal id="form-indicador-real" :titulo="titulo" :loading="!form">
    <template #actions>
      <li class="list-inline-item" v-if="!id && livro !== 'TRANSCRICAO'">
        <a @click.prevent="importarIndicador" title="Importar dados" href>
          <i class="fas fa-file-import"></i>
        </a>
      </li>
    </template>
    <form @submit.prevent="" v-if="form" autocomplete="off">

      <card>

        <card-content class="row gutters-xs">

          <div class="col-auto pr-4">

            <v-input :error="validation.categoria" v-model="form.categoria" :options="options.categorias"
                     type="radio" class="mb-2" @change="updateMedidaAreaDoi" />

            <div class="w-100"></div>

            <div class="form-group mb-0">
              <input style="position: relative; top: 3px; left: 0px;" type="checkbox" id="FLAG_UNIDADE_AUTONOMA"
                     v-model="form.unidadeAutonoma" />
              <label for="FLAG_UNIDADE_AUTONOMA" style="position: relative; top: 0px; left:4px;" class="pointer">
                Unidade Autônoma?
              </label>
            </div>

          </div>

          <v-input :error="validation.tipoImovel" v-model="form.tipoImovel" :options="options.tipos"
                   type="select" class="col" label="Tipo de imóvel" />

          <v-input class="col" v-model="form.descricaoTipoImovelOutros" v-if="form.tipoImovel == 'OUTROS'"
                   type="text" label="Descrição do Tipo de Imóvel" />


          <div class="w-100"></div>

          <v-input class="col" v-model="form.livroTranscricao" :options="options.livrosTranscricao" type="select" label="Livro - Transcrição" v-if="livro == 'TRANSCRICAO'" />

          <v-input class="col-2" label="Último Ato" v-model="form.ultimoAto" v-if="gestaoCompleta"
                   type="currency" :input-props="{precision : 0}" />

          <v-input v-if="form.id && form.status !== 'PENDENTE'" :error="validation.status" v-model="form.status" :options="options.statusEdicao"
                   type="select" class="col-2" label="Situação" />

          <template v-if="((livro !== 'TRANSCRICAO' && form.id) || (livro == 'TRANSCRICAO')) && form.status !== 'PENDENTE'">

          <v-input type="datetime-local" class="col-4" label="Abertura"
                   v-model="form.abertura" :error="validation.abertura"/>

          <v-input v-if="form.status !== 'ABERTA'" type="datetime-local" class="col-4" :label="form.status === 'CANCELADA' ? 'Cancelamento' : 'Encerramento'"
                   v-model="form.encerramento" :error="validation.encerramento"/>

          <div class="w-100"></div>
          </template>
          <RegistroAnteriorLista :registros="form.origens" @update="form.origens = $event" :isImportarRegistroAnterior="livro !== 'TRANSCRICAO'" @importarDados="importarDados" />

          <div class="w-100"></div>

          <v-input :default="false" :error="validation.imovelUniao" v-model="form.imovelUniao" label="Imóvel da União" type="radio" class="col-4" :required="true" />
          <v-input :default="false" :error="validation.marinha"     v-model="form.marinha" label="Imóvel da Marinha" type="radio" class="col-4" :required="true" />
          <v-input :default="false" :error="validation.fronteira"   v-model="form.fronteira" label="Imóvel de Fronteira" type="radio" class="col-4" :required="true" />
          <div class="w-100"></div>

        </card-content>

      </card>

      <referencia-imovel-lista :ficha="form" />

      <card titulo="Imóvel da União" v-if="form.imovelUniao">
        <card-content class="row gutters-xs">
          <v-input class="col mb-3" v-model="form.numeroRipSpu" type="mask" :masks="['#### #######-##']" label="Número RIP SPU" />
          <v-input class="col" v-model="form.regime" :options="options.regimes" type="select" label="Regime" />

          <div class="w-100"/>
          <card titulo="Área Terreno da União" class="w-100">
            <card-content class="row gutters-xs">
              <v-input class="col" v-model="form.medidaAreaUniao" :options="options.medidasUniao" type="select" label="Medida área" />
              <v-input class="col" label="Área Terreno da União" v-model="form.areaUniao" type="currency" :input-props="{precision : decimais}" />
            </card-content>
          </card>
        </card-content>
      </card>

      <card titulo="Transcrição" v-if="livro == 'TRANSCRICAO'">
        <card-content class="row gutters-xs">
          <v-input class="col" :disabled="form.id" :error="validation['codigo, livro']" v-model="form.codigo"
                   type="currency" :input-props="{precision : 0}" label="Registro" />
          <v-input class="col" v-model="form.livroNumero" type="text" label="Livro (Número)" />
          <v-input class="col" v-model="form.livroLetra" type="text" label="Livro (Letra)" />
          <v-input class="col" v-model="form.livroFolha" type="text" label="Folha" />

          <v-input :error="validation.status" v-model="form.status" :options="options.status"
                   type="select" class="col" label="Situação" v-if="!form.id" />
        </card-content>
      </card>

      <card titulo="Identificação">
        <card-content class="row gutters-xs">
          <v-input class="col" v-if="livro !== 'TRANSCRICAO' && enableLivroLetra" v-model="form.livroLetra" type="text" label="Livro (Letra)" />

          <template v-if="form.unidadeAutonoma">

            <v-input class="col" v-model="form.unidade" type="text" label="Número da unidade" />
            <v-input class="col" v-model="form.bloco" type="text" label="Bloco" />

            <div class="w-100"></div>
          </template>

          <v-input class="col-3" v-model="form.quadra" type="text" label="Quadra" />
          <v-input class="col-3" v-model="form.lote" type="text" label="Lote" />

          <v-input class="col" label="Localização" v-model="form.localizacao" type="autocomplete" :search-query="sugerirLocalizacao"  />

          <div class="w-100"></div>
          <v-input class="col" v-model="form.empreendimento" type="autocomplete" :search-query="sugerirEmpreendimento"  label="Empreendimento/Condomínio" />

        </card-content>
      </card>

      <card titulo="Informações Complementares">
        <card-content class="row gutters-xs">

          <template v-if="form.unidadeAutonoma">
            <v-input class="col" v-model="form.andar" type="text" label="Andar" />
            <v-input class="col" v-model="form.pavimento" type="text" label="Pavimento" />
            <v-input class="col" v-model="form.grupo" type="text" label="Grupo (Tipo)" />
            <div class="w-100"></div>
          </template>

          <template v-if="form.categoria == 'RURAL'">
            <v-input class="col" v-model="form.denominacao" type="text" label="Denominação da Área Rural" />
            <div class="w-100"></div>
            <v-input class="col" v-model="form.incra" type="text" label="INCRA (CCIR)" />
            <v-input class="col" v-model="form.itr" type="text" label="ITR" />
            <v-input class="col" v-model="form.car" type="text" label="CAR" />
            <v-input class="col" v-model="form.certificacaoIncra" type="text" label="Certificação no SIGEF" />
          </template>
          <div class="w-100"></div>

          <v-input class="col mb-3" v-model="form.cadastroImobiliarioBrasileiro" type="text" label="Cadastro Imobiliário Brasileiro (CIB)" :inputProps="{ 'maxlength': 8}" />
          <v-input class="col mb-3" v-model="form.cadastroImobiliario" type="text" label="Cadastro imobiliário" />
          <v-input class="col mb-3" v-model="form.cadastroFiscal" type="text" label="Cadastro fiscal" />

        </card-content>
      </card>

      <card titulo="Endereço">
        <card-content class="row gutters-xs">

          <v-input-cep :validation="validation.cep" v-model="form.cep" @endereco="atualizarEndereco" />

          <v-input class="col-3" v-model="form.tipoLogradouro" type="text" label="Tipo logradouro" />
          <v-input class="col" v-model="form.logradouro" type="autocomplete" :search-query="sugerirLogradouro" label="Logradouro (rua)" />
          <v-input class="col-2" v-model="form.numero" type="text" label="Número" />

          <div class="w-100"></div>

          <div class="col-12" v-if="!possuiListaCidadesConfigurada">
            <div class="alert alert-outline-warning alert-sm mb-2">
              <p class="mb-0">
                Necessário configurar a lista de cidades permitidas para o indicador real
              </p>
            </div>
          </div>

          <v-input class="col"
                   :error="validation.bairro"
                   v-model="form.bairro"
                   label="Bairro (setor)"
                   type="autocomplete" :search-query="sugerirBairro" />

          <v-input class="col"
                   label="Cidade"
                   v-model="form.cidade"
                   :options="options.cidades"
                   type="v-select"
                   :error="validation.cidade"/>
          <!--          <v-input class="col" v-model="form.cidadeIbge" type="number" label="Cidade (IBGE)" />-->
          <v-input class="col-auto" v-model="form.estado" :options="options.estados" type="select" label="Estado" />

          <div class="w-100"></div>

          <v-input class="col mb-3" v-model="form.complemento" type="text" label="Complemento" />

        </card-content>
      </card>

      <card titulo="Área">
        <card-content class="row gutters-xs">

          <v-input class="col" v-model="form.medidaArea" :options="options.medidas" type="select" label="Medida área" @change="updateMedidaAreaDoi" />
          <v-input class="col" label="Área total" v-model="form.area" type="currency" :input-props="{precision : decimais}" />

          <template v-if="(form.medidaArea !== 'M2' && form.categoria === 'URBANO') || (form.medidaArea !== 'HA' && form.categoria === 'RURAL') ">
            <v-input class="col" v-model="form.medidaAreaDoi" :options="options.medidas" type="select" label="Medida área DOI" :disabled="true"/>
            <v-input class="col" label="Área total DOI" v-model="form.areaTotalDoi" type="currency" :input-props="{precision : decimais}" />
          </template>

          <template v-if="form.unidadeAutonoma">

            <div class="w-100"></div>

            <v-input class="col" :label="unidadeArea" v-model="form.fracaoSolo" type="currency" :input-props="{precision : decimais}" />
            <v-input class="col" label="Fração solo (%)" v-model="form.fracaoSoloPercentual" type="currency" :input-props="{precision : decimais}" />
            <div class="w-100"></div>
            <v-input class="col" label="Coeficiente de proporcionalidade" v-model="form.coeficienteProporcionalidade" type="currency" :input-props="{currencyDisplay: 'hidden', precision : {min : 0, max : 10}}" />

          </template>

          <template v-if="form.unidadeAutonoma || (form.areas && form.areas.length)">

            <div class="w-100"></div>

            <div class="col mb-3">
              <detalhar-areas-indicador-real :ordenar="true" @ordenar="ordenar" tipo="areas" titulo="Detalhamento das áreas" :areas="form.areas" />
            </div>

          </template>

        </card-content>
      </card>

      <card titulo="Áreas edificadas">
        <card-content class="row gutters-xs">
          <detalhar-areas-indicador-real :ordenar="true" tipo="areasEdificadas" titulo="Áreas edificadas" :possui-tipo="false" :areas="form.areasEdificadas" @ordenar="ordenar" />
        </card-content>
      </card>

      <card titulo="Unidades acessórias">
        <card-content class="row gutters-xs">
          <detalhar-areas-indicador-real :ordenar="true" tipo="unidadesAcessorias" titulo="Unidade acessória" :possui-tipo="false" :areas="form.unidadesAcessorias" @ordenar="ordenar" />
        </card-content>
      </card>

      <card titulo="Confrontações">
        <card-content class="row gutters-xs">
          <confrontacao-indicador-real :ordenar="true" :confrontacoes="form.confrontacoes" @ordenar="ordenar" />
        </card-content>
      </card>
      <proprietarios :proprietarios="form.proprietarios" @setProprietario="form.proprietarios = $event" :ficha="form"
                     v-if="gestaoCompleta"></proprietarios>


      <card titulo="Especificações do imóvel">

        <template #acoes v-if="existModeloEspecificaoImovel">
          <v-button :button="false" :run="utilizarModelo" class="icon float-right pointer mr-3" :popover="true">
            UTILIZAR MODELO
          </v-button>
        </template>

        <card-content>
          <v-input type="editor" v-model="form.especificacao" />
        </card-content>
      </card>

      <card titulo="Observações para Certidão">
        <card-content>
          <v-input type="editor" v-model="form.certidao" />
        </card-content>
      </card>

      <card titulo="Observações Internas">
        <card-content>
          <v-input type="editor" v-model="form.observacao" />
        </card-content>
      </card>

      <card titulo="Registros Auxiliares Relacionados">
        <card-table>

          <thead>
            <tr>
              <th>Registro Auxiliar</th>
              <th width="30px"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(ficha, i) in form.registroAuxiliar" :key="ficha.id">
              <td class="pointer icon" @click="detalhesFicha(ficha.id)">
                <a>{{nomearLivro(ficha)}}</a>
              </td>
              <td>
                <a @click.prevent="remover(i)" class="icon"><i class="fa fa-times"></i></a>
              </td>
            </tr>
            <tr>
              <td colspan="100%">
                <div class="row">
                  <v-input class="col-auto pr-0" v-model="buscar" :options="options.livros" type="select" />
                  <buscar-fichas :livro="livroSelecionado" :status="statusSelecionado" ref="buscarFichas" @selecionar="selecionar($event)" class="pl-2 col"/>
                </div>
              </td>
            </tr>
          </tbody>

        </card-table>
      </card>

    </form>

    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-auto">Cancelar</button>
      <v-button :run="salvar" class="btn-outline-primary">Salvar</v-button>
    </template>

  </modal>
</template>
<script>

  import EnderecoBusiness from "@/business/EnderecoBusiness";
  import FrontBusiness from "@/business/FrontBusiness";
  import IndicadorRealBusiness from '@/business/indicadores/IndicadorRealBusiness';
  import TextParser from "@/business/livros/TextParser.js";
  import utils from "@/commons/Utils";
  import ConsultarIndicadorPessoal from "@/components/IndicadorPessoal/ConsultarIndicadorPessoal.vue";
  import DetalhesIndicadorPessoal from "@/components/IndicadorPessoal/Detalhes/DetalhesIndicadorPessoal.vue";
  import FormIndicadorPessoal from "@/components/IndicadorPessoal/Form/FormIndicadorPessoal";
  import DetalharAreasIndicadorReal from "@/components/IndicadorReal/Form/DetalharAreasIndicadorReal";
  import ConfrontacaoIndicadorReal from "@/components/IndicadorReal/Form/ConfrontacaoIndicadorReal";
  import ImportarIndicadorReal from "@/components/IndicadorReal/Form/ImportarIndicadorReal";
  import Proprietarios from "@/components/IndicadorReal/Form/Proprietarios";
  import ReferenciaImovelLista from "@/components/IndicadorReal/ReferenciaImovelLista.vue";
  import Card from "@/components/Layout/components/Card.vue";
  import CardContent from "@/components/Layout/components/CardContent.vue";
  import CardTable from "@/components/Layout/components/CardTable.vue";
  import Modal from "@/components/Modal";
  import Utils from "@/commons/Utils";
  import DetalhesRegistroAuxiliar from "@/components/RegistroAuxiliar/Detalhes/DetalhesRegistroAuxiliar.vue";
  import ListaBusiness from "@/business/crud/ListaBusiness";
  import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness";

  export default {
    name: "FormIndicadorReal",
    components: {
      ReferenciaImovelLista,
      Card,
      CardTable,
      CardContent,
      DetalharAreasIndicadorReal,
      ConfrontacaoIndicadorReal,
      Modal,
      Proprietarios,
      RegistroAnteriorLista: () => import('@/components/IndicadorReal/RegistroAnteriorLista.vue')
    },

    modal: {
      escapable: false,
      width: 1000,
    },

    props: {
      id: String,
      livro: String,
      origem: String,
      origemExterna: String,
      clone: {type: Object, default: () => ({})}
    },

    async mounted() {

      let indicador = {};

      if (this.id) {
        indicador = await IndicadorRealBusiness.getById(this.id);
        indicador.registroAuxiliar = await IndicadorRealBusiness.getVinculos(this.id);

        indicador.especificacao = indicador.especificacao || '';
        this.titulo = `Editar indicador real: ${this.nomearLivro(indicador)}`;
      } else {
        this.titulo = 'Adicionar ' + this.nomeLivro;
        indicador = {...this.clone};
      }

      let cidades = this.config?.listaCidade?.id ? await ListaBusiness.getById(this.config?.listaCidade?.id).catch(e => ({lista: []})) : {lista: []};

      if(indicador.cidade && !cidades?.lista?.includes(indicador.cidade)){
        cidades.lista.push(indicador.cidade);
      }

      this.$set(this.options, 'cidades', (cidades || {lista: []})?.lista.map(e => ({
        id: e,
        nome: e
      })));

      let form = {
        unidadeAutonoma: false,
        origens: [],
        proprietarios: [],
        confrontacoes: [],
        tipo: 'PRE_MATRICULA',
        categoria: 'URBANO',
        tipoImovel: 'CASA',
        imovelUniao: false,
        marinha: false,
        fronteira: false,
        areas: [], areasEdificadas: [], ...this.indicador, ...indicador
      };

      form.abertura = indicador.abertura ? this.$moment(indicador.abertura).format('YYYY-MM-DDTHH:mm:ss') : null;
      form.encerramento = indicador.encerramento ? this.$moment(indicador.encerramento).format('YYYY-MM-DDTHH:mm:ss') : null;

      if (this.livro === 'TRANSCRICAO') form.status = form.status || 'ABERTA';

      form.unidadeAutonoma = !!form.unidadeAutonoma;
      form.areas = [...(form.areas || [])];
      form.areasEdificadas = [...(form.areasEdificadas || [])];
      form.unidadesAcessorias = [...(form.unidadesAcessorias || [])];
      form.confrontacoes = [...(form.confrontacoes || [])];

      if(indicador.estado === null || indicador.cidade === null || !this.id){
        form.estado = this.config?.estado;
        form.cidade = this.config?.cidade;
      } else {
        form.estado = form.estado;
        form.cidade = form.cidade;
      }

      this.$set(this, 'form', form);

      this.ordenarProprietarios();
    },

    data() {
      return {
        form: null,
        validation: {},
        titulo: '',
        decimais: {min : 2, max : 10},
        buscar : 'REGISTRO_AUXILIAR',
        options: {
          medidas: IndicadorRealBusiness.getMedidas(),
          medidasUniao: IndicadorRealBusiness.getMedidasUniao(),
          tipos: IndicadorRealBusiness.getTiposImovel(),
          statusEdicao: IndicadorRealBusiness.getStatusImovel(false),
          categorias: IndicadorRealBusiness.getCategorias(),
          livrosTranscricao: IndicadorRealBusiness.getLivrosTranscricao(),
          origens: [
            {id: 'MATRICULA', nome: 'Matrícula'},
            {id: 'TRANSCRICAO', nome: 'Transcrição'}
          ],
          status: [
            {id: 'ABERTA', nome: 'Aberta'},
            {id: 'ENCERRADA', nome: 'Encerrada'}
          ],
          cidades: [],
          estados: EnderecoBusiness.getEstados(),
          regimes: IndicadorRealBusiness.getRegimesImovelUniao(),
          livros: [
            {id: 'REGISTRO_AUXILIAR', nome: 'Registro Auxiliar'},
            {id: 'PRE_REGISTRO_AUXILIAR', nome: 'Pré-Registro Auxiliar'},
          ],
        },
      }
    },

    computed: {
      possuiListaCidadesConfigurada(){
        return !!this.config?.listaCidade?.id;
      },
      config(){
        return this.$root.config;
      },
      gestaoCompleta(){
        return this.form.status && (this.form.status !== 'PENDENTE' || this.config?.indicadorReal?.permitirRegistroPreMatricula);
      },
      nomeLivro() {
        return FrontBusiness.nomearFicha({livro: this.form?.livro || this.livro, status: ''}, false);
      },
      unidadeArea() {
        return this.form.medidaArea ? 'Fração solo (' + FrontBusiness.getLabel(this.form.medidaArea, IndicadorRealBusiness.getMedidas()) + ')' : 'Fração solo';
      },
      existModeloEspecificaoImovel(){
        return this.config.outrosTemplates?.especificaoImovel;
      },
      livroSelecionado() {
        return this.buscar === 'PRE_REGISTRO_AUXILIAR' ? 'REGISTRO_AUXILIAR' : this.buscar;
      },
      statusSelecionado() {
        return this.buscar === 'PRE_REGISTRO_AUXILIAR' ? 'PENDENTE' : 'ABERTA';
      },
      enableLivroLetra(){
        return !Utils.isEmptyNullOrUndefined(this.form.livroLetra) || this.config.indicadorReal.habilitarLivroLetraMatricula && this.livro === 'MATRICULA'
      },
    },

    methods: {

      updateMedidaAreaDoi() {
        if (this.form.categoria === 'URBANO' && this.form.medidaArea !== 'M2') {
         this.form.medidaAreaDoi = 'M2';
        } else if (this.form.categoria === 'RURAL' && this.form.medidaArea !== 'HA') {
          this.form.medidaAreaDoi = 'HA';
        }
      },

      ordenar(tipo, lista) {
        this.$nextTick(() => {
          this.$set(this.form, tipo, lista);
        })
      },

      ordenarProprietarios(){
        this.form.proprietarios.sort(function (a, b) {
          return a.ordem > b.ordem ? 1 : -1;
        });
      },

      close() {
        FrontBusiness.closeModal();
      },

      showError(e) {
        if (e?.validation?.["codigo, livro"] || e?.validation?.["codigo, COALESCE(livro, ''::character varying"]) {
          FrontBusiness.showError('', 'Transcrição já existente');
        } else {
          FrontBusiness.fullErrorHandler(this, e, '#form-indicador-real');
        }
      },

      nomearLivro: FrontBusiness.nomearFicha,

      async pesquisar() {
        let pessoa = await FrontBusiness.openConsulta(ConsultarIndicadorPessoal);
        if (pessoa) {
          this.$nextTick(() => {
            this.form.proprietarios.push({indicadorPessoalVersao: pessoa, fracao: 0});
          });
        }
      },

      selecionar(itens) {
        this.$nextTick(() => {
          itens?.forEach((item) => {
            if (item && !this.form.registroAuxiliar.find(e => e.id == item.id)) {
              this.form.registroAuxiliar.push(item);
            }
          });
        });
      },

      remover(idx) {
        this.$nextTick(() => this.form.registroAuxiliar.splice(idx, 1));
      },


      async salvar(confirmadoCI = false, confirmadoQLB = false) {
        let dto = utils.clone(this.form);

          if (dto.categoria === 'URBANO' && dto.medidaArea === 'M2') {
            dto.medidaAreaDoi = null;
            dto.areaTotalDoi = null;
          } else if (dto.categoria === 'RURAL' && dto.medidaArea === 'HA') {
            dto.medidaAreaDoi = null;
            dto.areaTotalDoi = null;
          }

        dto.referencias = (dto.referencias || []).map((f) => {
          delete f.referenciaDetalhes;
          return f;
        });

        dto.registrosVinculados = (dto.registroAuxiliar || []).map((f) => {
          return {
            id: f.id
          };
        });

        if (dto.numeroRipSpu) {
          dto.numeroRipSpu = dto.numeroRipSpu.replace(/[^\d]/g, '');
        }

        dto.livro = dto.livro || 'MATRICULA';

        dto.registroAuxiliar = (dto.registroAuxiliar || []).map((f) => ({id: f.id}));

        let permiteSalvarSemProprietarios = (dto.status !== 'ABERTA')
          ? this.config?.indicadorReal?.salvarEncerradosSemProprietarios
          : this.config?.indicadorReal?.salvarSemProprietarios;

        if(!permiteSalvarSemProprietarios || dto.proprietarios.length > 0){
          dto.proprietarios = (dto.proprietarios || []).map((f, ordem) => ({
            fracao: f.fracao,
            ordem: ordem,
            indicadorPessoalVersao: {id: f.indicadorPessoalVersao.id},
            area: f.area,
            medidaArea: dto.medidaArea
          }));
        }
          let erro = await IndicadorRealBusiness.validarDadosCadastroIndicador(dto);
          FrontBusiness.showError('', erro, 'app', 5000);

        if(Utils.isEmptyNullOrUndefined(erro)) {
          let result = await this.confirmarSalvarAlteracoes(dto, confirmadoCI, confirmadoQLB);
          if (!result) return;
          if (!this.id && this.livro === 'TRANSCRICAO') {
            let valido = true;
            if ([undefined, null, ''].includes(dto.codigo)) {
              this.$set(this.validation, 'codigo, livro', 'Campo Obrigatório');
              valido = false;
            }
            if (valido) {
              (dto?.origens || []).forEach(origem => {
                if (!origem?.origemId) {
                  delete origem.origemId
                }
                origem.registro = parseInt(origem?.registro) || null;
              })

              let indicador = await IndicadorRealBusiness.criarTranscricao(dto).catch(this.showError);
              if (indicador) {
                FrontBusiness.closeModal(indicador);
              }
            }
            return;
          }

        if (!this.id && !this.origem && !this.origemExterna) {
          FrontBusiness.closeModal(this.form);
          return;
        }

        delete dto.textoLegado;

        let indicador = await IndicadorRealBusiness.save(this.id, dto, this.origem).catch(this.showError);
        if (indicador) {
          FrontBusiness.closeModal(indicador);
         }
        }
      },

        async confirmarSalvarAlteracoes(dto, confirmadoCI, confirmadoQLB){
          let retorno = await IndicadorRealBusiness.validarDadosCadastroIndicadorConfirmacao(dto, confirmadoCI, confirmadoQLB);
          if(retorno !== '') {
            return FrontBusiness.showConfirmacao(retorno, 'Deseja continuar?', 'checkbox', 'Tenho certeza que desejo continuar.').then(r => {
              if (r.isConfirmed) {
                return true;
              } else {
                return false
              }
            }).catch(this.showError)
          }
          return true;
        },


      async importarDados(origemId) {
        this.loading = true;
        let ficha = await IndicadorRealBusiness.getById(origemId);
        delete ficha.categoria;
        delete ficha.tipoImovel;
        delete ficha.origens;
        delete ficha.ultimoAto;
        delete ficha.proprietarios;
        delete ficha.status;
        delete ficha.id;
        delete ficha.cnm;
        ficha.areas = [...(ficha.areas || [])];
        ficha.areasEdificadas = [...(ficha.areasEdificadas || [])];
        ficha.unidadesAcessorias = [...(ficha.unidadesAcessorias || [])];
        ficha.confrontacoes = [...(ficha.confrontacoes || [])];
        this.$nextTick(() => {
          this.form = {...this.form, ...ficha};
          this.loading = false;
        });
      },

      async importarIndicador() {
        let retorno = await FrontBusiness.openModal(ImportarIndicadorReal);
        if (retorno) {
          let indicador = await IndicadorRealBusiness.getById(retorno?.id);
          indicador.especificacao = indicador.especificacao || '';
          indicador.areas = [...(indicador.areas || [])];
          indicador.areasEdificadas = [...(indicador.areasEdificadas || [])];
          indicador.unidadesAcessorias = [...(indicador.unidadesAcessorias || [])];
          indicador.confrontacoes = [...(indicador.confrontacoes || [])];
          delete indicador.cnm;

          let campos = retorno?.campos;
          for (let i = 0; i < campos.length; i++) {
            if (campos[i] === 'origens') {
              indicador[campos[i]].forEach(e =>{
                delete e.id;
                delete e.indicadorRealId;
              })
            }
            this.$set(this.form, campos[i], indicador[campos[i]]);
          }

        }
      },

      detalhes(pessoa, versao) {
        FrontBusiness.openModal(DetalhesIndicadorPessoal, {id: pessoa.indicadorPessoalId, versao});
      },

      detalhesFicha(id) {
        FrontBusiness.openModal(DetalhesRegistroAuxiliar, {id});
      },

      getFocusElements(campo) {
        let editor_elements = (document.getElementById('form-indicador-real'))?.querySelectorAll('input, input.form-check-input, select, textarea, a, .document-editor__editable, .add-editor')
        if (!editor_elements) return;
        if (campo == null) {
          editor_elements[0].focus();
        } else {
          for (let i = 0; i < editor_elements.length; i++) {
            let id_campo = (editor_elements[i].id).split('-')[1] == 'in' ? (editor_elements[i].id).split('-')[0] + '-' + editor_elements[i].value : (editor_elements[i].id).split('-')[0];
            if ((campo).indexOf('ENVOLVIDO') != -1) id_campo = (editor_elements[i].id).replace(/-/g, '');
            if (i + 1 < editor_elements.length && id_campo.indexOf(campo) != -1) {
              editor_elements[i].focus();
              break
            }
          }
        }
      },

      async editar(envolvido, i) {
        let retorno = await FrontBusiness.openModal(FormIndicadorPessoal, {versaoId: envolvido.indicadorPessoalVersao.id});
        if (retorno) {
          this.$nextTick(() => {
            let fracao = utils.clone(this.form.proprietarios[i].fracao || 0)
            this.form.proprietarios.splice(i, 1);
            this.form.proprietarios.splice(i, 0, {indicadorPessoalVersao: retorno, fracao: fracao});
          });
        }
      },

      atualizarEndereco(endereco){
        if (endereco) {
          this.$set(this.form, 'cep', endereco.cep);
          this.$set(this.form, 'logradouro', endereco.logradouro);
          this.$set(this.form, 'bairro', endereco.bairro);
          this.$set(this.form, 'cidade', endereco.cidade);
          this.$set(this.form, 'estado', endereco.estado);
          this.getFocusElements('Logradouro (rua)');
        }
      },

      async utilizarModelo(){
        if(this.existModeloEspecificaoImovel){
          let modelo = this.config.outrosTemplates?.especificaoImovel;
          modelo = await TextParser.gerar(modelo, {imovel: Utils.clone(this.form)});
          this.$set(this.form, 'especificacao', modelo);
        }
      },

      sugerirBairro(query) {
        return IndicadorRealBusiness.sugerirCampo('bairro', query.trim());
      },

      sugerirLogradouro(query) {
        return IndicadorRealBusiness.sugerirCampo('logradouro', query.trim());
      },

      sugerirEmpreendimento(query) {
        return IndicadorRealBusiness.sugerirCampo('empreendimento', query.trim());
      },

      sugerirLocalizacao(query) {
        return IndicadorRealBusiness.sugerirCampo('localizacao', query.trim());
      },

    }

  }
</script>

<style lang=scss>
  #form-indicador-real {
    .card {
      hr {
        margin-top: .25rem;
      }

      .card-body {
        padding-bottom: 0;
      }
    }
  }
</style>
