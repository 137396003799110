<template>
  <modal titulo="Exigências" id="exigencias-protocolo" :loading="loading">

    <template #actions>

      <template v-if="atividade && !emEdicao">

        <li class="list-inline-item">
          <v-button :run="downloadTxt" class="btn-outline-info btn-sm mr-1">
            Baixar TXT
          </v-button>
        </li>

        <li class="list-inline-item">
          <v-button :run="downloadPdf" class="btn-outline-info btn-sm mr-1">
            Baixar PDF
          </v-button>
        </li>

        <li class="list-inline-item">
          <v-button :actions="actionsAssinatura" :popover="true" position="dropleft" class="btn-outline-info btn-sm mr-1">
            Baixar PDF Assinado
          </v-button>
        </li>

        <li class="list-inline-item">
          <v-button :run="enviarEmail" :popover="true" position="dropleft" class="btn-outline-info btn-sm mr-2" label="">
            Enviar E-mail
          </v-button>
        </li>

<!--      <li class="list-inline-item" v-if="atividade" class="icon">-->
<!--        <a @click.prevent="enviarWhatsApp()" title="Enviar por WhatsApp" href>-->
<!--          <i class="fab fa-whatsapp"></i>-->
<!--        </a>-->
<!--      </li>-->

      </template>
      <template v-else>
        <li class="list-inline-item" v-if="exigenciasLocal && exigenciasLocal.length">
          <v-button :run="preVisualizarPdf" class="btn-outline-info btn-sm mr-1">
            Pré-visualizar PDF
          </v-button>
        </li>
      </template>

    </template>

    <div class="card">
      <div class="card-body">

        <h3>{{rotulo}}</h3>
        <hr v-if="exigenciasLocal.length" />

        <template v-if="exigenciasLocal.length">

          <draggable v-model="exigenciasLocal" ghost-class="ghost" handle=".move" tag="tbody" :disabled="!emEdicao">

            <tr class="bg-sidebar move" style="border-bottom: none !important" v-for="(texto, i) in exigenciasLocal" :key="i">
              <template v-if="edicao[i] && emEdicao">
                <td style="vertical-align: baseline; padding-right: 5px;">{{i + 1}}.</td>
                <td colspan="2">
                  <v-input type="classic-editor" v-model="exigenciasEdicao[i]" />
                  <v-button :run="salvar" :params="[i]" style="float: right;" class="btn-outline-primary mb-3">Salvar</v-button>
                  <v-button :run="cancelar" :params="[i]" style="float: left;" class="btn-outline-secondary mb-3">Cancelar</v-button>

                </td>
              </template>
              <template v-else>
                <td style="vertical-align: baseline; padding-right: 5px;">{{i + 1}}.</td>

                <td>
                  <div class="mb-2" v-html="texto"></div>
                </td>

                <td class="text-right" style="vertical-align: baseline; width: 50px" v-if="emEdicao">

                  <a class="icon mr-2" @click.prevent="editar(i)" href>
                    <i class="fal fa-edit" title="Editar"></i>
                  </a>

                  <v-button :run="remover" :params="[i]" :button="false" :popover="true" class="icon"
                            position="dropleft">
                    <i class="fal fa-times" title="Excluir"></i>
                  </v-button>

                </td>
              </template>
            </tr>
            <tr class="bg-sidebar" style="border-bottom: none !important" v-if="emEdicao">
              <td colspan="3" style="border-top: 1px solid #555555;padding-top: 10px;text-align: right;">
                <v-button :run="adicionar" class="btn-outline-primary">Adicionar</v-button>
              </td>
            </tr>

          </draggable>
        </template>

      </div>
    </div>

    <template #footer v-if="permiteEdicaoInterna">
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-auto">Cancelar</button>
      <v-button v-if="!emEdicao && !exigenciaLegada" :run="regerarExigencia" :popover="true"
                class="btn-outline-danger mr-2">Regerar Exigência</v-button>

      <v-button v-if="!emEdicao" :run="iniciarEdicao" :popover="false" class="btn-outline-primary">Editar</v-button>
      <v-button v-if="emEdicao" :run="save" :popover="true" class="btn-outline-primary">Finalizar</v-button>
    </template>

  </modal>
</template>

<script>
import AssinadorOnrBusiness from "@/business/AssinadorOnrBusiness.js";
import ChecklistProcessamentoBusiness from "@/business/ChecklistProcessamentoBusiness.js";
import FrontBusiness from "@/business/FrontBusiness.js";
import LacunaBusiness from "@/business/LacunaBusiness.js";
import ProtocoloAtividadeBusiness from "@/business/protocolo/ProtocoloAtividadeBusiness";
import RelatorioBusiness from "@/business/RelatorioBusiness.js";
import SiteBusiness from "@/business/SiteBusiness.js";
import Utils from "@/commons/Utils.js";
import Modal from "@/components/Modal.vue";
import draggable from 'vuedraggable'

export default {
    name: "NotaExigencia",
    components: {Modal, draggable},

    props: {
      protocolo: Object,
      atividade: Object,
      copilar: Object,
      exigencias: Array,
      permiteEdicao : {type : Boolean, default: () => false},
    },

    modal:{
      width: 1100
    },

    data() {
      return {
        exigenciasLocal: [],
        exigenciasEdicao: [],
        exigenciasCopiladas:[],
        emEdicao: false,
        loading: true,
        edicao: [],
        actionsAssinatura: [
          {class : 'btn-primary', label: 'Baixar Arquivo', action: this.assinar},
          {class : 'btn-info', label: 'Nova Assinatura', action: this.reassinar}
        ],
      }
    },
    async mounted() {

      let exigencias = this.exigencias;

      if(this.copilar?.exigencias){
        exigencias = await ChecklistProcessamentoBusiness.copilarExigencias(this.protocolo, this.copilar.dto, this.copilar.exigencias);
      }

      if (this.atividade?.id) {
        let atividade = await ProtocoloAtividadeBusiness.getById(this.atividade?.id);
        exigencias = atividade?.exigencias || [];
      }
      this.exigenciasLocal = Utils.uniq(exigencias).filter(a => a.length);
      for (let i = 0; i < this.exigenciasLocal.length ; i++) {
        this.edicao[i] = false;
      }

      this.$set(this, 'loading', false);

    },

    computed:{

      exigenciaLegada(){
        return this.atividade?.tipo == 'EXIGENCIA';
      },

      exigenciasFiltradas(){
        return Utils.uniq(this.exigenciasLocal);
      },

      rotulo() {
        return this.exigenciasFiltradas.length ? 'Para que o título seja registrado ou averbado é necessário:' : 'Não há exigências'
      },

      classe(){
        if(this.atividade?.tipo == 'EXIGENCIA'){
          return 'exigencia-legada';
        }else{
          return '';
        }
      },

      usuario() {
        return this.$root.usuarioLogado;
      },

      usuarioAdminOuGestor(){
        return this.usuario.administrador || this.usuario.gestor;
      },

      permitirEditarExigencia(){
        return this.$root.config?.exigencia?.permitirEditar;
      },

      permiteEdicaoInterna(){
        return this.permiteEdicao && (this.usuarioAdminOuGestor || (this.usuario.id == this.atividade?.usuario?.id && this.permitirEditarExigencia) || this.exigenciaLegada);
      },

    },

    methods: {

      close() {
        FrontBusiness.closeModal();
      },

      iniciarEdicao() {
        this.emEdicao = true;
      },

      remover(i) {
        this.$nextTick(() => {
          this.exigenciasLocal.splice(i, 1);
        });
      },

      editar(i) {
        this.$set(this.exigenciasEdicao, i, this.exigenciasLocal[i]);
        this.$set(this.edicao, i, true);
      },

      cancelar(i) {
        this.$set(this.edicao, i, false);
        this.exigenciasLocal = this.exigenciasLocal.filter(a => a.length)
      },

      salvar(i) {
        this.$set(this.exigenciasLocal, i, this.exigenciasEdicao[i]);
        this.$set(this.edicao, i, false);
        this.exigenciasLocal = this.exigenciasLocal.filter(a => a.length);
        this.save(i, false);
      },

      async save(i, reload = true) {
        let dto = Utils.clone(this.atividade);
        dto.exigencias = this.exigenciasLocal;
        this.exigenciasLocal = this.exigenciasLocal.filter(a => a.length);
        await ProtocoloAtividadeBusiness.alterarExigencia(Utils.clone(this.exigenciasLocal), this.atividade.id);
        if(reload){
          this.emEdicao = false;
        }
      },

      async regerarExigencia() {
        await ProtocoloAtividadeBusiness.regerarExigencia(this.atividade.id);
        FrontBusiness.showSuccess('', 'Exigência atualizada com sucesso');
        this.close();
      },

      adicionar() {
        this.exigenciasLocal.push('');
        this.editar(this.exigenciasLocal.length - 1);
      },

      async downloadPdf(){
        await RelatorioBusiness.visualizarPdf(SiteBusiness.baixarExigencia(this.protocolo.id, this.atividade.id, false), "Exigência");
      },

      async preVisualizarPdf(){
        await RelatorioBusiness.visualizarPdf(`api/public/site/exigencia/${this.protocolo.id}/pre-visualizar`, "Exigência", 'POST', {exigencias: this.exigenciasLocal});
      },

      async enviarEmail(){
        if(this.atividade){
          let r = await ProtocoloAtividadeBusiness.enviarEmailExigencia(this.protocolo.id, this.atividade.id).catch(e => {
            FrontBusiness.showError('Erro ao enviar e-mail', e.message);
            return false;
          });
          if(r){
            FrontBusiness.showSuccess('', 'E-mail enviado');
          }
        }else{
          FrontBusiness.showError('', 'Apenas notas registradas podem ser enviadas por e-mail');
        }
      },

      decodeHtml(html) {
        const nHtml = html.replaceAll('<br />', '\r\n').replaceAll('<br/>', '\r\n').replaceAll('<br>', '\r\n');
        let doc = new DOMParser().parseFromString(nHtml, 'text/html');
        return doc.body.textContent || "";
      },

      async downloadTxt() {

        let textFile = "data:text/txt;charset=utf-8,";
        const hr = '------------------------------------';
        textFile += 'Sistema Asgard';
        textFile += '\r\n\r\n';
        textFile += 'Exigências Protocolo ' + this.protocolo.codigo;
        textFile += '\r\n\r\n';
        textFile += hr;

        this.exigenciasFiltradas.forEach((nota) => {
          textFile += '\r\n\r\n';
          textFile += this.decodeHtml(nota).replace("\n/g", "\r\n");
          textFile += '\r\n\r\n';
          textFile += hr;
        });

        await setTimeout(() => {
          const encodedUri = encodeURI(textFile);
          const link = document.createElement("a");
          link.href = encodedUri;
          link.download = "Sistema Asgard - Nota de Exigência [" + this.protocolo.codigo + "].txt";
          document.body.appendChild(link);
          link.click();
        }, 100);

      },

      async reassinar(){
        await this.assinar(false);
      },

      async assinar(download = true) {

        if(!this.atividade){
          FrontBusiness.showError('', 'Apenas notas registradas podem ser enviadas por e-mail');
          return;
        }

        if(download && this.atividade.assinatura){
          const pdf = await LacunaBusiness.download('exigencia', this.atividade.id);
          FrontBusiness.downloadFile(pdf, 'exigencia.pdf');
          return;
        }

        if(this.$root.utilizarAssinadorOnr && this.$root.utilizarAssinadorOnrDemaisDocumentos){
          await AssinadorOnrBusiness.solicitarAssinatura('exigencia', [this.atividade.id]).catch(e => {
            console.error(e);
            FrontBusiness.showError('Erro ao tentar assinar exigência', e?.message || e);
            return false;
          });
          return;
        }

        await LacunaBusiness.preauthorizeSignatures(await LacunaBusiness.getThumb()).catch(e => {
          console.error(e);
          FrontBusiness.showError('', 'Erro ao tentar utilizar o certificado digital. Realize seu login novamente.');
          return false;
        });

        await LacunaBusiness.sign('exigencia', this.atividade.id, '', 'exigencia').catch(e => {
          console.error(e);
          FrontBusiness.showError('Erro ao tentar assinar exigência', e?.message || e);
          return false;
        });

      },

    }

  }
</script>

<style lang=scss>
  #exigencias-protocolo {

    ol {
      padding-inline-start: 20px;
      li {
        margin-bottom: 10px;
      }

      &.exigencia-legada{
        &, *{
          color: white !important;
        }
      }

    }

  }
</style>
