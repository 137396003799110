import EnderecoBusiness from "@/business/EnderecoBusiness";
import CachedApi from "@/commons/CachedApi.js";
import Http, {source} from "@/commons/Http";
import Utils from "@/commons/Utils.js";

const path = 'api/indicador-pessoal';

let cancelSource;
const cancelSearch = () => {
  if (cancelSource) {
    cancelSource.cancel();
  }
}

const tratarAto = (a) => {

  a.tipoServico = JSON.parse(a.tipoServico);
  if(a.tipoServico?.opcoes && typeof a.tipoServico?.opcoes === 'string'){
    a.tipoServico.opcoes = JSON.parse(a.tipoServico.opcoes || false)
  }
  a.ato = a.ato ? JSON.parse(a.ato) : undefined;

  a.indicador = JSON.parse(a.indicador || false);
  (a.indicador?.id) ? '' : a.indicador = undefined;

  a.registroAuxiliar = JSON.parse(a.registroAuxiliar || false);
  (a.registroAuxiliar?.id) ? '' : a.registroAuxiliar = undefined;

  a.indicadorReal = JSON.parse(a.indicadorReal || false);
  (a.indicadorReal?.id) ? '' : a.indicadorReal = undefined;

  a.ficha = a.indicadorReal || a.registroAuxiliar || a.indicador;

  if(a.validador) a.validador = JSON.parse(a.validador);


  return a;

};

const tratarAtoVersao = (a) => {

  a.tipoServico = JSON.parse(a.tipoServico);
  if(a.tipoServico?.opcoes && typeof a.tipoServico?.opcoes === 'string'){
    a.tipoServico.opcoes = JSON.parse(a.tipoServico.opcoes || false)
  }
  a.dto = JSON.parse(a.dto);

  a.indicador = JSON.parse(a.indicador || false);
  (a.indicador?.id) ? '' : a.indicador = undefined;

  a.registroAuxiliar = JSON.parse(a.registroAuxiliar || false);
  (a.registroAuxiliar?.id) ? '' : a.registroAuxiliar = undefined;

  a.indicadorReal = JSON.parse(a.indicadorReal || false);
  (a.indicadorReal?.id) ? '' : a.indicadorReal = undefined;

  a.ficha = a.indicadorReal || a.registroAuxiliar || a.indicador;

  return a;

};

let cacheTime = '';

export default {

  async pagination(sort, direction, page, size, filters = {}) {
    return Http.post(`${path}/pagination?sort=${sort || 'v.nome'},${direction || 'asc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async paginationV2(sort, direction, page, size, filters = {}) {
    return Http.post(`${path}/v2/pagination?sort=${sort || 'v.nome'},${direction || 'asc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async getVersaoById(id) {
    if(!Utils.testUUID(id)){
      return Promise.reject();
    }
    return Http.get(`${path}/versao/${id}`);
  },

  async getIndisponibilidades(id) {
    if(!Utils.testUUID(id)){
      return Promise.reject();
    }
    return Http.get(`${path}/${id}/indisponibilidades`);
  },

  async possuiIndisponibilidades(id) {
    if(!Utils.testUUID(id)){
      return Promise.reject();
    }
    return CachedApi.getCached('indisponibilidades-'+id, async () => {
      return Http.get(`${path}/${id}/possui-indisponibilidades`);
    });
  },

  async possuiSaldoPendente(id, protocolo) {
    return Http.get(`${path}/${id}/possui-saldo-pendente${protocolo ? '/'+protocolo :''}`);
  },

  resetCache(){
    cacheTime = Utils.uuid();
  },

  async getVersaoByIdComCache(id) {
    if(!Utils.testUUID(id)){
      return Promise.reject();
    }
    return CachedApi.getCached('versao-'+id, async () => {
      return Http.get(`${path}/versao/${id}/cache?resetCache=${cacheTime}`);
    });
  },

  async getById(id) {
    if(!Utils.testUUID(id)){
      return Promise.reject();
    }
    return Http.get(`${path}/${id}`);
  },

  async getVersoes(id) {
    if(!Utils.testUUID(id)){
      return Promise.reject();
    }
    return Http.get(`${path}/${id}/versoes`).then(l => l.map(tratarAto));
  },

  async getVersoesAll(id) {
    if(!Utils.testUUID(id)){
      return Promise.reject();
    }
    return Http.get(`${path}/${id}/versoes-all`);
  },

  async permiteRemover(id) {
    return Http.get(`${path}/versao/${id}/permite-remover`);
  },

  async deleteByIdVersao(id){
    if(!Utils.testUUID(id)){
      return Promise.reject();
    }
    return Http.delete(`${path}/versao/${id}`);
  },

  async updateVersaoAtual(id, versaoId, versao){
    if(!Utils.testUUID(id) || !Utils.testUUID(versaoId) || !versao){
      return Promise.reject();
    }
    return Http.get(`${path}/${id}/versao/${versaoId}/${versao}`);
  },

  async getDocumentosDuplicados(id) {
    if(!Utils.testUUID(id)){
      return Promise.reject();
    }
    return Http.get(`${path}/${id}/documentos-duplicados`);
  },

  async getQtdDocumentosDuplicados(id) {
    if(!Utils.testUUID(id)){
      return Promise.reject();
    }
    return Http.get(`${path}/${id}/qtd-documentos-duplicados`);
  },

  async mergeDocuments(id, lista){
    if(!Utils.testUUID(id)){
      return Promise.reject();
    }
    return Http.post(`${path}/${id}/merge`, lista);
  },

  async buscarAtosRegistrados(id, tipo) {
    return Http.get(`${path}/${id}/atos-registrados/${tipo}`).then(l => l.map(tratarAto));
  },

  async buscarAtosRegistradosPJ(documento, atribuicao) {
    let params = new URLSearchParams();
    params.set("documento",documento);
    params.set("atribuicao",atribuicao);
    return Http.get(`${path}/atos-registrados-pj`,{params});
  },

  async buscarUltimaAuditoriaImpressaoAto(id){
    return Http.get(`${path}/${id}/ultima-auditoria-impressao`);
  },

  async buscarAtosVersao(id) {
    return Http.get(`${path}/${id}/atos-versao`).then(l => l.map(tratarAtoVersao));
  },

  async buscarProtocolosAtivos(id) {
    return Http.get(`${path}/${id}/protocolos-ativos`);
  },

  async buscarPropriedadesAtuais(id){
    return Http.get(`${path}/${id}/propriedades-atuais`);
  },

  async buscarPropriedadesAtuaisPorDocumento(documento){
    return Http.get(`${path}/buscar-propriedades-atuais/${documento}`);
  },

  async buscarExPropriedades(id) {
    return Http.get(`${path}/${id}/ex-propriedades`);
  },

  async sugerir(filters) {
    cancelSearch();
    cancelSource = source.source();
    let retorno;
    await Http.post(`${path}/sugerir`, filters, { cancelToken: cancelSource.token }).then((response) => {
      if (response) {
        retorno = response;
        cancelSource = null;
      }
    });
    return retorno;
  },

  async sugerirProfissao(query) {
    return Http.get(`${path}/sugerir/profissao/${query}`);
  },

  async validarDadosAlteracaoIndicador(dto){
    return Http.post(`${path}/validar-dados-alteracoes-indicador`, dto);
  },

  async validarDadosCadastroIndicador(indicador){
    return Http.post(`${path}/validar-dados-cadastro-indicador`, indicador);
  },

  async getRestricoes(indicadorPessoalId) {
    return Http.get(`${path}/restricoes/${indicadorPessoalId}`);
  },

  async getRegistrosAuxiliares(indicadorPessoalId) {
    return Http.get(`${path}/registros-auxiliares/${indicadorPessoalId}`);
  },

  async criarIndicador(dto) {
    return Http.post(`${path}`, dto);
  },

  async criarVersao(id, editar = false, dto) {
    return Http.post(`${path}/${id}/${editar}`, dto);
  },

  async getErrosTomador(indicadorPessoalVersaoId) {
    return Http.get(`${path}/tomador/erros/${indicadorPessoalVersaoId}`);
  },

  async getVinculoSiscoaf(indicadorPessoalVersaoId) {
    return Http.get(`${path}/versao/${indicadorPessoalVersaoId}/vinculo-siscoaf`);
  },

  async validarNome(nome) {
    if(nome) {
      return Http.get(`${path}/public/validar/nome/${nome.replace('/', '')}`);
    }
    return "Nome inválido!";
  },

  async validarDocumentos(tipoPessoa, nrDocumento, permiteNove = false) {
    let doc = (nrDocumento || '').replace(/\D+/g, "").trim();

    if(!tipoPessoa){
      tipoPessoa = doc.length > 11 ? 'JURIDICA' : 'FISICA';
    }
    if (doc) {
      return Http.get(`${path}/public/validar/documento/${tipoPessoa}/${doc}/${permiteNove}`);
    }
    return "Documento inválido!";
  },

  getNaturezaJuridica() {
    return [
      {id: 'DIREITO_PRIVADO', nome: 'Pessoa Jurídica de Direito Privado'},
      {id: 'DIREITO_PUBLICO', nome: 'Pessoa Jurídica de Direito Público'},
      {id: 'AUTARQUIA_FEDERAL', nome: 'Autarquia Federal'},
      {id: 'AUTARQUIA_ESTADUAL', nome: 'Autarquia Estadual'},
      {id: 'AUTARQUIA_MUNICIPAL', nome: 'Autarquia Municipal'},
      {id: 'EMPRESA_PUBLICA', nome: 'Empresa Pública'},
      {id: 'SOCIEDADE_DE_ECONOMIA_MISTA', nome: 'Sociedade de Economia Mista'},
      {id: 'SOCIEDADE_ANONIMA_ABERTA', nome: 'Sociedade Anônima Aberta'},
      {id: 'SOCIEDADE_ANONIMA_FECHADA', nome: 'Sociedade Anônima Fechada'},
      {id: 'SOCIEDADE_EMPRESARIA_LIMITADA', nome: 'Sociedade Empresária Limitada'},
      {id: 'SOCIEDADE_EMPRESARIA_EM_NOME_COLETIVO', nome: 'Sociedade Empresária em Nome Coletivo'},
      {id: 'SOCIEDADE_EMPRESARIA_EM_COMANDITA_SIMPLES', nome: 'Sociedade Empresária em Comandita Simples'},
      {id: 'SOCIEDADE_EMPRESARIA_EM_COMANDITA_POR_ACOES', nome: 'Sociedade Empresária em Comandita por Ações'},
      {id: 'SOCIEDADE_EM_CONTA_DE_PARTICIPACAO', nome: 'Sociedade em Conta de Participação'},
      {id: 'SOCIEDADE_UNIPESSOAL_LIMITADA', nome: 'Sociedade Unipessoal Limitada'},
      {id: 'MEI', nome: 'Empresário Individual'},
      {id: 'COOPERATIVA', nome: 'Cooperativa'},
      {id: 'CONSORCIO_DE_SOCIEDADES', nome: 'Consórcio de Sociedades'},
      {id: 'GRUPO_DE_SOCIEDADES', nome: 'Grupo de Sociedades'},
      {id: 'SOCIEDADE_ESTRANGEIRA', nome: 'Estabelecimento, no Brasil, de Sociedade Estrangeira'},
      {id: 'EMPRESA_BINACIONAL_ARGENTINA', nome: 'Estabelecimento, no Brasil, de Empresa Binacional Argentino-Brasileira'},
      {id: 'EMPRESA_DOMICILIADA_NO_EXTERIOR', nome: 'Empresa Domiciliada no Exterior'},
      {id: 'CLUBE_FUNDO_DE_INVESTIMENTO', nome: 'Clube/Fundo de Investimento'},
      {id: 'SOCIEDADE_SIMPLES_PURA', nome: 'Sociedade Simples Pura'},
      {id: 'SOCIEDADE_SIMPLES_LIMITADA', nome: 'Sociedade Simples Limitada'},
      {id: 'SOCIEDADE_SIMPLES_EM_NOME_COLETIVO', nome: 'Sociedade Simples em Nome Coletivo'},
      {id: 'SOCIEDADE_SIMPLES_EM_COMANDITA_SIMPLES', nome: 'Sociedade Simples em Comandita Simples'},
      {id: 'EMPRESA_BINACIONAL', nome: 'Empresa Binacional'},
      {id: 'CONSORCIO_DE_EMPREGADORES', nome: 'Consórcio de Empregadores'},
      {id: 'CONSORCIO_SIMPLES', nome: 'Consórcio Simples'},
      {id: 'EIRELI', nome: 'Empresa Individual de Responsabilidade Limitada (de Natureza Empresária)'},
      {id: 'EIRELI_SIMPLES', nome: 'Empresa Individual de Responsabilidade Limitada (de Natureza Simples)'},
      {id: 'SOCIEDADE_UNIPESSOAL_DE_ADVOGADOS', nome: 'Sociedade Unipessoal de Advogados'},
      {id: 'COOPERATIVAS_DE_CONSUMO', nome: 'Cooperativas de Consumo'},
      {id: 'ORGANIZACAO_RELIGIOSA', nome: 'Organização Religiosa'},
      {id: 'ASSOCIACAO_PRIVADA', nome: 'Associação Privada'},
      {id: 'ORGANIZACAO_INTERNACIONAL', nome: 'Organização Internacional'},
      {id: 'REPRESENTACAO_DIPLOMATICA_ESTRANGEIRA', nome: 'Representação Diplomática Estrangeira'},
      {id: 'OUTRAS_INSTITUICOES_EXTRATERRITORIAIS', nome: 'Outras Instituições Extraterritoriais'},
      {id: 'OUTRAS', nome: 'Outras'},
    ]
  },

  getSexos() {
    return [
      {id: 'MASCULINO', nome: 'Masculino'},
      {id: 'FEMININO', nome: 'Feminino'},
    ]
  },

  getRegimes() {
    return [
      {id: 'COMUNHAO_PARCIAL', nome: 'Comunhão Parcial de Bens', descricao: 'pelo regime da comunhão parcial'},
      {id: 'COMUNHAO_UNIVERSAL', nome: 'Comunhão Universal de Bens', descricao: 'pelo regime da comunhão universal'},
      {id: 'PARTICIPACAO_FINAL_AQUESTOS', nome: 'Participação Final nos Aquestos', descricao: 'pelo regime de participação final nos aquestos'},
      {id: 'SEPARACAO_OBRIGATORIA', nome: 'Separação Obrigatória de Bens', descricao: 'pelo regime da separação obrigatória'},
      {id: 'SEPARACAO_TOTAL', nome: 'Separação Total', descricao: 'pelo regime da separação total'},
      {id: 'CASAMENTO_EXTERIOR', nome: 'Casamento no Exterior', descricao: ''},
      {id: 'DESCONHECIDO', nome: 'Desconhecido', descricao: ''},
    ]
  },

  getNacionalidades() {
    return [
      {id: 'BRASIL', nome: 'Brasileiro(a)', masculino: 'Brasileiro', feminino: 'Brasileira', pais: 'Brasil'},
      {id: 'AFEGANISTAO', nome: 'Afegã(o)', masculino: 'Afegão', feminino: 'Afegã', pais: 'Afeganistão'},
      {id: 'AFRICA_DO_SUL', nome: 'Sul-africano(a)', masculino: 'Sul-africano', feminino: 'Sul-africana', pais: 'África do Sul'},
      {id: 'ALBANIA', nome: 'Albanês(esa)', masculino: 'Albanês', feminino: 'Albanesa', pais: 'Albânia'},
      {id: 'ALEMANHA', nome: 'Alemã(ão)', masculino: 'Alemão', feminino: 'Alemã', pais: 'Alemanha'},
      {id: 'ANDORRA', nome: 'Andorrano(a)', masculino: 'Andorrano', feminino: 'Andorrana', pais: 'Andorra'},
      {id: 'ANGOLA', nome: 'Angolano(a)', masculino: 'Angolano', feminino: 'Angolana', pais: 'Angola'},
      {id: 'ANGUILLA', nome: 'Anguilano(a)', masculino: 'Anguilano', feminino: 'Anguilana', pais: 'Anguilla'},
      {id: 'ANTIGUA_E_BARBUDA', nome: 'Antiguano(a)', masculino: 'Antiguano', feminino: 'Antiguana', pais: 'Antigua e Barbuda'},
      {id: 'ARABIA_SAUDITA', nome: 'Árabe-saudita', masculino: 'Árabe-saudita', feminino: 'Árabe_saudita', pais: 'Arábia Saudita'},
      {id: 'ARGELIA', nome: 'Argelino(a)', masculino: 'Argelino', feminino: 'Argelina', pais: 'Argélia'},
      {id: 'ARGENTINA', nome: 'Argentino(a)', masculino: 'Argentino', feminino: 'Argentina', pais: 'Argentina'},
      {id: 'ARMENIA', nome: 'Arménio(a)', masculino: 'Arménio', feminino: 'Arménia', pais: 'Arménia'},
      {id: 'ARUBA', nome: 'Arubano(a)', masculino: 'Arubano', feminino: 'Arubana', pais: 'Aruba'},
      {id: 'AUSTRALIA', nome: 'Australiano(a)', masculino: 'Australiano', feminino: 'Australiana', pais: 'Austrália'},
      {id: 'AUSTRIA', nome: 'Austríaco(a)', masculino: 'Austríaco', feminino: 'Austríaca', pais: 'Áustria'},
      {id: 'AZERBAIJAO', nome: 'Azeri', masculino: 'Azeri', feminino: 'Azeri', pais: 'Azerbaijão'},
      {id: 'BAHAMAS', nome: 'Bahamense', masculino: 'Bahamense', feminino: 'Bahamense', pais: 'Bahamas'},
      {id: 'BAHREIN', nome: 'Barenita', masculino: 'Barenita', feminino: 'Barenita', pais: 'Bahrein'},
      {id: 'BANGLADESH', nome: 'Bangladechiano(a)', masculino: 'Bangladechiano', feminino: 'Bangladechiana', pais: 'Bangladesh'},
      {id: 'BARBADOS', nome: 'Barbadense', masculino: 'Barbadense', feminino: 'Barbadense', pais: 'Barbados'},
      {id: 'BELGICA', nome: 'Belga', masculino: 'Belga', feminino: 'Belga', pais: 'Bélgica'},
      {id: 'BELIZE', nome: 'Belizenho(a)', masculino: 'Belizenho', feminino: 'Belizenha', pais: 'Belize'},
      {id: 'BENIM', nome: 'Benineseo(a)', masculino: 'Benineseo', feminino: 'Beninesea', pais: 'Benim'},
      {id: 'BERMUDAS', nome: 'Bermudense', masculino: 'Bermudense', feminino: 'Bermudense', pais: 'Bermudas'},
      {id: 'BIELORRUSSIA', nome: 'Bielorrusso(a)', masculino: 'Bielorrusso', feminino: 'Bielorrussa', pais: 'Bielorrússia'},
      {id: 'BOLIVIA', nome: 'Boliviano(a)', masculino: 'Boliviano', feminino: 'Boliviana', pais: 'Bolívia'},
      {id: 'BOSNIA_E_HERZEGOVINA', nome: 'Bósnio(a)', masculino: 'Bósnio', feminino: 'Bósnia', pais: 'Bósnia e Herzegovina'},
      {id: 'BOTSWANA', nome: 'Botsuano(a)', masculino: 'Botsuano', feminino: 'Botsuana', pais: 'Botswana'},
      {id: 'BRUNEI', nome: 'Bruneíno(a)', masculino: 'Bruneíno', feminino: 'Bruneína', pais: 'Brunei'},
      {id: 'BULGARIA', nome: 'Búlgaro(a)', masculino: 'Búlgaro', feminino: 'Búlgara', pais: 'Bulgária'},
      {id: 'BURKINA_FASO', nome: 'Burquino(a)', masculino: 'Burquino', feminino: 'Burquina', pais: 'Burkina Faso'},
      {id: 'BURUNDI', nome: 'Burundiano(a)', masculino: 'Burundiano', feminino: 'Burundiana', pais: 'Burúndi'},
      {id: 'BUTAO', nome: 'Butanês(esa)', masculino: 'Butanês', feminino: 'Butanesa', pais: 'Butão'},
      {id: 'CABO_VERDE', nome: 'Cabo-verdiano(a)', masculino: 'Cabo-verdiano', feminino: 'Cabo-verdiana', pais: 'Cabo Verde'},
      {id: 'CAMAROES', nome: 'Camaronês(esa)', masculino: 'Camaronês', feminino: 'Camaronesa', pais: 'Camarões'},
      {id: 'CAMBOJA', nome: 'Cambojano(a)', masculino: 'Cambojano', feminino: 'Cambojana', pais: 'Camboja'},
      {id: 'CANADA', nome: 'Canadense', masculino: 'Canadense', feminino: 'Canadense', pais: 'Canadá'},
      {id: 'CAZAQUISTAO', nome: 'Cazaque', masculino: 'Cazaque', feminino: 'Cazaque', pais: 'Cazaquistão'},
      {id: 'CHADE', nome: 'Chadiano(a)', masculino: 'Chadiano', feminino: 'Chadiana', pais: 'Chade'},
      {id: 'CHILE', nome: 'Chileno(a)', masculino: 'Chileno', feminino: 'Chilena', pais: 'Chile'},
      {id: 'CHINA', nome: 'Chinês(esa)', masculino: 'Chinês', feminino: 'Chinesa', pais: 'China'},
      {id: 'CHIPRE', nome: 'Cipriota', masculino: 'Cipriota', feminino: 'Cipriota', pais: 'Chipre'},
      {id: 'COLOMBIA', nome: 'Colombiano(a)', masculino: 'Colombiano', feminino: 'Colombiana', pais: 'Colômbia'},
      {id: 'COMORES', nome: 'Comoriano(a)', masculino: 'Comoriano', feminino: 'Comoriana', pais: 'Comores'},
      {id: 'REPUBLICA_DO_CONGO', nome: 'Congolês(a)', masculino: 'Congolês', feminino: 'Congolêsa', pais: 'Republica do Congo'},
      {id: 'COREIA_DO_NORTE', nome: 'Norte-coreano(a)', masculino: 'Norte-coreano', feminino: 'Norte-coreana', pais: 'Coreia do Norte'},
      {id: 'COREIA_DO_SUL', nome: 'Sul-coreano(a)', masculino: 'Sul-coreano', feminino: 'Sul-coreana', pais: 'Coreia do Sul'},
      {id: 'COSTA_DO_MARFIM', nome: 'Marfinense', masculino: 'Marfinense', feminino: 'Marfinense', pais: 'Costa do Marfim'},
      {id: 'COSTA_RICA', nome: 'Costarriquenho(a)', masculino: 'Costarriquenho', feminino: 'Costarriquenha', pais: 'Costa Rica'},
      {id: 'CROACIA', nome: 'Croata', masculino: 'Croata', feminino: 'Croata', pais: 'Croácia'},
      {id: 'CUBA', nome: 'Cubano(a)', masculino: 'Cubano', feminino: 'Cubana', pais: 'Cuba'},
      {id: 'CURACAO', nome: 'Curaçauense', masculino: 'Curaçauense', feminino: 'Curaçauense', pais: 'Curacao'},
      {id: 'DINAMARCA', nome: 'Dinamarquês(esa)', masculino: 'Dinamarquês', feminino: 'Dinamarquesa', pais: 'Dinamarca'},
      {id: 'DJIBOUTI', nome: 'Jibutiano(a)', masculino: 'Jibutiano', feminino: 'Jibutiana', pais: 'Djibouti'},
      {id: 'DOMINICA', nome: 'Dominiquês(esa)', masculino: 'Dominiquês', feminino: 'Dominiquesa', pais: 'Dominica'},
      {id: 'EGITO', nome: 'Egípcio(a)', masculino: 'Egípcio', feminino: 'Egípcia', pais: 'Egito'},
      {id: 'EL_SALVADOR', nome: 'Salvadorenho(a)', masculino: 'Salvadorenho', feminino: 'Salvadorenha', pais: 'el Salvador'},
      {id: 'EMIRADOS_ARABES', nome: 'Emiradense', masculino: 'Emiradense', feminino: 'Emiradense', pais: 'Emirados Arabes'},
      {id: 'EQUADOR', nome: 'Equatoriano(a)', masculino: 'Equatoriano', feminino: 'Equatoriana', pais: 'Equador'},
      {id: 'ERITREIA', nome: 'Eritreu(ia)', masculino: 'Eritreu', feminino: 'Eritria', pais: 'Eritreia'},
      {id: 'ESCOCIA', nome: 'Escocês(esa)', masculino: 'Escocês', feminino: 'Escocesa', pais: 'Escocia'},
      {id: 'ESLOVAQUIA', nome: 'Eslovaco(a)', masculino: 'Eslovaco', feminino: 'Eslovaca', pais: 'Eslováquia'},
      {id: 'ESLOVENIA', nome: 'Esloveno(a)', masculino: 'Esloveno', feminino: 'Eslovena', pais: 'Eslovénia'},
      {id: 'ESPANHA', nome: 'Espanhol(a)', masculino: 'Espanhol', feminino: 'Espanhola', pais: 'Espanha'},
      {id: 'ESTADOS_FEDERADOS_DA_MICRONESIA', nome: 'Micronésio(a)', masculino: 'Micronésio', feminino: 'Micronésia', pais: 'Estados Federados da Micronesia'},
      {id: 'ESTADOS_UNIDOS', nome: 'Norte-americano(a)', masculino: 'Norte-americano', feminino: 'Norte-americana', pais: 'Estados Unidos'},
      {id: 'ESTONIA', nome: 'Estónio(a)', masculino: 'Estónio', feminino: 'Estónia', pais: 'Estónia'},
      {id: 'ETIOPIA', nome: 'Etíope', masculino: 'Etíope', feminino: 'Etíope', pais: 'Etiópia'},
      {id: 'FIJI', nome: 'Fijiano(a)', masculino: 'Fijiano', feminino: 'Fijiana', pais: 'Fiji'},
      {id: 'FILIPINAS', nome: 'Filipino', masculino: 'Filipino', feminino: 'Filipino', pais: 'Filipinas'},
      {id: 'FINLANDIA', nome: 'Finlandês(esa)', masculino: 'Finlandês', feminino: 'Finlandesa', pais: 'Finlândia'},
      {id: 'FRANCA', nome: 'Francês(esa)', masculino: 'Francês', feminino: 'Francesa', pais: 'França'},
      {id: 'GABAO', nome: 'Gabonês(a)', masculino: 'Gabonês', feminino: 'Gabonêsa', pais: 'Gabão'},
      {id: 'GAMBIA', nome: 'Gambiano(a)', masculino: 'Gambiano', feminino: 'Gambiana', pais: 'Gâmbia'},
      {id: 'GANA', nome: 'Ganês(a)', masculino: 'Ganês', feminino: 'Ganêsa', pais: 'Gana'},
      {id: 'GEORGIA', nome: 'Georgiano(a)', masculino: 'Georgiano', feminino: 'Georgiana', pais: 'Geórgia'},
      {id: 'GRANADA', nome: 'Granadino(a)', masculino: 'Granadino', feminino: 'Granadina', pais: 'Granada'},
      {id: 'GRECIA', nome: 'Grego(a)', masculino: 'Grego', feminino: 'Grega', pais: 'Grécia'},
      {id: 'GUADALUPE', nome: 'Guadalupense', masculino: 'Guadalupense', feminino: 'Guadalupense', pais: 'Guadalupe'},
      {id: 'GUAM', nome: 'Guamês(a)', masculino: 'Guamês', feminino: 'Guamêsa', pais: 'Guam'},
      {id: 'GUATEMALA', nome: 'Guatemalteco(a)', masculino: 'Guatemalteco', feminino: 'Guatemalteca', pais: 'Guatemala'},
      {id: 'GUIANA', nome: 'Guianês(esa)', masculino: 'Guianês', feminino: 'Guianesa', pais: 'Guiana'},
      {id: 'GUIANA_FRANCESA', nome: 'Guianense', masculino: 'Guianense', feminino: 'Guianense', pais: 'Guiana Francesa'},
      {id: 'GUINE', nome: 'Guineano(a)', masculino: 'Guineano', feminino: 'Guineana', pais: 'Guiné'},
      {id: 'GUINE_EQUATORIAL', nome: 'Guinéu-equatoriano(a)', masculino: 'Guinéu-equatoriano', feminino: 'Guinéu-equatoriana', pais: 'Guiné Equatorial'},
      {id: 'GUINE_BISSAU', nome: 'Guineense', masculino: 'Guineense', feminino: 'Guineense', pais: 'Guine Bissau'},
      {id: 'HAITI', nome: 'Haitiano(a)', masculino: 'Haitiano', feminino: 'Haitiana', pais: 'Haiti'},
      {id: 'HONDURAS', nome: 'Hondurenho(a)', masculino: 'Hondurenho', feminino: 'Hondurenha', pais: 'Honduras'},
      {id: 'HONG_KONG', nome: 'Honconguês(esa)', masculino: 'Honconguês', feminino: 'Honconguesa', pais: 'Hong Kong'},
      {id: 'HUNGRIA', nome: 'Húngaro(a)', masculino: 'Húngaro', feminino: 'Húngara', pais: 'Hungria'},
      {id: 'IEMEN', nome: 'Iemenita', masculino: 'Iemenita', feminino: 'Iemenita', pais: 'Iémen'},
      {id: 'ILHAS_CAYMAN', nome: 'Caimanês(a)', masculino: 'Caimanês', feminino: 'Caimanêsa', pais: 'Ilhas Cayman'},
      {id: 'ILHAS_COOK', nome: 'Cookense', masculino: 'Cookense', feminino: 'Cookense', pais: 'Ilhas Cook'},
      {id: 'ILHAS_FEROE', nome: 'Faroense', masculino: 'Faroense', feminino: 'Faroense', pais: 'Ilhas Feroe'},
      {id: 'ILHAS_SALOMAO', nome: 'Salomonense', masculino: 'Salomonense', feminino: 'Salomonense', pais: 'Ilhas Salomao'},
      {id: 'ILHAS_VIRGENS', nome: 'Virginense', masculino: 'Virginense', feminino: 'Virginense', pais: 'Ilhas Virgens'},
      {id: 'INDIA', nome: 'Indiano(a)', masculino: 'Indiano', feminino: 'Indiana', pais: 'Índia'},
      {id: 'INDONESIA', nome: 'Indonésia(o)', masculino: 'Indonésio', feminino: 'Indonésia', pais: 'Indonésia'},
      {id: 'INGLATERRA', nome: 'Inglês(esa)', masculino: 'Inglês', feminino: 'Inglesa', pais: 'Inglaterra'},
      {id: 'IRAO', nome: 'Iraniano(a)', masculino: 'Iraniano', feminino: 'Iraniana', pais: 'Irão'},
      {id: 'IRAQUE', nome: 'Iraquiano(a)', masculino: 'Iraquiano', feminino: 'Iraquiana', pais: 'Iraque'},
      {id: 'IRLANDA', nome: 'Irlandês(a)', masculino: 'Irlandês', feminino: 'Irlandêsa', pais: 'Irlanda'},
      {id: 'IRLANDA_DO_NORTE', nome: 'Norte-irlandês(esa)', masculino: 'Norte-irlandês', feminino: 'Norte-irlandesa', pais: 'Irlanda do Norte'},
      {id: 'ISLANDIA', nome: 'Islandês(esa)', masculino: 'Islandês', feminino: 'Islandesa', pais: 'Islândia'},
      {id: 'ISRAEL', nome: 'Israelita', masculino: 'Israelita', feminino: 'Israelita', pais: 'Israel'},
      {id: 'ITALIA', nome: 'Italiano(a)', masculino: 'Italiano', feminino: 'Italiana', pais: 'Itália'},
      {id: 'JAMAICA', nome: 'Jamaicano(a)', masculino: 'Jamaicano', feminino: 'Jamaicana', pais: 'Jamaica'},
      {id: 'JAPAO', nome: 'Japonês(esa)', masculino: 'Japonês', feminino: 'Japonesa', pais: 'Japão'},
      {id: 'JORDANIA', nome: 'Jordano(a)', masculino: 'Jordano', feminino: 'Jordana', pais: 'Jordânia'},
      {id: 'KIRIBATI', nome: 'Quiribatiano(a)', masculino: 'Quiribatiano', feminino: 'Quiribatiana', pais: 'Kiribati'},
      {id: 'KOSOVO', nome: 'Kosovar', masculino: 'Kosovar', feminino: 'Kosovar', pais: 'Kosovo'},
      {id: 'KUWAIT', nome: 'Kuwaitiano(a)', masculino: 'Kuwaitiano', feminino: 'Kuwaitiana', pais: 'Kuwait'},
      {id: 'LAOS', nome: 'Laociano(a)', masculino: 'Laociano', feminino: 'Laociana', pais: 'Laos'},
      {id: 'LESOTO', nome: 'Lesotiano(a)', masculino: 'Lesotiano', feminino: 'Lesotiana', pais: 'Lesoto'},
      {id: 'LETONIA', nome: 'Letã(o)', masculino: 'Letão', feminino: 'Letã', pais: 'Letónia'},
      {id: 'LIBANO', nome: 'Libanês(a)', masculino: 'Libanês', feminino: 'Libanêsa', pais: 'Líbano'},
      {id: 'LIBERIA', nome: 'Liberiano(a)', masculino: 'Liberiano', feminino: 'Liberiana', pais: 'Libéria'},
      {id: 'LIBIA', nome: 'Líbio(a)', masculino: 'Líbio', feminino: 'Líbia', pais: 'Líbia'},
      {id: 'LIECHTENSTEIN', nome: 'Listenstainiano(a)', masculino: 'Listenstainiano', feminino: 'Listenstainiana', pais: 'Liechtenstein'},
      {id: 'LITUANIA', nome: 'Lituano(a)', masculino: 'Lituano', feminino: 'Lituana', pais: 'Lituânia'},
      {id: 'LUXEMBURGO', nome: 'Luxemburguês(a)', masculino: 'Luxemburguês', feminino: 'Luxemburguêsa', pais: 'Luxemburgo'},
      {id: 'MACAU', nome: 'Macaense', masculino: 'Macaense', feminino: 'Macaense', pais: 'Macau'},
      {id: 'MACEDONIA_DO_NORTE', nome: 'Macedónico(a)', masculino: 'Macedónico', feminino: 'Macedónica', pais: 'Macedonia do Norte'},
      {id: 'MADAGASCAR', nome: 'Malgaxe', masculino: 'Malgaxe', feminino: 'Malgaxe', pais: 'Madagáscar'},
      {id: 'MALASIA', nome: 'Malaio(a)', masculino: 'Malaio', feminino: 'Malaia', pais: 'Malásia'},
      {id: 'MALAWI', nome: 'Malauiano(a)', masculino: 'Malauiano', feminino: 'Malauiana', pais: 'Malawi'},
      {id: 'MALDIVAS', nome: 'Maldivo(a)', masculino: 'Maldivo', feminino: 'Maldiva', pais: 'Maldivas'},
      {id: 'MALI', nome: 'Maliano', masculino: 'Maliano', feminino: 'Maliano', pais: 'Mali'},
      {id: 'MALTA', nome: 'Maltês(a)', masculino: 'Matês', feminino: 'Maltêsa', pais: 'Malta'},
      {id: 'MARROCOS', nome: 'Marroquino(a)', masculino: 'Marroquino', feminino: 'Marroquina', pais: 'Marrocos'},
      {id: 'MARTINICA', nome: 'Martinicano(a)', masculino: 'Martinicano', feminino: 'Martinicana', pais: 'Martinica'},
      {id: 'MAURICIA', nome: 'Mauriciano(a)', masculino: 'Mauriciano', feminino: 'Mauriciana', pais: 'Maurícia'},
      {id: 'MAURITANIA', nome: 'Mauritano(a)', masculino: 'Mauritano', feminino: 'Malritana', pais: 'Mauritânia'},
      {id: 'MEXICO', nome: 'Mexicano(a)', masculino: 'Mexicano', feminino: 'Mexicana', pais: 'México'},
      {id: 'MIANMAR', nome: 'Birmanês(a)', masculino: 'Birmanês', feminino: 'Birmanêsa', pais: 'Mianmar'},
      {id: 'MOCAMBIQUE', nome: 'Moçambicano(a)', masculino: 'Moçambicano', feminino: 'Moçambicana', pais: 'Moçambique'},
      {id: 'MOLDAVIA', nome: 'Moldavo(a)', masculino: 'Moldavo', feminino: 'Moldava', pais: 'Moldávia'},
      {id: 'MONACO', nome: 'Monegasco(a)', masculino: 'Monegasco', feminino: 'Monegasca', pais: 'Mónaco'},
      {id: 'MONGOLIA', nome: 'Mongol', masculino: 'Mongol', feminino: 'Mongol', pais: 'Mongólia'},
      {id: 'MONTENEGRO', nome: 'Montenegrino(a)', masculino: 'Montenegrino', feminino: 'Montenegrina', pais: 'Montenegro'},
      {id: 'MONTSERRAT', nome: 'Monserratense', masculino: 'Monserratense', feminino: 'Monserratense', pais: 'Montserrat'},
      {id: 'NAMIBIA', nome: 'Namibiano(a)', masculino: 'Namibiano', feminino: 'Namibiana', pais: 'Namíbia'},
      {id: 'NAURU', nome: 'Nauruano(a)', masculino: 'Nauruano', feminino: 'Nauruana', pais: 'Nauru'},
      {id: 'NEPAL', nome: 'Nepalês(a)', masculino: 'Nepalês', feminino: 'Nepalêsa', pais: 'Nepal'},
      {id: 'NICARAGUA', nome: 'Nicaraguense', masculino: 'Nicaraguense', feminino: 'Nicaraguense', pais: 'Nicarágua'},
      {id: 'NIGER', nome: 'Nigerino(a)', masculino: 'Nigerino', feminino: 'Nigerina', pais: 'Níger'},
      {id: 'NIGERIA', nome: 'Nigeriano(a)', masculino: 'Nigeriano', feminino: 'Nigeriana', pais: 'Nigéria'},
      {id: 'NORUEGA', nome: 'Norueguês(esa)', masculino: 'Norueguês', feminino: 'Norueguesa', pais: 'Noruega'},
      {id: 'NOVA_CALEDONIA', nome: 'Neocaledónio(a)', masculino: 'Neocaledónio', feminino: 'Neocaledónia', pais: 'Nova Caledonia'},
      {id: 'NOVA_ZELANDIA', nome: 'Neozelandês(esa)', masculino: 'Neozelandês', feminino: 'Neozelandesa', pais: 'Nova Zelândia'},
      {id: 'OMA', nome: 'Omanense', masculino: 'Omanense', feminino: 'Omanense', pais: 'Omã'},
      {id: 'PAIS_DE_GALES', nome: 'Galês(esa)', masculino: 'Galês', feminino: 'Galesa', pais: 'Pais de Gales'},
      {id: 'PAISES_BAIXOS', nome: 'Neerlandês(a)', masculino: 'Neerlandês', feminino: 'Neerlandêsa', pais: 'Países Baixos'},
      {id: 'PALAU', nome: 'Palauano(a)', masculino: 'Palauano', feminino: 'Palauana', pais: 'Palau'},
      {id: 'PALESTINA', nome: 'Palestiniano(a)', masculino: 'Palestiniano', feminino: 'Palestiniana', pais: 'Palestina'},
      {id: 'PANAMA', nome: 'Panamenho(a)', masculino: 'Panamenho', feminino: 'Panamenha', pais: 'Panamá'},
      {id: 'PAPUA_NOVA_GUINE', nome: 'Papua', masculino: 'Papua', feminino: 'Papua', pais: 'Papua Nova Guiné'},
      {id: 'PAQUISTAO', nome: 'Paquistanês(a)', masculino: 'Paquistanês', feminino: 'Paquistanêsa', pais: 'Paquistão'},
      {id: 'PARAGUAI', nome: 'Paraguaio(a)', masculino: 'Paraguaio', feminino: 'Paraguaia', pais: 'Paraguai'},
      {id: 'PERU', nome: 'Peruano(a)', masculino: 'Peruano', feminino: 'Peruana', pais: 'Peru'},
      {id: 'POLINESIA_FRANCESA', nome: 'Polinésio(a)', masculino: 'Polinésio', feminino: 'Polinésia', pais: 'Polinesia Francesa'},
      {id: 'POLONIA', nome: 'Polaco(a)', masculino: 'Polaco', feminino: 'Polaca', pais: 'Polónia'},
      {id: 'PORTO_RICO', nome: 'Porto-riquenho(a)', masculino: 'Porto-riquenho', feminino: 'Porto-riquenha', pais: 'Porto Rico'},
      {id: 'PORTUGAL', nome: 'Português(a)', masculino: 'Português', feminino: 'Portuguêsa', pais: 'Portugal'},
      {id: 'CATAR', nome: 'Catariano(a)', masculino: 'Catariano', feminino: 'Catariana', pais: 'Catar'},
      {id: 'QUENIA', nome: 'Queniano(a)', masculino: 'Queniano', feminino: 'Queniana', pais: 'Quénia'},
      {id: 'QUIRGUISTAO', nome: 'Quirguiz', masculino: 'Quirguiz', feminino: 'Quirguiz', pais: 'Quirguistão'},
      {id: 'REINO_UNIDO', nome: 'Britânico(a)', masculino: 'Britânico', feminino: 'Britânica', pais: 'Reino Unido'},
      {id: 'REPUBLICA_CENTRO_AFRICANA', nome: 'Centro-africano(a)', masculino: 'Centro-africano', feminino: 'Centro-africana', pais: 'Republica Centro Africana'},
      {id: 'REPUBLICA_CHECA', nome: 'Tcheco(a)', masculino: 'Tcheco', feminino: 'Tcheca', pais: 'República Tcheca'},
      {id: 'TAIWAN', nome: 'Taiwanês(a)', masculino: 'Taiwanês', feminino: 'Taiwanêsa', pais: 'Taiwan'},
      {id: 'REPUBLICA_DEMOCRATICA_DO_CONGO', nome: 'Congolês(a)', masculino: 'Congolês', feminino: 'Congolêsa', pais: 'República Democrática do Congo'},
      {id: 'REPUBLICA_DOMINICANA', nome: 'Dominicano(a)', masculino: 'Dominicano', feminino: 'Dominicana', pais: 'República Dominicana'},
      {id: 'ROMENIA', nome: 'Romeno(a)', masculino: 'Romeno', feminino: 'Romena', pais: 'Roménia'},
      {id: 'RUANDA', nome: 'Ruandês(a)', masculino: 'Ruandês', feminino: 'Ruandêsa', pais: 'Ruanda'},
      {id: 'RUSSIA', nome: 'Russo(a)', masculino: 'Russo', feminino: 'Russa', pais: 'Rússia'},
      {id: 'SAMOA', nome: 'Samoano(a)', masculino: 'Samoano', feminino: 'Samoana', pais: 'Samoa'},
      {id: 'SAMOA_AMERICANA', nome: 'Samoense', masculino: 'Samoense', feminino: 'Samoense', pais: 'Samoa Americana'},
      {id: 'SANTA_LUCIA', nome: 'Santa-luciense', masculino: 'Santa-luciense', feminino: 'Santa-luciense', pais: 'Santa Lúcia'},
      {id: 'SAN_MARINO', nome: 'São-marinhense', masculino: 'São-marinhense', feminino: 'São-marinhense', pais: 'San Marino'},
      {id: 'SAO_CRISTOVAO_E_NEVIS', nome: 'São-cristovense', masculino: 'São-cristovense', feminino: 'São-cristovense', pais: 'Sao Cristovao e Nevis'},
      {id: 'SAO_MARTINHO', nome: 'São-martinhense', masculino: 'São-martinhense', feminino: 'São-martinhense', pais: 'Sao Martinho'},
      {id: 'SAO_TOME_E_PRINCIPE', nome: 'São-tomense', masculino: 'São-tomense', feminino: 'São-tomense', pais: 'São Tomé e Príncipe'},
      {id: 'SAO_VICENTE_E_GRANADINAS', nome: 'São-vicentino(a)', masculino: 'São-vicentino', feminino: 'São-vicentina', pais: 'São Vicente e Granadinas'},
      {id: 'SENEGAL', nome: 'Senegalês(esa)', masculino: 'Senegalês', feminino: 'Senegalesa', pais: 'Senegal'},
      {id: 'SERRA_LEOA', nome: 'Serra-leonês(a)', masculino: 'Serra-leonês', feminino: 'Serra-leonêsa', pais: 'Serra Leoa'},
      {id: 'SERVIA', nome: 'Sérvio(a)', masculino: 'Sérvio', feminino: 'Sérvia', pais: 'Sérvia'},
      {id: 'SEICHELES', nome: 'Seichelense', masculino: 'Seichelense', feminino: 'Seichelense', pais: 'Seicheles'},
      {id: 'SINGAPURA', nome: 'Singapurense', masculino: 'Singapurense', feminino: 'Singapurense', pais: 'Singapura'},
      {id: 'SIRIA', nome: 'Sírio(a)', masculino: 'Sírio', feminino: 'Síria', pais: 'Síria'},
      {id: 'SOMALIA', nome: 'Somali', masculino: 'Somali', feminino: 'Somali', pais: 'Somália'},
      {id: 'SRI_LANKA', nome: 'Cingalês(a)', masculino: 'Cingalês', feminino: 'Cingalêsa', pais: 'Sri Lanka'},
      {id: 'SUAZILANDIA', nome: 'Suazilandês(a)', masculino: 'Suazilandês', feminino: 'Suazilandêsa', pais: 'Suazilândia'},
      {id: 'SUDAO', nome: 'Sudanês(a)', masculino: 'Sudanês', feminino: 'Sudanêsa', pais: 'Sudão'},
      {id: 'SUECIA', nome: 'Sueco(a)', masculino: 'Sueco', feminino: 'Sueca', pais: 'Suécia'},
      {id: 'SUICA', nome: 'Suíço(a)', masculino: 'Suíço', feminino: 'Suíça', pais: 'Suíça'},
      {id: 'SURINAME', nome: 'Surinamês(a)', masculino: 'Surinamês', feminino: 'Surinamêsa', pais: 'Suriname'},
      {id: 'TAJIQUISTAO', nome: 'Tajique', masculino: 'Tajique', feminino: 'Tajique', pais: 'Tajiquistão'},
      {id: 'TAILANDIA', nome: 'Tailandês', masculino: 'Tailandês', feminino: 'Tailandês', pais: 'Tailândia'},
      {id: 'TAIPE_CHINESA', nome: 'Taiwanês(a)', masculino: 'Taiwanês', feminino: 'Taiwanêsa', pais: 'Taipe Chinesa'},
      {id: 'TANZANIA', nome: 'Tanzaniano(a)', masculino: 'Tanzaniano', feminino: 'Tanzaniana', pais: 'Tanzânia'},
      {id: 'TIMOR_LESTE', nome: 'Timorense', masculino: 'Timorense', feminino: 'Timorense', pais: 'Timor Leste'},
      {id: 'TOGO', nome: 'Togolês(a)', masculino: 'Togolês', feminino: 'Togolêsa', pais: 'Togo'},
      {id: 'TONGA', nome: 'Tonganês(esa)', masculino: 'Tonganês', feminino: 'Tonganesa', pais: 'Tonga'},
      {id: 'TRINIDAD_E_TOBAGO', nome: 'Trinitário(a)-tobagense', masculino: 'Trinitário-tobagense', feminino: 'Trinitária-tobagense', pais: 'Trinidad e Tobago'},
      {id: 'TUNISIA', nome: 'Tunisino(a)', masculino: 'Tunisino', feminino: 'Tunisina', pais: 'Tunísia'},
      {id: 'TURKS_E_CAICOS', nome: 'Turquense', masculino: 'Turquense', feminino: 'Turquense', pais: 'Turks e Caicos'},
      {id: 'TURQUEMENISTAO', nome: 'Turquemeno(a)', masculino: 'Turquemeno', feminino: 'Turquemena', pais: 'Turquemenistao'},
      {id: 'TURQUIA', nome: 'Turco(a)', masculino: 'Turco', feminino: 'Turca', pais: 'Turquia'},
      {id: 'TUVALU', nome: 'Tuvaluano(a)', masculino: 'Tuvaluano', feminino: 'Tuvaluana', pais: 'Tuvalu'},
      {id: 'UCRANIA', nome: 'Ucraniano(a)', masculino: 'Ucraniano', feminino: 'Ucraniana', pais: 'Ucrânia'},
      {id: 'UGANDA', nome: 'Ugandense', masculino: 'Ugandense', feminino: 'Ugandense', pais: 'Uganda'},
      {id: 'URUGUAI', nome: 'Uruguaio(a)', masculino: 'Uruguaio', feminino: 'Uruguaia', pais: 'Uruguai'},
      {id: 'UZBEQUISTAO', nome: 'Usbeque', masculino: 'Usbeque', feminino: 'Usbeque', pais: 'Uzbequistao'},
      {id: 'VANUATU', nome: 'Vanuatuense', masculino: 'Vanuatuense', feminino: 'Vanuatuense', pais: 'Vanuatu'},
      {id: 'VATICANO', nome: 'Vaticano(a)', masculino: 'Vaticano', feminino: 'Vaticana', pais: 'Vaticano'},
      {id: 'VENEZUELA', nome: 'Venezuelano(a)', masculino: 'Venezuelano', feminino: 'Venezuelana', pais: 'Venezuela'},
      {id: 'VIETNA', nome: 'Vietnamita', masculino: 'Vietnamita', feminino: 'Vietnamita', pais: 'Vietna'},
      {id: 'ZAMBIA', nome: 'Zambiano(a)', masculino: 'Zambiano', feminino: 'Zambiana', pais: 'Zâmbia'},
      {id: 'ZIMBABWE', nome: 'Zimbabuano(a)', masculino: 'Zimbabuano', feminino: 'Zimbabuana', pais: 'Zimbabwe'}
    ];
  },

  getLabelPais(idNacionalidade){
    let nacionalidades = this.getNacionalidades();
    nacionalidades = nacionalidades.filter(n => n.id === idNacionalidade);
    if(nacionalidades.length === 1){
      return nacionalidades[0].pais;
    }
    return "";
  },

  getTiposPessoa() {
    return [
      {id: 'FISICA', nome: 'Pessoa Física'},
      {id: 'JURIDICA', nome: 'Pessoa Jurídica'}
    ]
  },

  getTiposCadastro(isRI, isRTD, isRCPJ){
    let options = [];

    if(isRI){
      options.push({id: 'indicadorRI', nome: 'Indicador Pessoal RI'});
    }

    if(isRTD){
      options.push({id: 'indicadorRTD', nome: 'Indicador Pessoal RTD'});
    }

    if(isRCPJ){
      options.push({id: 'indicadorRCPJ', nome: 'Indicador Pessoal RCPJ'});
    }

    return options;
  },

  getEstadosCivil() {
    return [
      {id: 'CASADO', nome: 'Casado(a)', masculino: 'Casado', feminino: 'Casada'},
      {id: 'DIVORCIADO', nome: 'Divorciado(a)', masculino: 'Divorciado', feminino: 'Divorciada'},
      {id: 'SEPARADO', nome: 'Separado(a)', masculino: 'Separado', feminino: 'Separada'},
      {id: 'SOLTEIRO', nome: 'Solteiro(a)', masculino: 'Solteiro', feminino: 'Solteira'},
      {id: 'VIUVO', nome: 'Viúvo(a)', masculino: 'Viúvo', feminino: 'Viúva'},
      {id: 'DESCONHECIDO', nome: 'Não Informado', masculino: 'Não Informado', feminino: 'Não Informado'}
    ]
  },

  getUniaoEstavel() {
    return [
      {id: 'SIM', nome: 'Sim'},
      {id: 'NAO', nome: 'Não'},
      {id: 'DESCONHECIDO', nome: 'Não Informado'}
    ]
  },

  indicadorPessoalIsEstrangeiro(indicadorPessoalVersao){
    const tipoPessoal = indicadorPessoalVersao?.indicadorPessoal?.tipoPessoa || indicadorPessoalVersao.tipoPessoa;
    if(tipoPessoal === 'FISICA'){
      return indicadorPessoalVersao.nacionalidade !== 'BRASIL';
    }else if(tipoPessoal === 'JURIDICA') {
      return !!indicadorPessoalVersao.possuiParticipacaoEstrangeira;
    }
  },

  validarEndereco(pessoa){
    let campos = ['cidade', 'pais'];
    if(pessoa.pais == 'BRASIL'){
      campos.push('estado');
    }

    campos = campos.filter(c => {
      return Utils.isEmptyNullOrUndefined(pessoa[c])
    });

    return campos;

  },

  async validarEmail(email) {
    if (email) {
      return Http.get(`${path}/public/validar/email/${email}`);
    }
    return "E-mail inválido";
  },

  camposConsulta() {
    let options = [{id: true, nome: 'Sim'}, {id: false, nome: 'Não'}];
    return Utils.orderBy([
      {id: 'nome', nome: 'Nome', type: 'text'},
      {id: 'tipo_pessoa', nome: 'Tipo de Pessoa', type: 'list', options: this.getTiposPessoa()},
      {id: 'tipo_cadastro', nome: 'Tipo de Cadastro'},
      {id: 'livro', nome: 'Livro'},
      {id: 'documento_cpf', nome: 'CPF', type: 'cpf'},
      {id: 'documento_cnpj', nome: 'CNPJ', type: 'cnpj'},
      {id: 'rg', nome: 'RG', type: 'text'},
      {id: 'cnh', nome: 'CNH', type: 'text'},
      {id: 'cip', nome: 'Identidade Profissional', type: 'text'},
      {id: 'passaporte', nome: 'Passaporte', type: 'text'},
      {id: 'outros_documentos', nome: 'Outros Documentos', type: 'text'},
      {id: 'falecido', nome: 'Pessoa Falecida', type: 'list', options},
      {id: 'menor', nome: 'Menor de Idade', type: 'list', options},
      {id: 'incapaz', nome: 'Incapaz', type: 'list', options},
      {id: 'exposta_politicamente', nome: 'Pessoa Exposta Politicamente', type: 'list', options},
      {id: 'pessoa_obrigada', nome: 'Pessoa Obrigada', type: 'list', options},
      {id: 'servidor_publico', nome: 'Servidor Público', type: 'list', options},
      {id: 'possui_participacao_estrangeira', nome: 'Participação Estrangeira', type: 'list', options},
      {id: 'nascimento', nome: 'Data de Nascimento', type: 'date'},
      {id: 'cadastro', nome: 'Data Cadastro', type: 'date'},
      {id: 'atualizacao_manual', nome: 'Data de Alteração Manual', type: 'date'},
      {id: 'registro', nome: 'Data Registro', type: 'date'},
      {id: 'sequencial', nome: 'Sequencial', type: 'text'},
      {id: 'sexo', nome: 'Sexo', type: 'list', options: this.getSexos()},
      {id: 'nacionalidade', nome: 'Nacionalidade', type: 'text'},
      {id: 'profissao', nome: 'Profissão', type: 'text'},
      {id: 'nome_pai', nome: 'Nome do Pai', type: 'text'},
      {id: 'nome_mae', nome: 'Nome da Mãe', type: 'text'},
      {id: 'estado_civil', nome: 'Estado Civil', type: 'list', options: this.getEstadosCivil()},
      {id: 'possui_uniao_estavel', nome: 'União Estável', type: 'list', options: this.getUniaoEstavel()},
      {id: 'email', nome: 'E-mail', type: 'text'},
      {id: 'telefone', nome: 'Telefone', type: 'telefone'},
      {id: 'celular', nome: 'Celular', type: 'telefone'},
      {id: 'comercial', nome: 'Comercial', type: 'telefone'},
      {id: 'cep', nome: 'CEP', type: 'cep'},
      {id: 'logradouro', nome: 'Logradouro (rua) ', type: 'text'},
      {id: 'numero', nome: 'Número', type: 'text'},
      {id: 'quadra', nome: 'Quadra', type: 'text'},
      {id: 'lote', nome: 'Lote', type: 'text'},
      {id: 'bairro', nome: 'Bairro (setor)', type: 'text'},
      {id: 'cidade', nome: 'Cidade', type: 'text'},
      {id: 'pais', nome: 'País', type: 'list', options: this.getNacionalidades().map(n => ({id: n.id, nome: n.pais}))},
      {id: 'estado', nome: 'Estado', type: 'list', options: EnderecoBusiness.getEstados()},
      {id: 'complemento', nome: 'Complemento', type: 'text'},
      {id: 'observacao', nome: 'Observações', type: 'text'},
      {id: 'denominacao', nome: 'Nome Fantasia (Denominação)', type: 'text'},
      {id: 'inscricao_estadual', nome: 'Inscrição Estadual', type: 'text'},
      {id: 'inscricao_municipal', nome: 'Inscrição Municipal', type: 'text'},
      {id: 'natureza_juridica', nome: 'Natureza Jurídica', type: 'list', options: this.getNaturezaJuridica()},
    ], 'nome');
  },

}
