import FrontBusiness from "@/business/FrontBusiness.js";
import VueBusiness from "@/business/VueBusiness.js";
import Http from "@/commons/Http";
import moment from "moment";
import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness";
import Utils from "@/commons/Utils";

const path = 'api/protocolo';
const pathV2 = 'api/v2/ato';

const tratarAto = (a) => {
  a.tipoServico = JSON.parse(a.tipoServico || false);
  if(a.tipoServico?.opcoes && typeof a.tipoServico?.opcoes === 'string'){
    a.tipoServico.opcoes = JSON.parse(a.tipoServico.opcoes || false)
  }
  a.dto = JSON.parse(a.dto || false) || {};
  a.doi = JSON.parse(a.doi || false) || {};
  a.ressarcimento = JSON.parse(a.ressarcimento || false) || {};

  a.envolvidos = typeof a?.envolvidos === 'string' ? JSON.parse(a?.envolvidos || false) || [] : a?.envolvidos;
  (a.envolvidos || []).forEach(envolvido => {
    envolvido.exigencias = JSON.parse(envolvido?.exigencias || false);
    envolvido.indices = JSON.parse(envolvido?.indices || false);
  });
  a.custas = JSON.parse(a?.custas || false);
  a.checklist = JSON.parse(a?.checklist || false);
  a.indices = JSON.parse(a?.indices || false);
  a.exigencias = JSON.parse(a?.exigencias || false);

  a.indicador = JSON.parse(a.indicador || false);
  (a.indicador?.id) ? '' : a.indicador = undefined;

  a.registroAuxiliar = JSON.parse(a.registroAuxiliar || false);
  (a.registroAuxiliar?.id) ? '' : a.registroAuxiliar = undefined;

  a.indicadorReal = JSON.parse(a.indicadorReal || false);
  (a.indicadorReal?.id) ? '' : a.indicadorReal = undefined;

  a.ficha = a.indicadorReal || a.registroAuxiliar || a.indicador;

  return a;

};

export default {

  descricaoVencimentoRestricao(vencimento){
    const labelVencimento = vencimento && Object.keys(vencimento).length > 0 && vencimento.constructor !== Object ? ` com vencimento no dia ${moment(vencimento).format('DD/MM/YYYY')}` : '';
    return labelVencimento;
  },

  descricaoAtoRestricao(restricao) {
    return restricao.descricao != null ? restricao.descricao : restricao.codigo + ' - ' + restricao.natureza;
  },

  descricaoAtoRestricaoCompleta(restricao, ficha){
    let codigo = this.getCodigo(restricao, ficha, restricao?.tipoAto)
    return codigo + " - " + (restricao.titulo || restricao.tipoServico || '') + this.descricaoVencimentoRestricao(restricao.vencimento);
  },

  gerarAtoPadrao(servico, prenotacao = 'NORMAL'){
    return {
      envolvidos : [],
      tipoServico : {id: servico},
      dto : {
        doi : {},
        indicador : {},
        camposAdicionais : {},
        valoresBaseCalculo : {},
      },
      tipoRegistro:prenotacao
    }
  },

  async listarAtos(protocolo){
    return Http.get(`${path}/${protocolo}/ato/atos`).then(l => l.map(tratarAto));
  },

  async listarIdsAtos(protocolo){
    return Http.get(`${path}/${protocolo}/ato/listar-ids`);
  },

  async listarAtosProtocolo(protocolo){
    return Http.get(`${path}/${protocolo}/ato/atos-protocolo`).then(l => l.map(tratarAto));
  },

  async restricoesSemRegistrar(protocolo, atoId, indicador){
    if (!atoId && indicador) return Http.get(`${path}/${protocolo}/ato/atos-restricao/null/${indicador}`);
    return Http.get(`${path}/${protocolo}/ato/atos-restricao/${atoId}`);
  },

  async restricoesSemRegistrarRegistroAuxiliar(protocolo, atoId, registroAuxiliar){
    if (!atoId && registroAuxiliar) return Http.get(`${path}/${protocolo}/ato/atos-restricao/registroAuxiliar/null/${registroAuxiliar}`);
    return Http.get(`${path}/${protocolo}/ato/atos-restricao/registroAuxiliar/${atoId}`);
  },

  async listarPorFicha(protocolo, livro, id, simplificado){

    if(!['MATRICULA', 'TRANSCRICAO', 'REGISTRO_AUXILIAR', 'INDICADOR-REAL', 'REGISTRO-AUXILIAR'].includes(livro.toUpperCase())){
      livro = 'indicador';
    }

    return Http.get(`${path}/${protocolo}/ato/atos/${livro}/${id}${simplificado ? '/simplificado' : ''}`)
      .then(l => l.map(tratarAto));
  },

  async getItensPorId(ids) {
    return await Http.post(`${path}/0/ato/ids`, ids).then(l => l.map(tratarAto));
  },

  async getItensPorIdSimplificado(ids) {
    return await Http.post(`${path}/0/ato/ids/simplificado`, ids).then(l => l.map(tratarAto));
  },

  async getRestricoesPorId(ato, ids) {
    return await Http.post(`${path}/0/ato/${ato}/restricoes`, ids);
  },

  async getById(protocolo, id){
    if(!Utils.testUUID(id)){
      return Promise.reject();
    }
    return Http.get(`${path}/${protocolo}/ato/${id}`).then(a => {
      a.ficha = a.indicadorReal || a.registroAuxiliar || a.indicador;
      return a;
    });
  },

  async getEnvolvidos(protocolo){
    return Http.get(`${path}/${protocolo}/ato/envolvidos`);
  },

  async getEnvolvidosConjugesProtocolo(protocolo){
    return Http.get(`${path}/${protocolo}/ato/envolvidos-conjuges`);
  },

  async delete(protocolo, id){
    return Http.delete(`${path}/${protocolo}/ato/${id}`);
  },

  async calcularCustoProtocolo(protocolo){
    return Http.post(`${path}/${protocolo}/ato/calcular-custo-protocolo`);
  },

  async save(dto, protocolo, id = ''){
    return Http.post(`${path}/${protocolo}/ato/${id}`, dto);
  },

  async verificarEnvolvidosAtos(dto, protocolo, id = ''){
    return Http.post(`${path}/${protocolo}/ato/${id}/verificar-envolvidos-atos`, dto);
  },

  async ajustarEnvolvidosAtos(dto, protocolo, id = ''){
    return Http.post(`${path}/${protocolo}/ato/${id}/ajustar-envolvidos-atos`, dto);
  },

  async salvarIndices(protocolo, id = '', dto){
    return Http.post(`${path}/${protocolo}/ato/${id}/indices`, dto);
  },

  async imprimir(id, impresso){
    return Http.post(`${path}/0/ato/${id}/impressao/${impresso}`);
  },

  async marcarAtosImpressos(livro, id, impresso){
    return Http.post(`${path}/0/ato/impressao/${livro}/${id}/${impresso}`);
  },

  async marcarImpressosLote(livro, impresso, dto){
    return Http.post(`${path}/0/ato/impressao-lote/${livro}/${impresso}`, dto);
  },

  async inserirLote(protocolo, dto) {
    return Http.post(`${path}/${protocolo}/ato/lote`, dto);
  },

  async inserirLoteArquivo(protocolo,file,dto) {

    const formData = new FormData();
    formData.append("file", file);
    formData.append("dto", JSON.stringify(dto));

    let config = {
      timeout: 0,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    return Http.post(`${path}/${protocolo}/ato/lote-arquivo`, formData, config);
  },

  async criarRegistroAuxiliar(protocolo, dto) {
    return Http.post(`${path}/${protocolo}/ato/criar-registro-auxiliar`, dto);
  },

  async ordenarAtos(protocolo, atos) {
    return Http.post(`${path}/${protocolo}/ato/ordenar`, atos);
  },

  async regerarChecklist(protocolo){
    return Http.post(`${path}/${protocolo}/ato/checklist`);
  },

  async validar(id, dto) {
    return Http.post(`${path}/0/ato/${id}/validar`, dto);
  },

  async desvalidar(id, dto) {
    return Http.post(`${path}/0/ato/${id}/desvalidar`, dto);
  },

  async validarLote(ids) {
    return Http.post(`${path}/0/ato/validar-lote`, ids);
  },

  async validarRegistrados(id) {
    return Http.post(`${path}/${id}/ato/validar-registrados`);
  },

  async desvalidarLote(ids) {
    return Http.post(`${path}/0/ato/desvalidar-lote`, ids);
  },

  async recolhimentos() {
    return Http.get(`${path}/0/ato/recolhimentos`);
  },

  async copiarCNIB(protocolo, envolvido){
    return Http.post(`${path}/${protocolo}/ato/copiar-cnib`, envolvido);
  },

  async paginationV2(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/0/ato/v2/pagination?sort=${sort || 'a.registro'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async copiarAtoOutroProtocolo(protocolo, dto){
    return Http.post(`${path}/${protocolo}/ato/copiar-ato-outro-protocolo`, dto);
  },

  async editarDataSelagem(dto, protocolo){
    return Http.post(`${path}/${protocolo}/ato/atualizar-data-selagem`, dto);
  },

  gerarCodigoSimplificado(ordem, inicio, tipo, statusficha = 'PENDENTE') {
    try{

      if (tipo == 'ABERTURA') {
        return 0;
      }

      ordem = (ordem + '.0.').split('.')[1].padEnd(4, '0');
      return  parseInt(inicio, 10) + (parseInt(ordem, 10) + (ordem > 1 && statusficha == 'PENDENTE' ? -1 : 0));

    }catch (e) {
      console.error(e);
      return '';
    }

  },

  getCodigo(ato, ficha, tipo, simplificado = false, atos = []) {
    try{

      ficha = ficha || ato.ficha;
      tipo = tipo || ato.tipo;

      if (tipo == 'ABERTURA') {
        return simplificado ? '0' : ficha?.livro == 'REGISTRO_AUXILIAR' ? 'RA.0': 'M.0';
      }

      let codigo;

      if(ato.codigo || ato.codigoAto || ato.ato){
        codigo = ato.codigo || ato.codigoAto || ato.ato;
        if (ato.tipo) tipo = ato.tipo;
      }else{

        let ultimo = -1;

        if(ficha?.status != 'PENDENTE' || VueBusiness.getVueRoot()?.config?.indicadorReal?.permitirRegistroPreMatricula) {
          ultimo = ficha?.ultimoAto || 0;

          if(atos?.find(ato => (ato.tipo || ato.tipoServico?.opcoes?.tipoAto) === 'ABERTURA' && !ato.registro)){
            ultimo = -1;
          }
        }

        let registrados = atos?.filter(a => a.registro && (ato.tipo || ato.tipoServico?.opcoes?.tipoAto) !== 'ABERTURA')?.length;
        let ordem = (ato.ordem?.toFixed(4) + '.0.').split('.')[1].padEnd(4, '0');
        codigo = parseInt(ultimo, 10) + (parseInt(ordem, 10)) - parseInt(registrados, 10);// + (ficha?.status === 'PENDENTE' && ordem > 1 ? -1 : 0));
      }

      if(simplificado){
        return codigo;
      }

      let prefix = tipo ? `${tipo == 'REGISTRO' ? 'R' : 'Av'}.` : '';
      return `${prefix}${FrontBusiness.formatarCodigo(parseInt(codigo, 10))}`;

    }catch (e) {
      console.error(e);
      return '';
    }

  },

  formatarValorCusta(ato, protocolo, servico = ''){

    if(ato.isento && !ato.valor || (servico !== 'certidao' && protocolo?.tipoRegistro === 'ISENTO' && !!ato.isento )){
      if(servico === 'certidao'){
        return 'Isenta';
      }else{
        return 'Isento';
      }
    }

    if(!ato.registro && !ato.valor && (!ato.custas || ato.custas.erro)){
      return '-';
    }

    return Number(ato.valor).toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      style: 'decimal',
      currency: 'BRL'
    });

  },

  camposBaseCalculo(){
    return Object.freeze([
      {id: 'ALIENACAO', nome: 'Negócio/Alienação', possuiAtualizacao: true, label: 'Valor do Negócio/Alienação', key: 'alienacao'},
      {id: 'AVALIACAO', nome: 'Avaliação', possuiAtualizacao: true, label: 'Valor de Avaliação Fiscal', key: 'valorAvaliacao', help: '(ITBI/ITCD)'},
      {id: 'DIVIDA', nome: 'Dívida', possuiAtualizacao: true, label: 'Valor da Dívida', key: 'valorDivida'},
      {id: 'IMOVEL', nome: 'Imóvel', possuiAtualizacao: false, label: 'Valor Atribuído ao Imóvel', key: 'valorImovel'},
      {id: 'MERCADO', nome: 'Mercado', possuiAtualizacao: false, label: 'Valor de Mercado', key: 'valorMercado'},
      {id: 'VENAL', nome: 'Venal', possuiAtualizacao: false, label: 'Valor Venal', key: 'valorVenal'},
      {id: 'MEACAO', nome: 'Meação', possuiAtualizacao: false, label: 'Valor de Meação', key: 'valorMeacao'},
      {id: 'LEILAO', nome: 'Leilão', possuiAtualizacao: false, label: 'Valor de Leilão', key: 'valorLeilao'},
      {id: 'RECURSOS_PROPRIOS', nome: 'Recursos Próprios', possuiAtualizacao: false, label: 'Recursos Próprios', key: 'recursosProprios'},
      {id: 'FGTS', nome: 'FGTS', possuiAtualizacao: false, label: 'FGTS', key: 'fgts'},
      {id: 'FGTS_DESCONTO', nome: 'FGTS (Desconto)', possuiAtualizacao: false, label: 'FGTS (Desconto)', key: 'fgtsDesconto'},
      {id: 'VTI', nome: 'Valor Total do Imóvel (VTI)', possuiAtualizacao: false, label: 'Valor Total do Imóvel (VTI)', key: 'vti'},
      {id: 'VTN', nome: 'Valor da Terra Nua (VTN)', possuiAtualizacao: false, label: 'Valor da Terra Nua (VTN)', key: 'vtn'},
    ]);
  },

  getEnvolvidosEstrangeiros(envolvidos = []){
    const envolvidosEstrangeiros = [];
    envolvidos?.forEach(e => {
      if(e.papel === 'ADQUIRENTE' && IndicadorPessoalBusiness.indicadorPessoalIsEstrangeiro(e.indicadorPessoalVersao || e)){
        envolvidosEstrangeiros.push(e);
      }
    });
    return envolvidosEstrangeiros;
  },

  mostrarCampoLivroEstrangeiro(ato){
    if(ato?.tipoServico?.opcoes?.inserirLivroEstrangeiro === false && !ato.registro){
      return false;
    }

    if((ato?.ficha?.categoria === 'RURAL' && this.getEnvolvidosEstrangeiros(ato.envolvidos).length > 0) || (ato.registro && ato.id && ato.livroEstrangeiro > 0)){
      return true;
    }

    return false;
  },

  async excluirAtos(atos, verificar = true, registrados = true) {
    let continua = [];
    if (verificar && atos.find(a => a.registro)) continua = await this.verificarAlertasExclusao(atos);
    if (continua.length) return continua;
    if(atos.length) {
      let promises = atos.map(ato => this.delete(0, ato.id)
          .catch(e => {
            FrontBusiness.showError('', `${e?.message}: ${this.getCodigo(ato, null, ato?.tipoServico?.opcoes?.tipoAto)}` || 'Ocorreu um erro ao tentar excluir o ato')
          }));
      return await Promise.all(promises)
    }
  },

  async verificarAlertasExclusao(atos) {
    let continua = [];
    let ids = (atos || []).map(f => f.id);
    let verificacao = await this.verificarAlertasExclusaoAto(ids);
    if (verificacao) continua = await this.confirmarExclusaoAto(atos, verificacao, 'checkbox');
    return continua;
  },

  async confirmarExclusaoAto(atos, title, input = null) {
    let result = await FrontBusiness.showConfirmacao(title, `Esta ação excluirá o${atos.length > 1 ? 's' : ''} ato${atos.length > 1 ? 's' : ''}. Deseja Continuar?`, input, 'Tenho certeza que desejo continuar.', 'Observações: As alterações que ocorreram no imóvel não serão desfeitas, ou seja, se o ato alterou proprietário ou algum dado do imóvel, após excluir o ato, as alterações não serão desfeitas.').then(async r => {
      if (r.value) {
        return await this.excluirAtos(atos, false);
      } else if (r.dismiss === 'cancel') {
        return [false];
      } else {
        return [false];
      }
    }).catch(this.showError);

    return result
  },

  async verificarAlertasExclusaoAto(ids){
    return await Http.post(`${path}/0/ato/verificar-alertas-exclusao-ato`, ids);
  },

  getValoresCalculadora(protocolo, tipoServico){
    return Http.get(`${pathV2}/calculadora-divida/${protocolo}/${tipoServico}`);
  },

  atualizarValoresDivida(protocolo, tipoServico, valores){
    return Http.post(`${pathV2}/calculadora-divida/${protocolo}/${tipoServico}`, valores);
  },

  async salvarTextoLote(protocolo, dto){
    return await Http.post(`${path}/${protocolo}/ato/texto-lote`, dto);
  },

  async editarTipoServico(protocolo, id, tipoServico) {
    return Http.get(`${path}/${protocolo}/ato/${id}/editar-tipo-servico/${tipoServico}`);
  },


  async tratarAlteracaoAtoDigital(ato, atos) {
    let marcando = !ato.digital;
    let ids = [];
    if (marcando) {
      ids = atos.filter(a => a.codigo > ato.codigo && a.digital !== marcando).map(a => a.id);
    } else {
      ids = atos.filter(a => a.codigo < ato.codigo && a.digital !== marcando).map(a => a.id);
    }
    ids.push(ato.id);
    await this.alterarAtoDigital(ato.protocoloId, ids, marcando)
      .catch(error => {
        FrontBusiness.showError('', error.message);
      });
  },

  async alterarAtoDigital(protocolo, listaId, status){
    return await Http.post(`${path}/${protocolo}/ato/alterar-ato-digital/${status}`, listaId);
  },

  async marcarAtoDigital(protocolo, ids){
    return await Http.post(`${path}/${protocolo}/ato/marcar-ato-digital`, ids);

  }

}
