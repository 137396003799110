<template>
  <modal titulo="Consultar Selo" id="consulta-selo">

    <card v-if="!consultarSeloId">
      <v-input v-model="form.codigo" :small="true" type="text" title="Código" @enter="consultar()"
               :label="$root.config.estado == 'SC' ? 'Informe o código sem os dígitos verificadores' : ''">
        <span class="input-group-append">
          <v-button :run="consultar" class="btn-outline-primary ml-auto">Consultar</v-button>
        </span>
      </v-input>
    </card>

    <small-loading v-if="loading" />

    <card v-if="consultado" :titulo="`${editando ? 'Edição das i' : 'I'}nformações do selo no cartório`">
      <template #acoes>
        <a class="icon float-right pointer mr-2" href @click.prevent="editarDetalhes()" v-if="permissaoEdicao && !auditoria">
          EDITAR
        </a>
        <a class="icon float-right pointer mr-2" href @click.prevent="showAuditorias()" v-if="!editando && !auditoria">
          AUDITORIA
        </a>
      </template>
      <auditoria-pagination v-if="auditoria" :referencia="seloBase.id" :entidade="`${entidade}`" :formatters="formatters" />
      <div v-if="seloBase && !editando && !auditoria">

        <div class="alert alert-sm alert-outline-danger mb-3" v-if="seloBase.tentativa && seloBase.erro">
          <p><span class="font-weight-500">Erro na tentativa de Envio: </span> {{seloBase.tentativa | datahora}}</p>
          <p v-html="seloBase.erro"></p>
        </div>

        <p v-if="seloBase.ignorar"><span class="text-danger font-weight-500">Este selo foi marcado para ser ignorado no envio</span></p>
        <p v-if="seloBase.retificar"><span class="text-danger font-weight-500">Este selo foi marcado para ser retificado</span></p>

        <p><span class="font-weight-500">Código Selo: </span><span>{{seloBase.codigo}} <a v-if="linkTj" :href="linkTj" target="_blank"><i class="fas fa-external-link-alt"></i></a></span></p>
        <p v-if="seloBase.codigoPai"><span class="font-weight-500">Código Pai: </span><span>{{seloBase.codigoPai}}</span></p>
        <p v-if="seloBase.codigoValidador"><span class="font-weight-500">Dígitos Verificadores: </span><span>{{seloBase.codigoValidador}}</span></p>
        <p v-if="seloBase.codigoFiscal"><span class="font-weight-500">Código Fiscal: </span><span>{{seloBase.codigoFiscal}}</span></p>
        <p v-if="seloBase.codigoAto"><span class="font-weight-500">Código do Ato: </span><span>{{seloBase.codigoAto}}</span></p>
        <p><span class="font-weight-500">Situação: </span><span>{{seloBase.status}}</span></p>
        <p><span class="font-weight-500">Data de Utilização: </span><span>{{seloBase.utilizacao | datahora}}</span></p>
        <p><span class="font-weight-500">Usuário Utilização: </span><span>{{seloBase.usuario}}</span></p>
        <p v-if="seloBase.protocolo">
          <span class="font-weight-500">Protocolo: </span><span>
            <router-link :to="routerLink" class="icon" target="_blank" @click.native="$event.stopImmediatePropagation()">
              {{formatarCodigo(seloBase.protocolo.codigo)}}
            </router-link>
          </span>
        </p>
        <p v-if="seloBase.taxaJudiciaria"><span class="font-weight-500">Taxa Judiciária: </span><span>{{seloBase.taxaJudiciaria | moeda}}</span></p>

        <p><span class="font-weight-500">Emolumento: </span><span>{{seloBase.emolumentos | moeda}}</span></p>
        <p><span class="font-weight-500">Enviado: </span><span>{{tratarBoolean(seloBase.enviado)}}</span></p>

        <p v-if="seloBase.enviado"><span class="font-weight-500">Data de Envio: </span><span>{{seloBase.envio | datahora}}</span>
        <p v-if="seloBase.cancelamento"><span class="font-weight-500">Data de Cancelamento: </span><span>{{seloBase.cancelamento | datahora}}</span>
        <p v-if="seloBase.motivoCancelamento"><span class="font-weight-500">Motivo: </span><span>{{seloBase.motivoCancelamento}}</span>
        <p v-if="seloBase.usuarioCancelamento"><span class="font-weight-500">Usuário Cancelamento: </span><span>{{seloBase.usuarioCancelamento}}</span></p>

        <p v-if="seloBase.lote && seloBase.lote.identificacao"><span class="font-weight-500">Identificação Lote: </span><span>{{seloBase.lote.identificacao}}</span></p>

        <p v-if="seloBase.retificado"><span class="text-success font-weight-500">Este selo retificou o selo: </span><span>{{seloBase.retificado}}</span></p>
        <p v-if="seloBase.retificador"><span class="text-danger font-weight-500">Este selo foi retificado pelo selo: </span><span>{{seloBase.retificador}}</span></p>

        <p v-if="seloBase.atualizacao"><span class="font-weight-500">Data de Modificação: </span><span>{{seloBase.atualizacao | datahora}}</span></p>

      </div>
      <div v-else-if="seloBase && editando">
        <div class="row">
          <div class="col-12">
            <div v-if="!seloBase.enviado">
              <label>Protocolo</label>
              <buscar-protocolos :multiple="false" @selecionar="selecionar($event)"
                                 :tipoProtocoloSimples="false" ref="buscarProtocolos" v-if="!dto.protocolo"/>
              <div class="row gutters-xs" v-else>
                <v-input class="col form-group">
                  <input class="form-control form-control-xs" readonly type="text" v-model="dto.protocolo.codigo"/>
                </v-input>
                <div class="col-auto">
                  <button :disabled="false" @click="selecionar(null)"
                          class="btn btn-outline-secondary btn-xs" title="Remover" type="button">
                    <i class="fal fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="row" style="display: flex; align-content: flex-start;">
              <v-input type="datetime-local" label="Data de Utilização" :error="validation.utilizacao" v-model="dto.utilizacao" :required="true" class="col-4"/>
              <div class="form-group mb-0" style="padding-top: 30px;">
                <input  type="checkbox" id="FLAG_ENVIADO" v-model="dto.enviado" :default="false" :disabled="true"/>
                <label for="FLAG_ENVIADO"  class="pointer ml-2">
                  Enviado?
                </label>
              </div>
              <v-input type="datetime-local" label="Data de Envio" :error="validation.envio" v-model="dto.envio" v-if="dto.enviado" class="col-4"/>
            </div>
          </div>
        </div>
        <card v-if="dto.protocolo && !seloBase.enviado" class="m-1" titulo="Vinculado a">
          <div class="row">
            <v-input :disabled="tipoVinculo && tipoVinculo !== 'ato'" type="radio" v-if="options.atos.length > 1" :options="options.atos" class="col-12"
                     label="Ato" v-model="dto.ato" />
            <v-input :disabled="tipoVinculo && tipoVinculo !== 'certidao'" type="radio" v-if="options.certidoes.length > 1" :options="options.certidoes" class="col-12"
                     label="Certidão" v-model="dto.certidao" />
            <v-input :disabled="tipoVinculo && tipoVinculo !== 'protocoloCustaAdicional'" type="radio" v-if="options.custasAdicionais.length > 1" :options="options.custasAdicionais" class="col-12"
                     label="Custa Adicional" v-model="dto.protocoloCustaAdicional" />
            <v-input :disabled="tipoVinculo && tipoVinculo !== 'protocoloPrenotacao'" type="radio" v-if="options.prenotacoes.length > 1" :options="options.prenotacoes" class="col-12"
                     label="Prenotação" v-model="dto.protocoloPrenotacao" />
          </div>
        </card>
        <div class="row" style="display: flex; align-content: flex-start;">
          <v-input v-if="['GO', 'MG'].includes($root.config.estado) && dto.protocolo && !seloBase.enviado" type="text" label="Selo pai" :error="validation.codigoPai" v-model="dto.codigoPai" :required="true" class="col"/>
        </div>
      </div>
      <div v-else>
        <p class="alert alert-warning alert-info" v-if="!auditoria">
          Selo não encontrado na base do cartório
        </p>
      </div>
    </card>

<!--    <card v-if="consultado && $root.config.estado == 'GO'" titulo="Informações do selo no TJGO">-->

<!--      <card-content v-if="seloTj">-->
<!--        <p><span class="font-weight-500">Protocolo Pedido: </span><span>{{seloTj.protocolo_pedido}}</span></p>-->
<!--        <p><span class="font-weight-500">Tipo Ato: </span><span>{{seloTj.tipo_ato_id}}</span></p>-->
<!--        <p><span class="font-weight-500">Código Ato: </span><span>{{seloTj.codigo_ato}}</span></p>-->
<!--        <p><span class="font-weight-500">Código Selo: </span><span>{{seloTj.codigo_selo}}</span></p>-->

<!--        <p><span class="font-weight-500">Taxa Judiciária: </span><span>{{seloTj.taxa_judiciaria | moeda}}</span>-->
<!--        </p>-->
<!--        <p><span class="font-weight-500">Emolumento: </span><span>{{seloTj.emolumento | moeda}}</span></p>-->
<!--        <p><span class="font-weight-500">FUNDESP: </span><span>{{seloTj.fundesp | moeda}}</span></p>-->
<!--        <p><span class="font-weight-500">Utilização: </span><span>{{seloTj.data_hora_utilizacao | datahora}}</span>-->
<!--        </p>-->
<!--        <p><span class="font-weight-500">Parte: </span><span>{{seloTj.nome_de_uma_parte_no_ato}}</span></p>-->
<!--        <p><span class="font-weight-500">Inutilizado: </span><span>{{tratarBoolean(seloTj.inutilizado)}}</span></p>-->
<!--        <p v-if="seloTj.inutilizado"><span class="font-weight-500">Justificativa Inutilização: </span><span>{{seloTj.justificativa_inutilizacao}}</span>-->
<!--        </p>-->
<!--        <p>-->
<!--          <span class="font-weight-500">Retorno: </span><span>{{seloTj.data_retorno | datahora}}</span>-->
<!--        </p>-->

<!--        <card-table v-if="seloTj.vinculados && seloTj.vinculados.length > 0" class="mt-3">-->
<!--          <thead>-->
<!--            <tr>-->
<!--              <th>Tipo Ato</th>-->
<!--              <th>Código Ato</th>-->
<!--              <th>Código Selo</th>-->
<!--              <th>Utilização</th>-->
<!--            </tr>-->
<!--          </thead>-->

<!--          <tbody>-->
<!--            <tr v-for="vinculado in seloTj.vinculados" :key="vinculado.id">-->
<!--              <td>{{vinculado.tipo_ato_id}}</td>-->
<!--              <td>{{vinculado.codigo_ato}}</td>-->
<!--              <td>{{vinculado.codigo_selo}}</td>-->
<!--              <td>{{seloTj.data_hora_utilizacao | datahora}}</td>-->
<!--            </tr>-->
<!--          </tbody>-->

<!--        </card-table>-->
<!--      </card-content>-->

<!--      <div v-else>-->
<!--        <p class="alert alert-warning alert-info">-->
<!--          Selo não encontrado no TJGO-->
<!--        </p>-->
<!--      </div>-->
<!--    </card>-->

    <template #footer>

      <template v-if="!editando && !auditoria">

        <button type="button" @click.prevent="openRetificarSelo()" class="btn btn-outline-danger"
                v-if="consultado && showRetificar && seloBase.status !== 'PENDENTE'">Retificar</button>

        <v-button :run="baixarXML" class="btn btn-outline-primary ml-auto" :popover="true"
                  v-if="consultado && !['GO'].includes($root.config.estado)">Gerar XML</v-button>

        <v-button :run="reativar" class="btn-outline-info ml-2" :popover="true"
                  v-if="showReativar">Reativar Selo</v-button>

        <v-button :run="desfazerUtilizacao" class="btn-outline-danger ml-2" :popover="true"
                  v-if="showDesfazerUtilizacao" title="Tornar o selo utilizável novamente.">Desfazer Utilização</v-button>

        <v-button :run="cancelar" class="btn-outline-danger ml-2" :popover="true"
                  v-if="showCancelar">{{tituloCancelar}}</v-button>

      </template>

      <template v-if="auditoria">
        <button type="button" @click.prevent="showAuditorias()" class="btn btn-outline-secondary mr-auto">Voltar</button>
      </template>

      <template v-if="editando">
        <button type="button" @click.prevent="cancelarEdicao()" class="btn btn-outline-secondary mr-auto">Cancelar</button>
        <v-button class="btn-outline-primary" :run="salvarDetalhes">Salvar</v-button>
      </template>

    </template>

  </modal>
</template>

<script>
  import FrontBusiness from "@/business/FrontBusiness.js";
  import AtoBusiness from "@/business/protocolo/AtoBusiness.js";
  import CertidaoBusiness from "@/business/protocolo/CertidaoBusiness.js";
  import ProtocoloBusiness from "@/business/protocolo/ProtocoloBusiness.js";
  import SeloBusiness from "@/business/SeloBusiness.js";
  import TabelaCustaBusiness from "@/business/TabelaCustaBusiness.js";
  import Utils from "@/commons/Utils.js";
  import BuscarProtocolos from "@/components/BuscarProtocolos.vue";
  import Card from "@/components/Layout/components/Card.vue";
  import CardContent from "@/components/Layout/components/CardContent.vue";
  import CardTable from "@/components/Layout/components/CardTable.vue";
  import Modal from "@/components/Modal";
  import SmallLoading from "@/components/SmallLoading.vue";
  import ModalRetificarSelo from "@/views/Administracao/modulos/selo/SC/ModalRetificarSelo.vue";
  import ModalCancelarSelo from "@/views/Administracao/modulos/selo/GO/ModalCancelarSelo";
  import AuditoriaPagination from "@/v2/components/Auditoria/AuditoriaPagination";
  import AuditoriaBusiness from "@/business/AuditoriaBusiness";
  import TipoServicoBusiness from "@/business/crud/TipoServicoBusiness";
  import ProtocoloCustaAdicionalBusiness from "@/business/protocolo/ProtocoloCustaAdicionalBusiness";
  import EventBus from "@/commons/EventBus";



  export default {
    name: "ModalConsultaSelo",
    components: {SmallLoading, CardTable, Card, CardContent, Modal, BuscarProtocolos, AuditoriaPagination},

    props: {
      consultarSeloId: String,
      consultarSelo: String,
      localSelo: {type: Boolean, default: () => false},
      entidade: {type : String, default : 'SELO'}
    },
      data() {
        return {
          loading: false,
          seloCancelamento: [],
          consultado: false,
          seloTj: null,
          seloBase: null,
          seloEnviado: true,
          motivoCancelamento: null,
          form: {codigo: null},
          dto: {},
          editando: false,
          auditoria: false,
          options: {
            atos: [],
            certidoes: [],
            custasAdicionais: [],
            prenotacoes: [],
          },
          validation: {}
        }
      },

      computed: {
        routerLink() {
          if (this.seloBase.ato) {
            return { name: 'protocolo-atos', params:{id : this.seloBase.protocolo.id }, query : { tipo : 'ato', id: this.seloBase.ato} };
          }
          if (this.seloBase.protocoloCustaAdicional) {
            return { name: 'protocolo-atos', params:{id : this.seloBase.protocolo.id }, query : { tipo : 'custa-adicional', id: this.seloBase.protocoloCustaAdicional} };
          }
          if (this.seloBase.certidao) {
            return { name: 'protocolo-certidoes', params:{id : this.seloBase.protocolo.id }, query : { id: this.seloBase.certidao} };
          }
          return {name: 'protocolo-atos', params: {id: this.seloBase.protocolo.id}};
        },

        showReativar() {
          return this.consultado && this.$root.config.estado !== 'GO' &&
            this.seloBase?.status === 'CANCELADO' && this.$root.getPermission({id: 'PERMITIR_REATIVAR_SELO'});
        },

        showRetificar(){
          return ['SC', 'SP', 'PR'].includes(this.$root.config.estado)
        },

        showDesfazerUtilizacao() {
          return this.consultado && this.seloBase?.status === 'USADO' && !this.seloBase?.enviado && this.$root.getPermission({id: 'PERMITIR_DESFAZER_UTILIZACAO_SELO'});
        },

        showCancelar() {
          return this.consultado && this.seloBase?.status === 'USADO' && this.seloBase?.enviado && this.$root.getPermission({id: 'PERMITIR_CANCELAR_SELO'});
        },

        tituloCancelar() {
          return this.$root.isEstadoPR ? 'Anular Selo' : 'Cancelar Selo';
        },

        usuario() {
          return this.$root.usuarioLogado;
        },

        permissaoEdicao(){
          return this.seloBase && !this.editando && this.$root.getPermission({id: 'PERMITIR_EDITAR_SELO'});
        },

        tipoVinculo() {
          if (this.dto.ato) {
            return 'ato'
          }
          if (this.dto.certidao) {
            return 'certidao'
          }
          if (this.dto.protocoloCustaAdicional) {
            return 'protocoloCustaAdicional'
          }
          if (this.dto.protocoloPrenotacao) {
            return 'protocoloPrenotacao'
          }
          return null;
        },

        linkTj() {

          if(this.seloBase?.urlAcompanhamento){
            return this.seloBase.urlAcompanhamento;
          }

          if (this.seloBase?.codigo && this.$root.isEstadoSC) {
            return `http://selo.tjsc.jus.br/consulta/ato!qrcode?nuselo=${this.seloBase.codigo}-${this.seloBase.codigoValidador}`;
          }

          if (this.consultado && this.$root.isEstadoGO) {
            return `https://extrajudicial.tjgo.jus.br/verificacao/${this.seloBase.codigo}`;
          }

          if (this.consultado && this.$root.isEstadoMG) {
            return `https://selos.tjmg.jus.br/sisnor/eselo/consultaSeloseAtos.jsf?selo=${this.seloBase.codigo}&codigo=${this.seloBase.codigoValidador}`;
          }

          if (this.consultado && this.$root.isEstadoPE) {
            return `https://www.tjpe.jus.br/sicase/externo/autenticidadeselo/form_validarautenticidadeselo.jsf?hash=${this.seloBase.codigoValidador}`;
          }

          return null;
        }

      },

      mounted() {
        if(this.consultarSeloId){
          this.consultar();
        }else if (this.consultarSelo) {
          this.form.codigo = this.consultarSelo;
          this.consultar();
        }
      },

      methods: {
        formatarCodigo: FrontBusiness.formatarCodigo,

        async consultar() {
          this.editando = false;
          this.loading = true;

          if(!this.form.codigo && Utils.testUUID(this.consultarSeloId)){
            this.seloBase = await SeloBusiness.consultarSelo(this.consultarSeloId).catch(e => {
              FrontBusiness.showError('', e.message);
              return null;
            });
          }else{
            this.seloBase = await SeloBusiness.consultarBase(this.form.codigo).catch(e => {
              FrontBusiness.showError('', e.message);
              return null;
            });
          }

          this.consultado = true;
          this.loading = false;
        },

        tratarBoolean(valor) {
          return !valor ? 'Não' : 'Sim';
        },

        async openRetificarSelo() {
          await FrontBusiness.openModal(ModalRetificarSelo, {selo: this.seloBase});
          this.consultar();
        },

        async reativar() {
          await SeloBusiness.reativar(this.seloBase.id);
          await this.consultar();
        },

        async cancelar() {
          this.seloCancelamento.push(this.seloBase.codigo);
          await FrontBusiness.openModal(ModalCancelarSelo, {
            codigos: this.seloCancelamento
          });
          await this.consultar();
          if (this.localSelo) {
            FrontBusiness.reload();
          }

        },

        async desfazerUtilizacao() {
          await SeloBusiness.desvincularSelo(this.seloBase.id);
          await this.consultar();
          if (this.localSelo) {
            EventBus.$emit('load')
          }
        },

        async baixarXML() {
          let response = await SeloBusiness.getXML(this.seloBase.id).catch(e => {
            FrontBusiness.showError('', e.message);
            return null;
          });
          if (response) {
            let xml = new Blob([response], {type: "application/xml"});
            await FrontBusiness.downloadFile(xml, 'Selo - ' + this.seloBase.codigo + (this.seloBase.codigoValidador ? '-' + this.seloBase.codigoValidador : ''));
          }
        },

        async editarDetalhes() {
          this.dto = Utils.clone(this.seloBase);
          await this.loadDetalhes(this.dto.protocolo);
          this.toggleEdicao();
        },

        toggleEdicao() {
          this.editando = !this.editando;
        },

        async loadDetalhes(protocolo) {
          this.loading = true;
          if (!this.seloBase.enviado) {
            if (protocolo) {
              this.options.atos = [ ...[{id: null, nome: 'Sem vínculo'}], ...(await AtoBusiness.listarAtosProtocolo(protocolo.id) || []).map(ato => {
                return {
                  id: ato.id,
                  nome: AtoBusiness.getCodigo(ato, ato.ficha, ato.tipo || ato.tipoServico?.opcoes?.tipoAto, false, null)
                    + ` - ${ato.titulo || ato.tipoServico.nome} - ${FrontBusiness.nomearFicha(ato.ficha)}`
                }
              })];
              this.options.certidoes = [ ...[{id: null, nome: 'Sem vínculo'}], ...(await CertidaoBusiness.listar(protocolo.id) || []).map(certidao => {
                return {
                  id: certidao.id,
                  nome: `Certidão [${FrontBusiness.formatarCodigo(certidao.pedido)}]`
                }
              })];
              this.options.custasAdicionais = [ ...[{id: null, nome: 'Sem vínculo'}], ...(await ProtocoloCustaAdicionalBusiness.listar(protocolo.id) || []).map(custa => {
                return {
                  id: custa.id,
                  nome: `[${custa.quantidade}] ${this.nomeCusta(custa)}`
                }
              })];
              this.options.prenotacoes = [ ...[{id: null, nome: 'Sem vínculo'}], ...(await ProtocoloBusiness.listarPrenotacao(protocolo.id) || []).map(prenotacao => {
                return {
                  id: prenotacao.id,
                  nome: FrontBusiness.getLabel(prenotacao.tipoRegistro, TabelaCustaBusiness.listTiposRegistro())
                }
              })];
            } else {
              this.options.atos = [];
              this.options.certidoes = [];
              this.options.custasAdicionais = [];
              this.options.prenotacoes = [];
            }
          }
          this.loading = false;
        },

        nomeCusta(custaAdicional) {
          let nome = '';
          if (custaAdicional?.descricao) {
            nome = custaAdicional?.descricao;
            if (custaAdicional?.tipoServico?.nome && custaAdicional?.tipoServico?.nome != custaAdicional?.descricao) {
              nome += ` [${custaAdicional?.tipoServico?.nome}]`;
            }
          } else if (custaAdicional?.tipoServico?.nome) {
            nome = custaAdicional?.tipoServico?.nome;
          } else {
            nome = this.titulo;
          }

          return nome;
        },

        async selecionar(protocolo) {
          this.$set(this.dto, 'protocolo', protocolo ? protocolo[0] : null);
          this.$set(this.dto, 'ato', null);
          this.$set(this.dto, 'certidao', null);
          this.$set(this.dto, 'protocoloCustaAdicional', null);
          this.$set(this.dto, 'protocoloPrenotacao', null);
          await this.loadDetalhes(protocolo?.[0]);
        },

        cancelarEdicao() {
          this.toggleEdicao();
        },

        showAuditorias(){
          this.auditoria = !this.auditoria;
        },

        async salvarDetalhes() {
          this.$set(this, 'validation', {})

          if (!this.dto.protocolo) {
            return FrontBusiness.showError('', 'O protocolo precisa ser informado');
          }
          let dto = {
            id: this.dto.id,
            protocolo: {id: this.dto.protocolo.id},
            enviado: this.dto.enviado
          }

          if (Utils.isEmptyNullOrUndefined(this.dto.utilizacao)) {
            return this.$set(this.validation, 'utilizacao', 'Campo obrigatório');
          } else {
            dto.utilizacao = this.formatarData(this.dto.utilizacao)
          }
          if (this.dto.enviado) {
            if (Utils.isEmptyNullOrUndefined(this.dto.envio)) {
              return this.$set(this.validation, 'envio', 'Campo obrigatório');
            } else {
              dto.envio = this.formatarData(this.dto.envio)
            }
          }

          if (['GO', 'MG'].includes(this.$root.config.estado) && !this.dto.enviado) {
            dto.codigoPai = this.dto.codigoPai;
          }

          if (this.dto.ato) {
            dto.ato = {id: this.dto.ato}
          }
          if (this.dto.certidao) {
            dto.certidao = {id: this.dto.certidao}
          }
          if (this.dto.protocoloCustaAdicional) {
            dto.protocoloCustaAdicional = {id: this.dto.protocoloCustaAdicional}
          }
          if (this.dto.protocoloPrenotacao) {
            dto.protocoloPrenotacao = {id: this.dto.protocoloPrenotacao}
          }
          let retorno = await SeloBusiness.save(this.dto.id, dto).catch(e => FrontBusiness.fullErrorHandler(this, e));
          if (retorno) {
            FrontBusiness.showSuccess('', 'Selo salvo com sucesso');
            await this.consultar();
          }
        },

        async formatters(auditoria){
          let formatters = [];

          formatters = [
            {label: 'Código do Selo', key: 'codigo', type: 'string'},
            {label: 'Situação', key: 'status', type: 'string'},
            {label: 'Motivo Cancelamento', key: 'motivoCancelamento', type: 'string'},
            {label: 'Enviado', key: 'enviado', type: 'boolean'},
            {label: 'Data de Utilização', key: 'utilizacao', type: 'datetime'},
            {label: 'Data de Envio', key: 'envio', type: 'datetime'},
            {label: 'Selo Pai', key: 'codigoPai', type: 'string'},
            {label: 'Usuário Utilização', key: 'usuario', formatter: (value) => AuditoriaBusiness.getUsuario(value)},
            {label: 'Usuário Cancelamento', key: 'usuarioCancelamento', formatter: (value) => AuditoriaBusiness.usuarioLogado(value)},
            {label: 'Protocolo', key: 'protocolo', formatter: (value) => AuditoriaBusiness.nomearProtocolo(value)},
            {label: 'Ato', key: 'ato', formatter: (value) => AuditoriaBusiness.getTipoAto(value)},
            {label: 'Certidao', key: 'certidao', formatter:  (value) => AuditoriaBusiness.nomearCertidao(value)},
            {label: 'Prenotação', key: 'protocoloPrenotacao', formatter:  (value) => AuditoriaBusiness.nomearPrenotacao(value)},
            {label: 'Custa Adicional', key: 'protocoloCustaAdicional', formatter:  (value) => AuditoriaBusiness.nomearCustaAdicional(value)},
          ];

          return formatters
        },

        formatarData(data){
          if (!data) return
          if(!data.includes('T')){
            data = data.replace(' ', 'T');
          }
          return data;
        },
      }

    }
</script>

