<template>
  <card titulo="Histórico">

    <auditoria-pagination :referencia="ficha.id" entidade="INDICADOR" :formatters="formatters" />

  </card>
</template>

<script>
  import RegistroTDBusiness from "@/business/indicadores/RegistroTDBusiness.js";
  import Card from "@/components/Layout/components/Card.vue";
  import AuditoriaBusiness from "@/business/AuditoriaBusiness";
  import TipoBemBusiness from "@/business/crud/TipoBemBusiness";

  export default {

    name: "Histórico",
    components: {
      Card,
      AuditoriaPagination: () => import('@/v2/components/Auditoria/AuditoriaPagination')
    },
    props: {
      ficha : Object,
      livro: String
    },

    data() {
      return {
        historico : [],
        loading: true,
        disable: false,
        page: 1,
        pageSize: 10
      }
    },

    methods:{

      async formatters(auditoria) {
        let formatters = [];
        if (auditoria.entidade === 'ATO') {
          formatters = [...formatters, ... await AuditoriaBusiness.formattersAtos(auditoria)]
        } else if(auditoria.entidade.startsWith('ANEXO_')){
          formatters = [
            {label: 'Ordem', key: 'ordem', type: 'string'},
            {label: 'Nome', key: 'nomeAnexo', type: 'string'},
            {label: 'Caminho', key: 'caminho', type: 'string'},
            {label: 'Tags', key: 'tags', type: 'listahtml'},
            {label: 'Caminho Assinado', key: 'caminhoAssinado', type: 'string'},
          ];
        } else {

          //TODO captura business do livro e verifica campos da auditoria

          formatters = [
            {label: 'Tipo de Registro', key: 'tipoRegistro', type: 'enum', lista: RegistroTDBusiness.getTipos()},
            {label: 'Último Ato', key: 'ultimoAto'},
            {label: 'Situação', key: 'status', type: 'enum', lista: RegistroTDBusiness.getStatusImovel(false)},
            {label: 'Abertura', key: 'abertura', type: 'datetime'},
            {label: 'Cancelamento/Encerramento', key: 'encerramento', type: 'datetime'},
            {label: 'Texto Importado', key: 'textoLegado'},
            {label: 'Observações', key: 'observacoes'},
            {label: 'Livro (Letra)', key: 'livroLetra'},

            // Bem Móvel
            {label: 'Tipo', key: 'tipoBem', formatter: (value) => AuditoriaBusiness.getEntityById(value, TipoBemBusiness)},
            {label: 'Nome', key: 'nome'},
            {label: 'Identificação', key: 'identificacao'},
            {label: 'Data de Referência', key: 'dataReferencia', type: 'date'},
            {label: 'Localização', key: 'localizacao'},
            {label: 'Valor', key: 'valor', type: "currency"},
            {label: 'Nota fiscal', key: 'notaFiscal'},
            {label: 'Detalhes', key: 'detalhes'},

          ];
        }

        return formatters;
      },

    }
  }
</script>
